import { Button, Col, Row, Table, Pagination, FormInstance, Spin, Tag, Dropdown, Menu, notification } from "antd";
import { ColumnsType } from "antd/es/table";
import { useEffect, useState, useRef } from "react";
import { ReactComponent as Manipulation } from "../../../../assets/logos/action.svg";
import { ReactComponent as RemoveDetail } from '../../../../assets/logos/delete.svg';
import { ReactComponent as Lock } from '../../../../assets/logos/lock-dots.svg';
import { ReactComponent as Unlock } from '../../../../assets/logos/unlock.svg';
import { DistrictParams, DistrictResponse } from "../redux/apiTypes";
import AddDistrictModal from "./AddDistrictModal";
import ConfirmModalCustom from "../../../common/components/ConfirmModalCustom";
import { useDeleteDistrictMutation, useLazyGetPageDistrictQuery, useLockDistrictMutation, useUnlockDistrictMutation } from "../redux/districtApi";
import { format } from "date-fns";
import { checkPermission, exportFile } from "../../../common/assets/CommonFunctions";
import { PERMISSION_KEYS } from "../../../permissions/ts/Contants";

export interface DataListTable {
    key: string;
    districtCode: string;
    districtName: string;
    provinceId: string;
    provinceName: string;
    status: number;
    date: string;
    user: string;
    // isUsed: number,
}

type Props = {
    form: FormInstance<any>,
    searchParam: any,
}

const DistrictList = ({ form, searchParam }: Props) => {
    const [tableData, setTableData] = useState<DataListTable[]>([]);
    const [loadingExcel, setLoadingExcel] = useState<boolean>(false);
    const [loadingBtnSubmit, setLoadingBtnSubmit] = useState<boolean>(false);
    const [page, setPage] = useState(1);
    const pageSizeRef = useRef(10);
    const [currentDistrict, setCurrentDistrict] = useState<DataListTable>();
    const [addDistrict, setAddDistrict] = useState(false);
    const [deleteDistrictVisible, setDeleteDistrictVisible] = useState(false);
    const [activateDistrictVisible, setActivateDistrictVisible] = useState(false);

    const [districtTrigger, districtResponse] = useLazyGetPageDistrictQuery();
    const [deleteDistrict] = useDeleteDistrictMutation();
    const [activateDistrict] = useUnlockDistrictMutation();
    const [lockDistrict] = useLockDistrictMutation();

    const canDelete = checkPermission(PERMISSION_KEYS.DANH_MUC.QUAN_HUYEN.XOA)
    const canAddNew = checkPermission(PERMISSION_KEYS.DANH_MUC.QUAN_HUYEN.THEM_MOI)
    const canEdit = checkPermission(PERMISSION_KEYS.DANH_MUC.QUAN_HUYEN.SUA)

    useEffect(() => {
        districtTrigger({
            page: 0,
            size: pageSizeRef.current,
            ...searchParam,
        });
        setPage(1);
    }, [districtTrigger, searchParam]);

    useEffect(() => {
        if (districtResponse?.data?.content?.length) {
            let newTableData: DataListTable[] = [];
            // totalPages.current = districtResponse?.data?.totalPages;
            districtResponse.data.content.forEach((item: DistrictResponse) => {
                newTableData.push({
                    key: item.id,
                    status: item.status,
                    districtCode: item.districtCode,
                    districtName: item.districtName,
                    provinceId: item.provinceId,
                    provinceName: item?.province?.provinceName,
                    date: item.modifiedDateStr,
                    user: item.modifiedBy,
                    // isUsed: item.isUsed,
                })
            })
            setTableData(newTableData);
        } else {
            setTableData([]);
            // totalPages.current = 1;
        }
    }, [districtResponse]);

    const columns: ColumnsType<DataListTable> = [
        {
            title: "STT",
            dataIndex: "index",
            key: "index",
            align: "center",
            render: (value, item, index) => (page - 1) * pageSizeRef.current + index + 1,
        },
        {
            title: "Thao Tác",
            key: "action",
            align: "center",
            render: (record) => {
                return (
                    <Dropdown placement="bottomLeft" overlay={dropdownOverlay(record)} >
                        <Manipulation onClick={(e) => e.stopPropagation()} />
                    </Dropdown>
                );
            },
        },
        {
            title: "Mã Quận/huyện",
            dataIndex: "districtCode",
            key: "districtCode",
            align: "left",
        },
        {
            title: "Quận/huyện",
            dataIndex: "districtName",
            key: "districtName",
            align: "left",
        },
        {
            title: "Tỉnh/thành phố",
            dataIndex: "provinceName",
            key: "provinceName",
            align: "left",
        },
        {
            title: "Trạng Thái",
            key: "status",
            dataIndex: "status",
            render: (tag) => (
                <Tag className={`tag-status${!tag ? "_active" : "_err"}`}>
                    {!tag ? "Hoạt động" : "Không hoạt động"}
                </Tag>
            ),
            align: "left",
        },
        {
            title: "Ngày cập nhật",
            key: "date",
            dataIndex: "date",
            align: "left",
        },
        {
            title: "Người cập nhật",
            key: "user",
            dataIndex: "user",
            align: "left",
        },
    ];

    const dropdownOverlay = (record: any) => {
        // const menuItems: ItemType[] = [];
        // menuItems.push({
        //     label: "Xóa",
        //     key: "remove-detail",
        //     icon: <RemoveDetail />,
        //     // onClick: () => deleteDistrictToggle(record),
        // })
        // record.status
        //     ? menuItems.push({
        //         label: "Kích hoạt",
        //         key: "activate",
        //         icon: <Unlock />,
        //         // onClick: () => activateDistrictToggle(record),
        //     })
        //     : menuItems.push({
        //         label: "Khóa",
        //         key: "lock",
        //         icon: <Lock />,
        //         // onClick: () => activateDistrictToggle(record),
        //     }) 
        return (
            <div onClick={(e) => e.stopPropagation()}>
                <Menu
                    // items={menuItems}
                    className="menu-actions"
                    style={{ padding: "8px", width: "140px" }}
                >
                    {canDelete ?
                        <Menu.Item key="remove-detail" onClick={() => deleteDistrictToggle(record)}><RemoveDetail />Xóa</Menu.Item> : <></>
                    }
                    {
                        record.status ?
                            <Menu.Item key="unlock" onClick={() => activateDistrictToggle(record)}><Unlock /> Kích hoạt</Menu.Item> :
                            <Menu.Item key="lock" onClick={() => activateDistrictToggle(record)}><Lock />Khóa</Menu.Item>
                    }
                </Menu>
            </div>
        );
    };

    const changePage = (currentPage: number, pageSize: number) => {
        const pageSizeChange = pageSizeRef.current !== pageSize;
        let newPage = currentPage;
        if (pageSizeChange) {
            newPage = 1;
        }
        setPage(newPage);
        pageSizeRef.current = pageSize;
        districtTrigger({
            ...searchParam,
            page: newPage - 1,
            size: pageSize
        });
    }

    // Thêm mới
    const toggleAddDistrict = (record?: DataListTable) => {
        setAddDistrict(!addDistrict);
        if (record) setCurrentDistrict(record)
    }
    const onAddSuccessful = () => {
        toggleAddDistrict();
        setCurrentDistrict(undefined);
        setPage(1);
        districtTrigger({ size: pageSizeRef.current, page: 0, })
    }

    // Xóa 
    const deleteDistrictToggle = (record: DataListTable) => {
        setDeleteDistrictVisible(!deleteDistrictVisible)
        setCurrentDistrict(record)
    }
    const onDelete = () => {
        if (currentDistrict) {
            if (!loadingBtnSubmit) {
                setLoadingBtnSubmit(true);
                deleteDistrict([currentDistrict.key]).unwrap().then(res => {
                    notification.success({
                        message: 'Xóa Quận/huyện thành công'
                    })
                    setDeleteDistrictVisible(false);
                    setCurrentDistrict(undefined);
                    setPage(1);
                    form.resetFields();
                    setLoadingBtnSubmit(false);
                    districtTrigger({ size: pageSizeRef.current, page: 0 });
                }).catch(err => {
                    notification.error({
                        message: err.data?.message || "Đã có lỗi xảy ra. Vui lòng thử lại sau!"
                    });
                    setLoadingBtnSubmit(false);
                })
            }
        }
    }

    // Khóa, kích hoạt
    const activateDistrictToggle = (record: DataListTable) => {
        setActivateDistrictVisible(!activateDistrictVisible)
        setCurrentDistrict(record)
    }
    const onActivate = () => {
        if (currentDistrict) {
            if (currentDistrict.status === 0) {
                if (!loadingBtnSubmit) {
                    setLoadingBtnSubmit(true);
                    lockDistrict([currentDistrict.key]).unwrap().then(res => {
                        notification.success({
                            message: 'Khóa Quận/huyện thành công.'
                        })
                        setActivateDistrictVisible(false);
                        setCurrentDistrict(undefined);
                        setPage(1);
                        form.resetFields();
                        setLoadingBtnSubmit(false);
                        districtTrigger({ size: pageSizeRef.current, page: 0 });
                    }).catch(err => {
                        notification.error({
                            message: err.data?.message || "Đã có lỗi xảy ra. Vui lòng thử lại sau!"
                        });
                        setLoadingBtnSubmit(false);
                    })
                }
            }

            if (currentDistrict.status === 1) {
                if (!loadingBtnSubmit) {
                    setLoadingBtnSubmit(true);
                    activateDistrict([currentDistrict.key]).unwrap().then(res => {
                        notification.success({
                            message: 'Kích hoạt Quận/huyện thành công.'
                        })
                        setActivateDistrictVisible(false);
                        setCurrentDistrict(undefined);
                        setPage(1);
                        form.resetFields();
                        setLoadingBtnSubmit(false);
                        districtTrigger({ size: pageSizeRef.current, page: 0 });
                    }).catch(err => {
                        notification.error({
                            message: err.data?.message || "Đã có lỗi xảy ra. Vui lòng thử lại sau!"
                        });
                        setLoadingBtnSubmit(false);
                    })
                }
            }
        }
    }

    // export excel
    const onExportExcelClick = () => {
        let url = process.env.REACT_APP_API_URL + "/api/v1/common/district/export/excel";
        let submitValues: DistrictParams = { ...searchParam };
        url += `?search=${encodeURIComponent(JSON.stringify(submitValues))}&pageNumber=0&pageSize=20000&sortBy=DESC&sortByProperty=modifiedDate`
        let filename = `${format(new Date(), "dd-MM-yyyy")}_Danh sách quận huyện.xlsx`;
        setLoadingExcel(true);
        exportFile(url, filename).then(() => {
            setLoadingExcel(false);
        }).catch(err => {
            setLoadingExcel(false);
            notification.error({
                message: err.data?.message || "Đã có lỗi xảy ra. Vui lòng thử lại sau!"
            })
        });
    }

    const resetState = () => {
        setCurrentDistrict(undefined);
        setDeleteDistrictVisible(false);
        setActivateDistrictVisible(false);
        setAddDistrict(false);
    }

    return (
        <div className='content-box'>
            <Row justify="space-between" align="middle" style={{ marginBottom: 15 }}>
                <Col span={6}>
                </Col>
                <div className='search-box-button'>
                    <Button
                        htmlType="button"
                        style={{ marginRight: 15 }}
                        className="secondary-button"
                        onClick={onExportExcelClick}
                        loading={loadingExcel}
                    >
                        Xuất excel
                    </Button>
                    {canAddNew ?
                        <Button
                            htmlType="button"
                            onClick={() => toggleAddDistrict()}
                            className="secondary-button"
                            type="primary"
                        >
                            Thêm mới
                        </Button> : <></>
                    }
                </div>
            </Row>
            <Table<DataListTable>
                loading={districtResponse?.isFetching}
                dataSource={tableData}
                columns={columns}
                tableLayout="auto"
                pagination={false}
                scroll={{ x: "max-content" }}
                onRow={(record) => {
                    return {
                        onClick: event => toggleAddDistrict(record),
                    }
                }}
            />

            <Spin spinning={districtResponse?.isFetching} tip="Đang tải...">
                <Pagination
                    total={districtResponse?.data?.totalElements || 0}
                    defaultPageSize={pageSizeRef.current}
                    defaultCurrent={1}
                    locale={{ items_per_page: ' dòng' }}
                    pageSizeOptions={[10, 20, 50, 100]}
                    onChange={changePage}
                    showSizeChanger
                    current={page}
                />
            </Spin>

            {/* form thêm mới, sửa Quận/huyện */}
            {addDistrict && (
                <AddDistrictModal
                    visible={addDistrict}
                    onCancel={resetState}
                    currentDistrict={currentDistrict}
                    onAddSuccessful={onAddSuccessful}
                    canEdit={canEdit}
                />
            )}

            {/* modal xóa Quận/huyện */}
            {deleteDistrictVisible && (
                <ConfirmModalCustom
                    onCancel={resetState}
                    cancelText="Đóng"
                    okText="Đồng ý"
                    showCancel={true}
                    showOk={true}
                    type={"error"}
                    danger={true}
                    title="Xóa Quận/huyện"
                    content="Bạn có chắc chắn muốn xóa Quận/huyện này hay không?"
                    visible={deleteDistrictVisible}
                    onOk={onDelete}
                    loadingBtnOk={loadingBtnSubmit}
                />
            )}

            {/* modal khóa, kích hoạt Quận/huyện*/}
            {activateDistrictVisible && (
                <ConfirmModalCustom
                    onCancel={resetState}
                    cancelText="Đóng"
                    okText="Đồng ý"
                    showCancel={true}
                    showOk={true}
                    type={currentDistrict?.status === 0 ? 'warning' : 'success'}
                    title={currentDistrict?.status === 0 ? "Khóa Quận/huyện" : "Kích hoạt Quận/huyện"}
                    content={currentDistrict?.status === 0 ?
                        "Bạn có chắc chắn muốn khóa Quận/huyện này hay không?" :
                        "Bạn có chắc chắn muốn kích hoạt Quận/huyện này hay không?"
                    }
                    visible={activateDistrictVisible}
                    onOk={onActivate}
                    loadingBtnOk={loadingBtnSubmit}
                />
            )}

        </div>
    )
}

export default DistrictList;