import { Alert, Button, Checkbox, Col, Form, Input, notification, Row, Typography } from 'antd';
import { Navigate, useLocation } from 'react-router-dom';
import './assets/css/index.css';
import { useEffect, useRef, useState } from 'react';
import { useLoginMutation } from './redux/LoginApi';
import { useAppDispatch, useAppSelector } from '../../redux/Hooks';
import { setToken } from './redux/UserSlice';
import { isTokenExpired } from '../common/assets/CommonFunctions';
import { useLazyVerifyGoogleCaptchaQuery } from '../../redux/api/PublicApi';
import ReCAPTCHA from "react-google-recaptcha";

const { Title } = Typography;

const Login = () => {
    const location = useLocation();
    const [isLogin, setIsLogin] = useState(false);
    const [login, { isLoading, error }] = useLoginMutation();
    const user = useAppSelector((state) => state.user);
    const [formError, setFormError] = useState(false);
    const dispatch = useAppDispatch();
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [verifyCaptcha] = useLazyVerifyGoogleCaptchaQuery();
    const [numOfFailedLogins, setNumOfFailedLogins] = useState(0);
    const recaptchaRef = useRef<ReCAPTCHA>(null);

    useEffect(() => {
        setNumOfFailedLogins(Number(localStorage.getItem("numOfFailedLogins") || 0));
    }, [])

    useEffect(() => {
        // if (numOfFailedLogins >= 3) {
        //     setButtonDisabled(true);
        // }
    }, [numOfFailedLogins])

    useEffect(() => {
        let accessToken = localStorage.getItem("accessToken");
        if (accessToken && !isTokenExpired(accessToken)) {
            setIsLogin(true);
            dispatch(setToken(accessToken));
        } else {
            localStorage.removeItem("accessToken")
        }
    }, [dispatch])

    useEffect(() => {
        if (user.userToken && !isTokenExpired(user.userToken)) {
            localStorage.setItem("accessToken", user.userToken);
            setIsLogin(true);
        }
        if (user.user) {
            localStorage.setItem("user", JSON.stringify(user.user));
        }
    }, [user])

    const onFinish = (values: any) => {
        setFormError(false);
        login({ username: values.username, password: values.password }).unwrap().then(() => {
            setNumOfFailedLogins(0);
            localStorage.setItem("numOfFailedLogins", "0");
        }).catch(err => {
            let newNumOfFailedLogins = numOfFailedLogins + 1;
            setNumOfFailedLogins(newNumOfFailedLogins);
            localStorage.setItem("numOfFailedLogins", (newNumOfFailedLogins).toString());
            if (recaptchaRef.current) {
                recaptchaRef.current.reset();
            }
        })
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
        setFormError(true);
    };

    if (isLogin) {
        return <Navigate to="/home" replace state={{ from: location }} />
    }

    const onValidate = (token: string | null) => {
        if (token) {
            verifyCaptcha(token).unwrap().then(res => {
                if (res.success) {
                    setButtonDisabled(false);
                } else {
                    notification.error({ message: "Lỗi Captcha. Vui lòng thử lại sau!" });
                }
            }).catch(err => {
                console.log(err);
                notification.error({ message: "Lỗi Captcha. Vui lòng thử lại sau!" });
            })
        } else {
            setButtonDisabled(true);
        }
    }

    return (
        <div className='login-background'>
            <Row align='middle' justify='center'>
                <Col span={8} offset={16} className='login-card'>
                    <Title level={2}>ĐĂNG NHẬP</Title>
                    <Alert
                        message={user?.errorMessage ? <>{user?.errorMessage.split('\n').map(item => (<div key={item} style={{ textAlign: 'center' }}>{item}</div>))}</> : "Đã có lỗi xảy ra"}
                        type="error"
                        style={{ marginBottom: 10, visibility: error && !formError ? 'visible' : 'hidden' }} />
                    <Form
                        name="loginForm"
                        labelCol={{ span: 8 }}
                        wrapperCol={{ span: 16 }}
                        initialValues={{ remember: false }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                        requiredMark={false}
                        validateTrigger="onSubmit"
                    >
                        <Form.Item
                            labelCol={{ span: 9 }}
                            labelAlign='left'
                            label="Tên đăng nhập"
                            name="username"
                            rules={[{ required: true, message: 'Tên đăng nhập là bắt buộc, vui lòng nhập đầy đủ.' }]}
                        >
                            <Input style={{ height: 50 }} placeholder="Tên đăng nhập" maxLength={50} />
                        </Form.Item>

                        <Form.Item
                            labelCol={{ span: 9 }}
                            labelAlign='left'
                            label="Mật khẩu"
                            name="password"
                            rules={[{ required: true, message: 'Mật khẩu là bắt buộc, vui lòng nhập đầy đủ.' }]}
                        >
                            <Input.Password style={{ height: 50 }} placeholder="Mật khẩu" maxLength={20} />
                        </Form.Item>

                        {/* {numOfFailedLogins >= 3 ?
                            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 15 }}>
                                <ReCAPTCHA
                                    ref={recaptchaRef}
                                    sitekey={process.env.REACT_APP_RECAPTCHA_KEY || ""}
                                    onChange={onValidate}
                                    hl="vi"
                                />
                            </div> : <></>} */}

                        <Form.Item wrapperCol={{ offset: 9, span: 16 }}>
                            {!isLoading ?
                                <Button type="primary" htmlType="submit" disabled={buttonDisabled}>
                                    Đăng nhập
                                </Button> :
                                <Button type='primary' loading>Đăng nhập</Button>}
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
        </div >
    )
}

export default Login;