import { Button, Col, Form, Input, notification, Row, Select, Space, Tree, Upload } from "antd"
import React, { useEffect, useState } from "react"
import { useAddUserMutation, useEditUserMutation, useLazyGetByIdQuery } from "../redux/userApi"
import { DatePicker } from "../../../custom-components";

import { ReactComponent as XIcon } from '../../../assets/logos/x-solid.svg';

import '../assets/scss/user.scss';
import { useLazyGetAllRoleQuery, useLazyGetFullRoleQuery } from "../../permissions/redux/permissionsApi";
import { useNavigate, useParams } from "react-router-dom";
import { PERMISSION_KEYS } from "../../permissions/ts/Contants";
import { FullRoleResponse, Role, TreeRole } from "../../permissions/redux/apiTypes";
import { useDeleteFileMutation, useLazyGetFileByObjectIdAndTypeQuery, useUploadFileForObjectMutation, useUploadFileMutation } from "../../../redux/api/FileApi";
import confirm from "antd/lib/modal/confirm";
import { checkPermission, exportFile } from "../../common/assets/CommonFunctions";
import { arrayToTree } from "performant-array-to-tree";
import moment from "moment";

const pattern = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
const patternMessage = "Nhập mật khẩu tối thiểu 8 ký tự bao gồm chữ hoa, chữ thường, số và ký tự đặc biệt.";

const CreateUser = () => {
    const [form] = Form.useForm();
    const { Option } = Select;

    const [loadingFile, setLoadingFile] = useState(false);
    const [fileError, setFileError] = useState('');
    const [uploadFile] = useUploadFileMutation();
    const [fileList, setFileList] = useState<any>([]);
    const [loading, setLoading] = useState(true);
    const [editing, setEditing] = useState<boolean>(false);

    const [uploadedFiles, setUploadedFiles] = useState<any>([]);
    const [deleteIds, setDeleteIds] = useState<any>([]);

    // const [modalVisible, setModalVisible] = useState<boolean>(false);
    // const [expandedRowKeys, setExpandedRowKeys] = useState<number[]>([]);

    const navigate = useNavigate();
    const params = useParams();

    const canEdit = checkPermission(PERMISSION_KEYS.QUAN_TRI_HE_THONG.NGUOI_DUNG.SUA)


    const [addUser] = useAddUserMutation();
    const [editUser] = useEditUserMutation();
    const [roleListTrigger, roleListResponse] = useLazyGetAllRoleQuery();
    const [getFullRoleTrigger] = useLazyGetFullRoleQuery();
    const [detailUserTrigger] = useLazyGetByIdQuery();

    const [uploadFileToObject] = useUploadFileForObjectMutation();
    const [fileIdTrigger] = useLazyGetFileByObjectIdAndTypeQuery();
    const [deleteFile] = useDeleteFileMutation();

    const [treeData, setTreeData] = useState<any>([]);

    const [expandedKeys, setExpandedKeys] = useState<React.Key[]>([]);
    const [checkedKeys, setCheckedKeys] = useState<React.Key[]>([]);
    const [selectedKeys, setSelectedKeys] = useState<React.Key[]>([]);
    const [autoExpandParent, setAutoExpandParent] = useState<boolean>(true);
    const onExpand = (expandedKeysValue: React.Key[]) => {
        setExpandedKeys(expandedKeysValue);
        setAutoExpandParent(false);
    };

    const onSelect = (selectedKeysValue: React.Key[], info: any) => {
        setSelectedKeys(selectedKeysValue);
    };
    useEffect(() => {
        getFullRoleTrigger({}).unwrap()
            .then(res => {
                let tree: TreeRole[] = [
                    {
                        title: "Quản trị hệ thống",
                        key: "quanTriHeThong",
                        id: "quanTriHeThong",
                        authKey: "quanTriHeThong",
                        authoritieName: "Quản trị hệ thống",
                        description: "Quản trị hệ thống",
                        children: []
                    },
                    {
                        title: "Quản lý danh mục",
                        key: "danhMuc",
                        id: "danhMuc",
                        authKey: "danhMuc",
                        authoritieName: "Quản lý danh mục",
                        description: "Quản lý danh mục",
                        children: []
                    }
                ];

                res.forEach((item: FullRoleResponse) => {
                    let treeNode: TreeRole = {
                        title: item.authoritieName,
                        key: item.authKey,
                        id: item.id,
                        authKey: item.authKey,
                        authoritieName: item.authoritieName,
                        description: item.description,
                        parentId: item.parentId,
                        children: []
                    }
                    if (['Tỉnh thành', 'Quận huyện', 'Phường xã'].includes(item.authoritieName)) {
                        treeNode.parentId = 'danhMuc';
                    }
                    if (['Người dùng', 'Nhóm người dùng'].includes(item.authoritieName)) {
                        treeNode.parentId = 'quanTriHeThong';
                    }
                    if (item.authoritieName === 'Tham số hệ thống') {
                        treeNode.title = 'Cấu hình trường thông tin';
                        treeNode.authoritieName = 'Cấu hình trường thông tin';
                    }
                    tree.push(treeNode);
                })

                const treeRes = arrayToTree(
                    tree,
                    { dataField: null }
                );

                let sortIn = ["Chiến dịch", "Chiến dịch nhập liệu", "Cấu hình trường thông tin", "Danh sách file pdf", "Nhập liệu dữ liệu", "Kho dữ liệu nhập liệu", "Kiểm tra dữ liệu", "Kho dữ liệu kiểm tra", "Kho dữ liệu", "Báo cáo", "Quản trị hệ thống", "Quản lý danh mục"]

                let sortRes = sortIn.map(item => {
                    let idx = treeRes.findIndex(x => x.authoritieName === item);
                    if (idx !== -1) {
                        return treeRes[idx];
                    }
                }
                ).filter(item => item !== undefined);

                setTreeData(sortRes);

            })
    }, [getFullRoleTrigger])


    // // table
    // const _onExpandIconClick = (key: number) => {
    //     _toggleExpandByKey(key);
    // }

    // const _toggleExpandByKey = (key: number) => {
    //     const newExpandedRowKeys = expandedRowKeys.find(x => x === key)
    //         ? expandedRowKeys.filter(x => x !== key)
    //         : [...expandedRowKeys, key];
    //     setExpandedRowKeys(newExpandedRowKeys);
    // };

    // const handleOnChange = (e: any, record: any, type: string) => {
    //     const a = record.children.map((child: any) => child[type])

    //     let newFormValues = { ...form.getFieldsValue() };
    //     a.forEach((item: any) => {
    //         newFormValues[item] = e.target.checked
    //     })

    //     form.setFieldsValue(newFormValues)
    // }

    // const columns: ColumnsType<DataType> = [
    //     {
    //         title: 'Phân quyền chức năng',
    //         dataIndex: 'title',
    //         render: (text: string) => <a>{text}</a>,
    //     },
    //     {
    //         key: 'action',
    //         align: "center",
    //         width: 60,
    //         render: (record) => {
    //             const { key } = record;
    //             const expened = expandedRowKeys.find(x => x === key);
    //             return Array.isArray(record?.children) && (
    //                 <span onClick={(e) => e.stopPropagation()}>
    //                     {expened &&
    //                         <DownOutlined onClick={() => _onExpandIconClick(key)} className='expand-icon' />}
    //                     {!expened &&
    //                         <RightOutlined onClick={() => _onExpandIconClick(key)} className='expand-icon' />}
    //                 </span>
    //             );
    //         },
    //     },
    //     {
    //         title: 'Xem',
    //         dataIndex: 'view',
    //         render: (text: any, record: any) => {
    //             return (
    //                 <Form.Item name={record?.view} valuePropName="checked">
    //                     <Checkbox onChange={(e: any) => handleOnChange(e, record, 'view')} disabled />
    //                 </Form.Item>
    //             )
    //         },
    //         align: 'center'
    //     },
    //     {
    //         title: 'Thêm',
    //         dataIndex: 'add',
    //         render: (text: any, record: any) => (
    //             <Form.Item name={record?.add} valuePropName="checked">
    //                 <Checkbox onChange={(e: any) => handleOnChange(e, record, 'add')} disabled />
    //             </Form.Item>
    //         ),
    //         align: 'center'
    //     },
    //     {
    //         title: 'Sửa',
    //         dataIndex: 'edit',
    //         render: (text: any, record: any) => (
    //             <Form.Item name={record?.edit} valuePropName="checked">
    //                 <Checkbox onChange={(e: any) => handleOnChange(e, record, 'edit')} disabled />
    //             </Form.Item>
    //         ),
    //         align: 'center'
    //     },
    //     {
    //         title: 'Xoá',
    //         dataIndex: 'delete',
    //         render: (text: any, record: any) => (
    //             <Form.Item name={record?.delete} valuePropName="checked">
    //                 <Checkbox onChange={(e: any) => handleOnChange(e, record, 'delete')} disabled />
    //             </Form.Item>
    //         ),
    //         align: 'center'
    //     },
    // ];


    // initial data

    useEffect(() => {
        roleListTrigger({});
        getFullRoleTrigger({});
    }, [roleListTrigger])

    useEffect(() => {
        if (params.id) {
            detailUserTrigger(params.id).unwrap().then(res => {
                let newFormValues = {
                    // ...form.getFieldsValue(),
                    username: res.username,
                    phoneNumber: res.phoneNumber,
                    fullName: res.fullName,
                    email: res.email,
                    address: res.address,
                    password: res.password,
                    status: res.status,
                    // role: res.roles?.[0].id,
                    role: res.roles?.length ? res.roles?.[0] ? res.roles?.[0].roleName : "" : "",
                    birthday: res.birthday,
                    gender: res.gender,
                    educationLevel: res.educationLevel,
                    startWorkDate: res.startWorkDate,
                    workType: res.workType,
                    warningCount: res.warningCount,
                    rewardCount: res.rewardCount,
                };
                // if (res.roles?.[0]) {
                //     res.roles?.[0].authoritieLst.forEach((item: any) => {
                //         newFormValues[item.id] = true;
                //     })
                // }
                if (res.roles?.length && res?.roles?.[0]?.authoritieLst) {
                    const authList = res.roles?.[0].authoritieLst.map((role: Role) => role.authKey);
                    setCheckedKeys(authList);
                }
                form.setFieldsValue(newFormValues)
            }).catch(err => {
                notification.error({ message: err.data?.message || "Có lỗi xảy ra!" });
            });

            fileIdTrigger({ objectId: params?.id, objectType: 100 }).unwrap()
                .then(res => {
                    console.log('file', res)
                    if (res.length) {
                        setLoading(true)
                        let newFileList: any = [];

                        Promise.all(res.map(item =>
                            fetch(process.env.REACT_APP_API_URL + "/api/v1/file/downloadFile/" + item.id, {
                                headers: {
                                    Authorization: 'Bearer ' + localStorage.getItem("accessToken"),
                                }
                            }).then(res => {
                                return res.blob().then(blob => {
                                    return { ...item, blob }
                                })
                            })
                        )).then(res => {
                            let promises: any = [];
                            res.forEach(item => {

                                let newPromise = new Promise(resolve => {
                                    let reader = new FileReader();
                                    reader.readAsDataURL(item.blob);
                                    // reader.onload = () => resolve({ ...item, thumbUrl: reader.result });
                                    reader.onload = () => resolve(item);

                                })

                                promises.push(newPromise);
                            })

                            Promise.all(promises).then(fileContents => {
                                fileContents.forEach(item => {

                                    newFileList.push({
                                        id: item.id,
                                        name: item.fileName,
                                        status: 'done',
                                        thumbUrl: item.thumbUrl,
                                        response: item,
                                        fileExtension: item.fileExtension,
                                    })
                                })
                                setUploadedFiles(newFileList);
                            })
                            setLoading(false)
                        })
                    }
                })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [detailUserTrigger, params.id])

    const onFinish = (values: any) => {
        deleteIds.forEach((id: string) => {
            if (typeof id === "string") deleteFile(id);
        });
        // let keys = Object.keys(values).filter(k => values[k] === true);
        // const authoritieList = fullRoleResponse?.data.filter((role: Role) => keys.includes(role.id));
        if (!params.id) {
            addUser({
                fullName: values.fullName?.trim() || "",
                phoneNumber: values.phoneNumber?.trim() || "",
                email: values.email?.trim() || "",
                address: values.address?.trim() || "",
                birthday: values.birthday ? new Date(values?.birthday)?.toISOString() : "",
                gender: values.gender,
                educationLevel: values.educationLevel?.trim() || "",
                startWorkDate: values.startWorkDate ? new Date(values?.startWorkDate)?.toISOString() : "",
                workType: values.workType || 0,
                warningCount: values.warningCount,
                rewardCount: values.rewardCount,

                username: values.username?.trim() || "",
                password: values.password?.trim() || "",
                status: values.status || 0,

                roles: [roleListResponse?.data?.find(role => role.id === values.role)],
            }).unwrap()
                .then(res => {
                    // notification.success({ message: "Tạo tài khoản thành công!" });
                    // navigate('/setting/user')
                    addFileToObject(res, values)
                })
                .catch(err => {
                    notification.error({ message: err.data?.message || "Có lỗi xảy ra!" });
                })
        } else {
            editUser({
                id: params.id,

                fullName: values.fullName?.trim() || "",
                phoneNumber: values.phoneNumber?.trim() || "",
                email: values.email?.trim() || "",
                address: values.address?.trim() || "",
                birthday: values.birthday ? new Date(values?.birthday)?.toISOString() : "",
                gender: values.gender,
                educationLevel: values.educationLevel?.trim() || "",
                startWorkDate: values.startWorkDate ? new Date(values?.startWorkDate)?.toISOString() : "",
                workType: values.workType || 0,
                warningCount: values.warningCount,
                rewardCount: values.rewardCount,

                username: values.username?.trim() || "",
                password: values.password?.trim() || "",
                status: values.status || 0,

                roles: [roleListResponse?.data?.find(role => role.id === values.role)],
            }).unwrap()
                .then(res => {
                    // notification.success({ message: "Chỉnh sửa tải khoản thành công!" });
                    // navigate('/setting/user')
                    addFileToObject(res, values)
                })
                .catch(err => {
                    notification.error({ message: err.data?.message || "Có lỗi xảy ra!" });
                })
        }
    }


    // handle khi change role
    const onValuesChange = (changedValues: any, allValues: any) => {
        if (changedValues.role) {
            // let keys = Object.keys(allValues).filter(k => allValues[k] === true);
            // const roles = roleListResponse?.data?.find(role => role.id === changedValues.role)?.authoritieLst?.map((child: any) => child['id']);
            // if (roles) {
            //     let newFormValues = { ...form.getFieldsValue() };

            //     keys.forEach((item: any) => {
            //         newFormValues[item] = false;
            //     })
            //     roles.forEach((item: any) => {
            //         newFormValues[item] = true;
            //     })
            //     form.setFieldsValue(newFormValues)
            // }
            const roles = roleListResponse?.data?.find(role => role.id === changedValues.role)?.authoritieLst?.map((role: any) => role.authKey);
            if (roles) {
                setCheckedKeys(roles);
            }
        }
    }

    // upload file
    const customUpload = (options: any) => {
        setLoadingFile(true);
        options.onProgress({ percent: 0 })
        let formData = new FormData();
        formData.append('file', options.file);
        uploadFile({
            file: formData,
            objectType: 100,
            storageType: 'FILE_HOP_DONG',
        }).unwrap()
            .then(res => {
                options.onProgress({ percent: 100 });
                options.onSuccess(res, options.file);

                let uploadFile = {
                    id: res.id,
                    uid: options.file.uid,
                    name: options.file.name,
                    size: options.file.size,
                    type: options.file.type,
                }

                setUploadedFiles([...uploadedFiles, uploadFile]);
                setLoadingFile(false);
                fetch(process.env.REACT_APP_API_URL + "/api/v1/file/downloadFile/" + res.id, {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem("accessToken"),
                    }
                }).then(response => {
                    return response.blob().then(blob => {
                        let reader = new FileReader();
                        reader.readAsDataURL(blob);
                        reader.onload = () => setFileList([
                            {
                                uid: res.id,
                                name: res.fileName,
                                status: 'done',
                                thumbUrl: reader.result,
                                response: res
                            }]);
                    })
                })
            })
            .catch(err => {
                options.onError();
                setLoadingFile(false);
                if (err?.data?.message) {
                    notification.error({
                        message: err?.data?.message
                    })
                }
            })
    }


    const beforeUpload = (files: File[]) => {
        let accepted = true;
        let fileList = [...files];
        fileList = fileList.slice(-1);
        Array.from(fileList).forEach(file => {
            let error = ''
            if (file.type !== "application/vnd.ms-excel" &&
                file.type !== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
                accepted = false;
                error = 'Định dạng file không hợp lệ!'
            }
            setFileError(error)
        })
        return accepted || Upload.LIST_IGNORE;
    }

    const onFileRemove = (item: any, index: number) => {
        return new Promise<boolean>((resolve, reject) => {
            confirm({
                title: 'Xoá file đã chọn?',
                okText: "Đồng ý",
                cancelText: "Hủy bỏ",
                onOk: () => {
                    if (uploadedFiles) {
                        let newDeleteIds = deleteIds;
                        newDeleteIds.push(item.id);
                        setDeleteIds(newDeleteIds);
                        let newUploadedFiles = uploadedFiles;
                        newUploadedFiles.splice(index, 1);
                        setUploadedFiles([...newUploadedFiles])
                        form.setFieldsValue({
                            attachment: []
                        })
                        form.validateFields(["attachment"])
                    }
                    resolve(true);
                },
                onCancel: () => {
                    resolve(false)
                }
            })
        })
    }
    const downloadFile = (id: number, name: string) => {
        exportFile(process.env.REACT_APP_API_URL + "/api/v1/file/downloadFile/" + id, name);
    }

    const addFileToObject = (res: any, values: any) => {
        let fileIds = uploadedFiles?.length ? uploadedFiles.map((item: any) => item.id) : [];
        uploadFileToObject({
            listFileIds: fileIds,
            objectId: res.id,
            storageType: 'FILE_HOP_DONG'
        })
            .unwrap()
            .then(() => {
                setDeleteIds([])
                notification.success({
                    message: params?.id ? "Cập nhật người dùng thành công" : "Thêm mới người dùng thành công",
                })
                navigate('/setting/user')
            })
            .catch((err) => {
                notification.error({
                    message: err?.data?.message || "Đã có lỗi xảy ra. Vui lòng thử lại sau!"
                })
            })
    }

    const changePhoneNumber = (e: { target: { value: string; }; }) => {
        if (`${e.target.value}`.includes("+")) {
            form.setFieldsValue({ phoneNumber: e.target.value });
        }
        else if (e.target.value.charAt(0) !== '0') {
            form.setFieldsValue({ phoneNumber: "+" + e.target.value });
        }
        if (e.target.value === '') {
            form.setFieldsValue({ phoneNumber: e.target.value });
        }

    }

    return (
        <div className='create-container'>
            <Row className="fixed-header" align="middle" justify="space-between" style={{ width: '100%' }}>
                <Col>
                    <span className="title">{params?.id ? !editing ? 'THÔNG TIN NGƯỜI DÙNG' : 'CHỈNH SỬA NGƯỜI DÙNG' : 'THÊM MỚI NGƯỜI DÙNG'}</span>
                </Col>
                <Col>
                    <Space size={16} >
                        {(params.id && canEdit && !editing) && <Button className="submit-btn" type="primary" onClick={() => setEditing(!editing)}>Chỉnh sửa</Button>}
                        {editing && <Button
                            className="submit-btn btn"
                            type="primary"
                            onClick={() => form.submit()}>
                            Lưu
                        </Button>
                        }
                        {!params.id && <Button
                            className="submit-btn btn"
                            type="primary"
                            onClick={() => form.submit()}>
                            Thêm mới
                        </Button>
                        }
                        <Button danger className="submit-btn" onClick={() => navigate('/setting/user')}>Huỷ</Button>
                    </Space>
                </Col>
            </Row>
            <Form
                name="searchBox"
                autoComplete={"off"}
                form={form}
                labelWrap
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                labelAlign="left"
                requiredMark={false}
                colon={false}
                onFinish={onFinish}
                onValuesChange={onValuesChange}
                scrollToFirstError={{ behavior: 'smooth' }}
                initialValues={{
                    username: '',
                    password: '',
                }}
            // onFinishFailed={() => setModalVisible(!modalVisible)}
            >
                <Row gutter={16} className='create-section'>
                    <span className="section-title">Thông tin cá nhân</span>
                    <Col span={12}>
                        <Form.Item
                            label="Họ tên"
                            name="fullName"
                            rules={[
                                { required: true, whitespace: true, message: "Vui lòng nhập Họ tên." }
                            ]}>
                            <Input
                                placeholder="Nhập họ và tên"
                                autoFocus
                                allowClear
                                style={{ minWidth: '100%' }}
                                disabled={(params.id && !editing) ? true : false}
                                maxLength={250}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Số điện thoại"
                            name="phoneNumber"
                            rules={[
                                //     { required: true, whitespace: true, message: "Vui lòng nhập Số điện thoại." }
                                { min: 10, message: 'Số điện thoại cần tối thiểu 10 chữ số' }
                            ]}
                        >
                            <Input
                                maxLength={11}
                                placeholder="Nhập số điện thoại"
                                allowClear
                                style={{ minWidth: '100%' }}
                                disabled={(params.id && !editing) ? true : false}
                                onBlur={changePhoneNumber}
                                onKeyDown={(e) => {
                                    if ((e.key < "0" || e.key > "9") && !["Backspace", "ArrowLeft", "ArrowRight"].includes(e.key)) {
                                        e.preventDefault();
                                    }
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Email"
                            name="email"
                            rules={[
                                { whitespace: true, type: 'email', message: "Vui lòng nhập đúng định dạng Email." }
                            ]}
                        >
                            <Input
                                placeholder="Nhập email"
                                allowClear
                                style={{ minWidth: '100%' }}
                                disabled={(params.id && !editing) ? true : false}
                                type="email"
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Địa chỉ"
                            name="address"
                        >
                            <Input.TextArea
                                placeholder="Nhập địa chỉ"
                                allowClear
                                rows={1}
                                maxLength={500}
                                disabled={(params.id && !editing) ? true : false} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Ngày sinh"
                            name="birthday"
                        >
                            <DatePicker
                                placeholder="Nhập Ngày sinh"
                                format="DD/MM/Y"
                                style={{ minWidth: '100%' }}
                                allowClear
                                disabled={(params.id && !editing) ? true : false}
                                disabledDate={(current) => current > moment().endOf('day').toDate()}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Giới tính"
                            name="gender"
                        >
                            <Select
                                placeholder="Chọn giới tính"
                                allowClear
                                disabled={(params.id && !editing) ? true : false}
                            >
                                <Option value={1}>
                                    Nam
                                </Option>
                                <Option value={2}>
                                    Nữ
                                </Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    {/* <Col span={12}>
                        <Form.Item
                            label="Trình độ học vấn"
                            name="educationLevel"
                        >
                            <Input placeholder="Nhập Trình độ học vấn" allowClear style={{ minWidth: '100%' }} disabled={(params.id && !editing) ? true : false} />
                        </Form.Item>
                    </Col> */}
                    <Col span={12}>
                        <Form.Item
                            label="Ngày vào làm"
                            name="startWorkDate"
                        >
                            <DatePicker
                                placeholder="Nhập Ngày vào làm"
                                format="DD/MM/Y"
                                style={{ minWidth: '100%' }}
                                allowClear
                                disabled={(params.id && !editing) ? true : false}
                                disabledDate={(current) => current > moment().endOf('day').toDate()}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Hình thức làm việc"
                            name="workType"
                        >
                            <Select
                                placeholder="Chọn hình thức làm việc"
                                allowClear
                                disabled={(params.id && !editing) ? true : false}
                            >
                                <Option value={0}>
                                    Online
                                </Option>
                                <Option value={1}>
                                    Offline
                                </Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Số lần cảnh báo"
                            name="warningCount"
                        >
                            <Input placeholder="Nhập Số lần cảnh báo" allowClear type='number' min={0} style={{ minWidth: '100%' }} disabled={(params.id && !editing) ? true : false} />
                        </Form.Item>
                    </Col>
                    {/* <Col span={12}>
                        <Form.Item
                            label="Số lần khen thưởng"
                            name="rewardCount"
                        >
                            <Input placeholder="Nhập Số lần khen thưởng" allowClear type='number' min={0} style={{ minWidth: '100%' }} disabled={(params.id && !editing) ? true : false} />
                        </Form.Item>
                    </Col> */}
                    {/* <Col span={12}>
                        <Form.Item
                            label="Hợp đồng"
                            name="contract"
                        >
                            <Upload
                                showUploadList={false}
                                customRequest={(options) => customUpload(options)}
                                accept=".xlsx, .xls,.docx,.doc"
                                beforeUpload={(file, fileList) => beforeUpload(fileList)}
                                style={{ marginBottom: 0 }}
                                multiple={true}
                                openFileDialogOnClick
                                listType="text"
                            >
                                <Button
                                    type="primary"
                                    loading={loadingFile}
                                    style={{ marginBottom: 15, marginLeft: 0 }}
                                    disabled={(params.id && !editing) ? true : false}
                                >Lựa chọn</Button>
                                {fileError ? <div style={{ color: 'red' }}>{fileError}</div> : <></>}
                            </Upload>
                            {(uploadedFiles && uploadedFiles.length) ? uploadedFiles.map((item: any, index: number) => {
                                return (
                                    <div className='file-wrapper' style={{ marginTop: 10 }} key={item?.id || item.uid}>
                                        <span style={{ cursor: 'pointer' }} className="anchor-text" onClick={() => downloadFile(item?.id, item.name)}>{item.name}</span>
                                        {editing ? <XIcon
                                            onClick={() => onFileRemove(item, index)}
                                            style={{ cursor: 'pointer', width: 20, height: 20, marginLeft: 20, marginBottom: 0 }} />
                                            : <></>
                                        }
                                    </div>
                                )
                            }) : <></>}
                        </Form.Item>
                    </Col> */}
                </Row>
                <Row gutter={16} className='create-section'>
                    <span className="section-title">Thông tin tài khoản</span>
                    <Col span={8}>
                        <Form.Item
                            label="Tên đăng nhập"
                            name="username"
                            rules={[
                                { required: true, whitespace: true, message: "Vui lòng nhập Tên đăng nhập." }
                            ]}
                        >
                            <Input maxLength={50} placeholder="Nhập tên đăng nhập" disabled={(params.id && !editing) ? true : false} allowClear style={{ minWidth: '100%' }} autoComplete={"off"} />
                        </Form.Item>
                    </Col>
                    {!params.id ? <Col span={8}>
                        <Form.Item
                            label="Mật khẩu"
                            name="password"
                            rules={[
                                { required: true, message: "Vui lòng nhập mật khẩu." },
                                { pattern, message: patternMessage },
                            ]}
                        >
                            <Input.Password placeholder="Nhập mật khẩu" allowClear disabled={(params.id && !editing) ? true : false} style={{ minWidth: '100%' }} maxLength={20} autoComplete={"new-password"} />
                        </Form.Item>
                    </Col> : <></>}
                    <Col span={8}>
                        <Form.Item
                            label="Trạng thái"
                            name="status"
                        >
                            <Select
                                placeholder="Chọn trạng thái"
                                allowClear
                                disabled={(params.id && !editing) ? true : false}
                                defaultValue={0}
                            >
                                <Option value={0}>
                                    Hoạt động
                                </Option>
                                <Option value={1}>
                                    Không hoạt động
                                </Option>
                            </Select>
                        </Form.Item>
                    </Col>

                </Row>

                <Row gutter={16} className='create-section'>
                    <span className="section-title">Thông tin phân quyền</span>
                    <Col span={12}>
                        <Form.Item
                            label="Vai trò"
                            name="role"
                            rules={[
                                { required: true, message: "Vui lòng chọn Nhóm quyền" }
                            ]}
                        >
                            <Select
                                allowClear
                                placeholder="--Chọn Nhóm quyền--"
                                showSearch
                                optionFilterProp="label"
                                options={roleListResponse?.data?.filter(permission => permission.status === 0 && permission.isDelete === 0).map((item: { id: string; roleName: string; }) => ({
                                    label: item.roleName,
                                    value: item.id,
                                }))}
                                filterSort={(optionA, optionB) =>
                                    optionA.label.toLowerCase().localeCompare(optionB.label.toLowerCase())
                                }
                                disabled={(params.id && !editing) ? true : false}
                            >
                            </Select>
                        </Form.Item>
                    </Col>

                    {/* <Table
                        columns={columns}
                        dataSource={data}
                        expandable={{
                            expandIconColumnIndex: 0,
                            rowExpandable: record => Array.isArray(record.children),
                            showExpandColumn: false,
                            expandedRowKeys: expandedRowKeys,
                        }}
                        pagination={false}
                        style={{ width: '100%', marginTop: 18 }}
                    /> */}

                    <Tree
                        checkable
                        disabled
                        onExpand={onExpand}
                        expandedKeys={expandedKeys}
                        autoExpandParent={autoExpandParent}
                        checkedKeys={checkedKeys}
                        onSelect={onSelect}
                        selectedKeys={selectedKeys}
                        treeData={treeData}
                        style={{ marginTop: 18 }}
                    />
                </Row>
            </Form>

            {/* {modalVisible && (
                <ConfirmModalCustom
                    onCancel={() => setModalVisible(!modalVisible)}
                    cancelText="Huỷ"
                    okText="Đồng ý"
                    showCancel={true}
                    showOk={true}
                    type={"info"}
                    danger={true}
                    title={params?.id ? 'Chỉnh sửa người dùng' : 'Thêm mới người dùng'}
                    content={params?.id ?
                        "Bạn có chắc chắn muốn chỉnh sửa người dùng này hay không?" :
                        "Bạn có chắc chắn muốn thêm mới người dùng này hay không?"
                    }
                    visible={modalVisible}
                    onOk={() => form.submit()}
                />
            )} */}
        </div>
    )
}

export default CreateUser