import { Breadcrumb, Col, Form, Row, Select } from "antd";
import { ReactComponent as Home } from '../../assets/logos/home.svg';
import { useState } from "react";
import CampaignList from "./components/FieldConfigList";
import { TYPE_DATA } from "../../assets/ts/constant";

const RegisterInfo = () => {
    const [form] = Form.useForm();
    const [searchParam, setSearchParam] = useState({});

    const onValuesChange = (values: any) => {
        setSearchParam(values);
    }

    return (
        <>
            {/*<Breadcrumb separator="" style={{ marginBottom: 20, marginLeft: 20, marginTop: 20 }}>*/}
            {/*    <Breadcrumb.Item href="/home">*/}
            {/*        <Home style={{ width: 14, height: 14, marginRight: 20 }} /> Trang chủ*/}
            {/*    </Breadcrumb.Item>*/}
            {/*    <Breadcrumb.Separator> {'>'} </Breadcrumb.Separator>*/}
            {/*    <Breadcrumb.Item href="">*/}
            {/*        <span>Cấu hình trường thông tin</span>*/}
            {/*    </Breadcrumb.Item>*/}
            {/*</Breadcrumb>*/}

            <div className='title-label' style={{ marginBottom: 20 }}>Cấu hình trường thông tin</div>

            <div className='content-box' style={{ marginBottom: 30 }}>
                <Form
                    name="searchBox"
                    autoComplete="off"
                    layout="vertical"
                    form={form}
                    labelWrap
                    labelAlign="left"
                    colon={false}
                    onValuesChange={onValuesChange}
                >
                    <label className='search-title'>Lọc chung</label>
                    <Row gutter={16} style={{ marginTop: 8, marginBottom: 8 }}>
                        <Col span={8}>
                            <Form.Item
                                style={{ marginBottom: 10 }}
                                label="Loại dữ liệu"
                                name="typeId"
                            >
                                <Select
                                    allowClear
                                    placeholder="Chọn loại dữ liệu"
                                    optionFilterProp='label'
                                    options={Object.keys(TYPE_DATA).map(item => ({
                                        label: `${TYPE_DATA[item]} (${item})`,
                                        value: item
                                    }))}
                                    showSearch
                                    showArrow
                                >
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    {/* <div className='search-box-button'>
                        <Button htmlType='button' onClick={() => resetFormData()}>
                            Xóa điều kiện
                        </Button>
                        <Button htmlType='submit' type='primary'>Tìm kiếm</Button>
                    </div> */}
                </Form>
            </div>
            <CampaignList form={form} searchParam={searchParam} />
        </>
    )
}

export default RegisterInfo;
