import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { CommonListQuery, } from '../../../redux/api/apiTypes';
import { CampaginParams, FieldConfigResponse, FieldConfigData } from './apiTypes';

export const fieldConfigApi = createApi({
    reducerPath: 'fieldConfigApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_URL + "/api/v1",
        prepareHeaders: (headers, { getState }) => {
            const token = localStorage.getItem("accessToken");
            if (token) {
                headers.set('authorization', `Bearer ${token}`)
            }
            return headers;
        }
    }),
    endpoints: (builder) => ({
        getFieldConfig: builder.query<FieldConfigResponse, { searchParam?: CampaginParams } & Partial<CommonListQuery>>({
            query: (body) => ({
                url: `/fields/getPage?search=${body.searchParam ? encodeURIComponent(JSON.stringify(body.searchParam)) : ""}`,
                method: 'GET',
                params: {
                    page: body.page || 0,
                    size: body.size || 10
                }
            }),
            transformResponse: (response: { data: FieldConfigResponse }, meta, arg) => {
                return response.data;
            },
        }),
        getFieldConfigCount: builder.query<number, { searchParam?: CampaginParams } & Partial<CommonListQuery>>({
            query: (body) => ({
                url: `/fields/getPage-count?search=${body.searchParam ? encodeURIComponent(JSON.stringify(body.searchParam)) : ""}`,
                method: 'GET',
            }),
            transformResponse: (response: { data: number }, meta, arg) => {
                return response.data;
            },
        }),
        getFieldConfigById: builder.query<FieldConfigData, string>({
            query: (fieldConfigId) => ({
                url: `/fields/${fieldConfigId}`,
                method: 'GET',
            }),
            transformResponse: (response: { data: FieldConfigData }, meta, arg) => {
                return response.data;
            },
        }),
        editFieldConfig: builder.mutation<FieldConfigData, FieldConfigData>({
            query: (body) => ({
                url: `/fields`,
                method: 'PUT',
                body
            }),
            transformResponse: (response: { data: FieldConfigData }, meta, arg) => {
                return response.data;
            },
        }),
        createFieldConfig: builder.mutation<FieldConfigData, FieldConfigData>({
            query: (body) => ({
                url: `/fields`,
                method: 'POST',
                body
            }),
            transformResponse: (response: { data: FieldConfigData }, meta, arg) => {
                return response.data;
            },
        }),
    })
})

export const {
    useCreateFieldConfigMutation,
    useEditFieldConfigMutation,
    useLazyGetFieldConfigQuery,
    useLazyGetFieldConfigCountQuery,
    useLazyGetFieldConfigByIdQuery,
} = fieldConfigApi;
export default fieldConfigApi;