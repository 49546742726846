import './assets/css/index.css';
import Chart from './components/HomeChart';
import BarChart from './components/BarChart'
import { Col, Row } from 'antd';


const Home = () => {
    return (
        <div className='home'>
            <Row align='middle' gutter={24}>
                <Col span={9}>
                    <Chart />
                </Col>
                <Col span={15}>
                    <BarChart />
                </Col>
            </Row>
        </div>
    )
}

export default Home;