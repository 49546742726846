import {
    Button,
    Checkbox,
    Col,
    Collapse,
    Divider,
    Form,
    Input,
    notification,
    Row,
    Select,
    Space,
    Spin,
    Table, Tooltip, Tag, message, Anchor, Affix
} from "antd";
import {
   useLazyGetDataInfoInitByFilePdfQuery,
    useLazyGetFieldConfigQuery,
    useLazyGetRandomDataPdfQuery, useUpdateCompareMutation
} from "../redux/dataSyncApi";
import React, {useEffect, useLayoutEffect, useMemo, useRef, useState} from "react";
import {Link, useLocation, useParams} from "react-router-dom";
import {FieldConfig} from "../redux/apiTypes";
import {ReactComponent as Top} from '../../../assets/logos/layout-top-line.svg';
import {ReactComponent as Left} from '../../../assets/logos/layout-left-line.svg';
import {ReactComponent as ZoomOut} from '../../../assets/logos/zoom-out-line.svg';
import {ReactComponent as ZoomIn} from '../../../assets/logos/zoom-in-line.svg';
import {ReactComponent as Rotate} from '../../../assets/logos/rotate.svg';
import {Document, Page} from 'react-pdf/dist/esm/entry.webpack5';
import ConfirmModal from "../../common/components/ConfirmModal";
import useResizeObserver from '@react-hook/resize-observer';
import {DEFAULT_FIELD_CONFIG, QN_UBND, TYPE_FILE_PDF} from "../../../assets/ts/constant";
import {
    useLazyGetCommuneAllQuery,
    useLazyGetCommuneQuery,
    useLazyGetDistrictAllQuery,
    useLazyGetDistrictQuery,
    useLazyGetProvinceQuery
} from "../../../redux/api/commonApi";
import {Commune, District} from "../../../redux/api/apiTypes";
import {helpers} from "../../../assets/js/helper";
import {
    CheckCircleOutlined,
    ExclamationCircleOutlined,
    FullscreenOutlined,
    ClockCircleOutlined,
    UserOutlined
} from "@ant-design/icons";
import ViewFilePdf from "./ViewFilePdf";
import SplitPane from "react-split-pane";
import moment from "moment";
import {ColumnsType} from "antd/es/table";
import ListDataInfoInitModal from "./ListDataInfoInitModal";
import DragAndDropTable from "./DragAndDropTable";
import {ReactZoomPanPinchRef, TransformComponent, TransformWrapper} from 'react-zoom-pan-pinch';
import {PDFPageProxy} from 'react-pdf';
import {useLazyGetAllCommuneNoAuthQuery} from "../../category/commune/redux/communeApi";
import {useLazyGetAllDistrictNoAuthQuery} from "../../category/district/redux/districtApi";
import {useLazyGetAllProvinceNoAuthQuery} from "../../category/province/redux/provinceApi";
import {da} from "date-fns/locale";
import lodash from 'lodash';
import {useLazyGetHistoryQuery} from "../../data_init/redux/dataInitApi";

const {Panel} = Collapse;

const useWidth = (target: any) => {
    const [width, setWidth] = useState(300);

    useLayoutEffect(() => {
        setWidth(target.current.getBoundingClientRect().width)
    }, [target]);

    useResizeObserver(target, (entry: any) => setWidth(entry.contentRect.width));
    return width;
};

const CreateDataInfoLack = () => {
    const [form] = Form.useForm();
    const param = useParams();
    const [scale, setScale] = useState(1);
    const [rotate, setRotate] = useState(0);
    const [numPages, setNumPages] = useState(0);
    const [orderNumber, setOrderNumber] = useState(1);
    const [year, setYear] = useState<string>();
    const [displayFlex, setDisplayFlex] = useState(true);
    const [width, setWidth] = useState<any>();
    const [initWidth, setInitWidth] = useState<any>();
    const [height, setHeight] = useState<any>();
    const [showDiff, setShowDiff] = useState<boolean>(true);
    const [data, setData] = useState<any>();
    const [keyMaps, setKeyMaps] = useState<FieldConfig[]>([]);
    const [keyMap158s, setKeyMap158s] = useState<FieldConfig[]>([]);
    const [triggerFilePdf, filePdfResponse] = useLazyGetRandomDataPdfQuery();
    const [triggerHistory, historyResponse] = useLazyGetHistoryQuery();
    const [historyHaveEdit, setHistoryHaveEdit] = useState<string[]>([]);

    const [confirmNextVisible, setConfirmNextVisible] = useState(false);
    const [confirmVisible, setConfirmVisible] = useState(false);
    const [clearDataVisible, setClearDataVisible] = useState(false);
    const [editDataVisible, setEditDataVisible] = useState(false);
    const [viewPdfVisible, setViewPdfVisible] = useState(false);
    const [indexCompare, setIndexCompare] = useState<number>();
    const [triggerProvince, responseProvince] = useLazyGetAllProvinceNoAuthQuery();
    const [provinceObject, setProvinceObject] = useState();
    const [districtTrigger, responseDistrict] = useLazyGetDistrictQuery();
    const [communeTrigger, responseCommune] = useLazyGetCommuneQuery();
    const [districts, setDistricts] = useState<District[]>([]);
    const [communes, setCommunes] = useState<Commune[]>([]);
    const [districtObject, setDistrictObject] = useState<any>();
    const [communeObject, setCommuneObject] = useState<any>();
    const [communeAllTrigger, responseCommuneAll] = useLazyGetAllCommuneNoAuthQuery();
    const [districtAllTrigger, responseDistrictAll] = useLazyGetAllDistrictNoAuthQuery();
    const [pdfFileName, setPdfFileName] = useState('');
    const [listActive, setListActive] = useState<any>([]);
    const [triggerGetFieldConfig, fieldConfigResponse] = useLazyGetFieldConfigQuery();
    const [triggerGetDataInfoInitByFilePdf, dataInfoInitByFilePdfResponse] = useLazyGetDataInfoInitByFilePdfQuery();
    const wrapperDiv = useRef<HTMLInputElement>(null);
    const [tableData, setTableData] = useState<any[]>([]);
    const [columns, setColumns] = useState<ColumnsType<any>>([]);
    const [listDataInfoInitVisible, setListDataInfoInitVisible] = useState(false);
    const [currentDataInit, setCurrentDataInit] = useState<any>();
    const [currentDataInitActive, setCurrentDataInitActive] = useState<any>();
    const [itemSelected, setItemSelected] = useState<any>();
    const [updateDataApi] = useUpdateCompareMutation();

    const transformComponentRef = useRef<ReactZoomPanPinchRef>(null!);
    const canvas = useRef<any>();
    const canvas1 = useRef<any>();
    const [clientY, setClientY] = useState<number>(0);
    const [isRendered, setIsRendered] = useState<boolean>(false);
    const [isDrag, setIsDrag] = useState<boolean>(false);
    const prevScale = useRef<number>(1);
    useEffect(() => {
        if (clientY === 0 || !canvas.current || !isRendered) {
            return;
        }
        const context = canvas.current.getContext('2d');
        const {width} = canvas.current;
        const rect = canvas.current.getBoundingClientRect();
        context.save();
        context.beginPath();
        context.moveTo(0, clientY);
        context.lineTo(width, clientY);
        context.strokeStyle = 'red';
        context.stroke();
        context.restore();
        if (canvas1.current) {
            const context1 = canvas1.current.getContext('2d');
            const width1 = canvas1.current.width;
            const rect1 = canvas1.current.getBoundingClientRect();
            context1.save();
            context1.beginPath();
            context1.moveTo(0, clientY);
            context1.lineTo(width1, clientY);
            context1.strokeStyle = 'red';
            context1.stroke();
            context1.restore();
        }
    }, [clientY, isRendered]);
    useEffect(() => {
        if (prevScale.current === scale) {
            return;
        } else {
            transformComponentRef.current.resetTransform(0);
            transformComponentRef.current.centerView(1, 0);
            setClientY((prevClientY) => prevClientY * (scale / prevScale.current));
            setIsRendered(false);
        }
    }, [scale])
    const onRenderSuccess = (page: PDFPageProxy) => {
        setIsRendered(true);
    }
    const onClickPage = (event: React.MouseEvent, page: PDFPageProxy) => {
        if (!isDrag) {
            const rect = canvas.current.getBoundingClientRect();
            const scaleY = canvas.current.height / rect.height;
            setClientY((event.clientY - rect.top) * scaleY);
        }
    }

    const file = useMemo(() => {
        return {
            url: process.env.REACT_APP_API_URL + `/api/v1/file/download-pdf/${filePdfResponse?.data?.dataInfoInit?.filePDFId}`,
            httpHeaders: {
                Authorization: 'Bearer ' + localStorage.getItem("accessToken"),
                "Content-Type": "application/json;charset=UTF-8",
            },
        }
    }, [filePdfResponse?.data?.dataInfoInitId]);

    useEffect(() => {
        triggerFilePdf(lodash.get(param,'id',null));
    }, [triggerFilePdf, param?.id]);

    useEffect(() => {
        if (filePdfResponse?.data?.dataInfoInitId) {
            triggerHistory({ data_info_ids: [filePdfResponse?.data?.dataInfoInitId] });
        }
    }, [triggerHistory, filePdfResponse]);
    useEffect(() => {
        const slider = document.getElementById("block-view-pdf");
        let isDown = false;
        let startX: any;
        let startY: any;
        let scrollLeft: any;
        let scrollTop: any;
        if (slider) {
            slider.addEventListener('mousedown', (e) => {
                isDown = true;
                slider.classList.add('active');
                startX = e.pageX - slider.offsetLeft;
                startY = e.pageY - slider.offsetTop;
                scrollLeft = slider.scrollLeft;
                scrollTop = slider.scrollTop;
                setIsDrag(false);
            });
            slider.addEventListener('mouseleave', () => {
                isDown = false;
                slider.classList.remove('active');
            });
            slider.addEventListener('mouseup', () => {
                isDown = false;
                slider.classList.remove('active');
            });
            slider.addEventListener('mousemove', (e) => {
                if (!isDown) return;
                e.preventDefault();
                const x = e.pageX - slider.offsetLeft;
                const walk = (x - startX) * 3; //scroll-fast
                slider.scrollLeft = scrollLeft - walk;
                setIsDrag(true);
            });
            slider.addEventListener('mousemove', (e) => {
                if (!isDown) return;
                e.preventDefault();
                const x = e.pageY - slider.offsetTop;
                const walk = (x - startY) * 3; //scroll-fast
                slider.scrollTop = scrollTop - walk;
                setIsDrag(true);
            });
        }
    }, []);

    useEffect(() => {
        if (displayFlex) {
            setWidth((wrapperDiv?.current?.getBoundingClientRect()?.width || 0) / 3 || undefined);
            setInitWidth((wrapperDiv?.current?.getBoundingClientRect()?.width || 0) / 3 || undefined);
        }
    }, [wrapperDiv?.current?.getBoundingClientRect()?.width, displayFlex]);

    useEffect(() => {
        let typeFile = filePdfResponse?.data?.dataInfoInit?.filePDFName?.split(".")?.[0];
        if (typeFile && !filePdfResponse.isFetching) {
            // console.log('vao day');
            triggerGetFieldConfig({types: typeFile, years: convertYearToTypePdf(typeFile)});
        }
    }, [triggerGetFieldConfig, filePdfResponse?.data?.dataInfoInit?.filePDFName, filePdfResponse.isFetching]);
    useEffect(()=>{
        if(keyMaps?.length>0&&keyMap158s?.length>0&&fieldConfigResponse?.data?.fieldKeyMap?.length>0){
                let items:any=[];
            fieldConfigResponse?.data?.fieldKeyMap?.filter((x: any) => x.isDisplay).map((obj: any, index: number) => {
                let item: any = keyMaps.find((x: any) => x.dataKeyAscii === obj.dataKeyAscii);
                let item158: any = keyMap158s.find((x: any) => x.dataKeyAscii.replace('158', '') === obj.dataKeyAscii);
                let isDisable = item.dataValue == item158.dataValue;
                if(!isDisable&&(!item.isSelected&&!item158.isSelected))
                    items.push(obj.dataKeyAscii);
            });
            setListActive(items);
        }
    }, [fieldConfigResponse, keyMaps, keyMap158s]);
    useEffect(() => {
        triggerProvince({}).unwrap().then(res => {
            setProvinceObject(res?.reduce((a: any, v: any) => ({...a, [v.provinceCode]: v.id}), {}));
        });
        communeAllTrigger({}).unwrap().then(res => {
            setCommuneObject(res?.reduce((a: any, v: any) => ({...a, [v.communeCode]: v.id}), {}));
        });
        districtAllTrigger({}).unwrap().then(res => {
            setDistrictObject(res?.reduce((a: any, v: any) => ({...a, [v.districtCode]: v.id}), {}));
        });
    }, [triggerProvince, districtAllTrigger, communeAllTrigger]);

    useEffect(() => {
        if (dataInfoInitByFilePdfResponse?.data?.length && columns?.length) {
            setTableData(dataInfoInitByFilePdfResponse?.data?.map((item: any) => {
                if(item.id===filePdfResponse?.data?.id)
                    setCurrentDataInitActive(item);
                return {
                    key: item.id,
                    id: item.id,
                    status: item.status,
                    // ...item.dataKeyMap?.reduce((a: any, v: any) => ({ ...a, [v.dataKeyAscii]: v.dataKeyAscii === "quyenSo" ? <Link to={`/data/lack/info_init_detail/${item.id}`}>{v.dataValue || ""}</Link> : v.dataValue }), {})
                    ...item?.dataInfoInit?.dataKeyMap?.reduce((a: any, v: any) => ({...a, [v.dataKeyAscii]: v.dataValue}), {})
                }
            }));
        } else {
            setTableData([]);
        }
    }, [dataInfoInitByFilePdfResponse, columns, filePdfResponse]);
    useEffect(() => {
        if (filePdfResponse?.data?.dataInfoInit?.dataKeyMap?.length &&
            filePdfResponse?.data?.id &&
            provinceObject &&
            districtObject &&
            responseCommuneAll?.data
        ) {
            setInitData(filePdfResponse?.data, undefined);
            triggerGetDataInfoInitByFilePdf(filePdfResponse?.data?.id);
        } else {
            setKeyMaps([]);
        }
    }, [filePdfResponse, provinceObject, districtObject, responseCommuneAll?.data]);


    const convertYearToTypePdf = (typeFile: string) => {
        // KS.1994.01.--.001.PDF
        let yearStr = filePdfResponse?.data?.dataInfoInit?.filePDFName?.split(".")?.[1];
        let yearNum = yearStr ? parseInt(yearStr) : 0;
        // TODO: LƯU Ý CHECK LẠI RULE NÀY KHI TRIỂN KHAI CHO QUẢNG NGÃI (ĐẶC BIỆT GIAI ĐOẠN <= 1977)
        // Chuyển từ năm sang file loại file: A3, A4
        // console.log(filePdfResponse.data);
        if (filePdfResponse?.data?.paperSize) {
            // console.log('===>',filePdfResponse?.data?.paperSize);
            setYear(filePdfResponse?.data?.paperSize);
            return filePdfResponse?.data?.paperSize;
        }
        if (typeFile === "KH" && yearNum === 2007 && filePdfResponse?.data?.communeCode === "xatinhantay") {
            setYear(TYPE_FILE_PDF.A3);
            return TYPE_FILE_PDF.A3;
        }
        if (yearNum === 2007 && filePdfResponse?.data?.communeCode === "xatinhantay") {
            setYear(TYPE_FILE_PDF.A3);
            return TYPE_FILE_PDF.A3;
        }
        if (yearNum > 2006) {
            if (typeFile === "KH" &&
                filePdfResponse?.data?.communeCode === "sotuphap" &&
                filePdfResponse?.data?.districtCode === "thanhphoquangngai") {
                if (yearNum < 2011) {
                    setYear(TYPE_FILE_PDF.A3);
                    return TYPE_FILE_PDF.A3;
                }
            }
            setYear(TYPE_FILE_PDF.A4);
            return TYPE_FILE_PDF.A4;
        }
        if (yearNum >= 1977) {
            setYear(TYPE_FILE_PDF.A3);
            return TYPE_FILE_PDF.A3;
        }
        switch (typeFile) {
            case "KS":
                setYear(TYPE_FILE_PDF.A4);
                return TYPE_FILE_PDF.A4
            default:
                setYear(TYPE_FILE_PDF.A4);
                return TYPE_FILE_PDF.A4;
        }
    }

    const onFinish = (values: any) => {
        console.log('vao day');
        form.validateFields().then(res => {
            console.log('123456');
            toggleConfirmModal();
        }).catch(err => {
            console.log('vao day', err);
            notification.error({
                message: err?.errorFields?.[0].name?.[0] &&
                err?.errorFields?.[0].errors?.[0] ?
                    `${err?.errorFields?.[0].name?.[0]}: ${err?.errorFields?.[0].errors?.[0]}!`
                    : "Có lỗi nhập liệu"
            });
        })
    }


    const onAcceptClearData = () => {
        notification.success({message:'Xóa dữ liệu đã chọn thành công'});
        resetData();
        setInitData(filePdfResponse?.data, undefined);
        setListActive([]);
        toggleClearDataModal();
    }
    const onAcceptNextData = () => {
        notification.success({message:'Bỏ qua dữ liệu thành công'});
        setListActive([]);
        setKeyMap158s([]);
        setKeyMaps([]);
        form.resetFields();
        triggerFilePdf(null);
        toggleConfirmNextModal();
    }

    const changeLayout = (newDisplayFlex: boolean, order: number) => {
        setDisplayFlex(newDisplayFlex);
        setOrderNumber(order);
        if (!newDisplayFlex) {
            setWidth(initWidth * 2);
        }
    }
    const onDocumentLoadSuccess = ({numPages}: any) => {
        setNumPages(numPages);
    }

    const toggleConfirmModal = () => {
        setConfirmVisible(!confirmVisible);
    }

    const toggleConfirmNextModal = () => {
        setConfirmNextVisible(!confirmNextVisible);
    }

    const toggleClearDataModal = () => {
        setClearDataVisible(!clearDataVisible);
    }

    // Xác nhận chỉnh sửa dữ liệu
    const toggleEditDataModal = (record?:any) => {
        if(record)
            setItemSelected(record);
;        setEditDataVisible(!editDataVisible);
    }

    const toggleViewPdfModal = () => {
        setViewPdfVisible(!viewPdfVisible);
    }

    const toggleListDataInfoInitModal = () => {
        setListDataInfoInitVisible(!listDataInfoInitVisible);
    }

    const setInitData = (data: any, oldData?: any) => {
        // TODO: Thêm trường dữ liệu mặc định trong cấu hình trường dữ liệu
        let dataKeyMaps: any = lodash.cloneDeep(data?.dataInfoInit.dataKeyMap);
        let dataKeyMap158: any = lodash.cloneDeep(data.dataKeyMap158);
        let items: any = [];
        if (dataKeyMaps?.length) {
            dataKeyMaps.map((x: any) => {
                let temp = dataKeyMap158.find((xx: any) => xx.dataKeyAscii == x.dataKeyAscii);
                if (!temp) {
                    items.push({...x, dataKeyAscii: `${x.dataKeyAscii}158`});
                } else items.push({...temp, dataKeyAscii: `${temp.dataKeyAscii}158`});
            });
            setKeyMaps(dataKeyMaps.map((item: any, index: number) => {
                    form.setFieldsValue({
                        [item.dataKeyAscii]: item.dataValue
                    })
                return item;
            }))
            setKeyMap158s(items.map((item: any, index: number) => {
                form.setFieldsValue({
                    [item.dataKeyAscii]: item.dataValue
                })
                if (item.dataKeyAscii.replace('158', '')===DEFAULT_FIELD_CONFIG[filePdfResponse?.data?.dataInfoInit?.typeId]?.tinhThanh) {
                    districtTrigger(provinceObject?.[DEFAULT_FIELD_CONFIG.tinhThanh]).unwrap()
                        .then(dists => setDistricts(dists));
                }
                if (item.dataKeyAscii.replace('158', '')===DEFAULT_FIELD_CONFIG[filePdfResponse?.data?.dataInfoInit?.typeId]?.quanHuyen) {
                    communeTrigger(filePdfResponse?.data?.dataInfoInit?.districtId).unwrap()
                        .then(coms => setCommunes(coms));
                }

                return item;
            }))
        }
    }
    useEffect(() => {
        if (fieldConfigResponse?.data?.fieldKeyMap) {
            setColumns([{
                title: 'Trạng thái',
                dataIndex:'status',
                key: 'status',
                align: "center",
                width: 100,
                fixed: 'left',
                render:(text:any)=>  <Button
                    shape="round"
                    type={'primary'}
                    style={{backgroundColor: text==0?'red':'green', borderWidth:0}}
                    className={`btn__status btn__status--blue btn cursor-initial  _status`}>
                    {text==0?'Chưa đồng bộ':'Đồng bộ'}
                </Button>
            },
                ...(fieldConfigResponse?.data?.fieldKeyMap || "")
                    .filter((x: any) =>
                        // x.isDefault &&
                        x.isEdit &&
                        x.dataKeyAscii !== "tinhThanh" &&
                        x.dataKeyAscii !== "quanHuyen" &&
                        x.dataKeyAscii !== "phuongXa"
                    )
                    .map((item: any, index: number) => {
                        return {
                            title: item.dataKey,
                            dataIndex: item.dataKeyAscii,
                            key: item.dataKeyAscii,
                            align: "center",
                            width: item.dataKeyAscii == "so" ||
                            item.dataKeyAscii === "quyenSo" ||
                            item.dataKeyAscii === "trangSo" ||
                            item.type === "date" ? 125 : 250,
                            fixed: item.dataKeyAscii == "so" ||
                            item.dataKeyAscii === "quyenSo" ? 'left' : '',
                            render: (text: string, record: any) => {

                                return <span style={{color: record.key===currentDataInitActive.id?'#000000':'#1890FF'}}>{text}</span>;
                            },
                        }
                    })
            ]);
        }
    }, [fieldConfigResponse, currentDataInitActive]);
    // Set dữ liệu ban đầu

    const changeData = async (value: any, index: number, item: FieldConfig, isCheckbox?: boolean) => {
        // Nếu không cho sửa thì không được sửa
        // if (!item.isEdit) return;
        if (item?.dataKeyAscii&&!isCheckbox) {
            form.setFieldsValue({[item.dataKeyAscii]: value});
        }

        switch (item.dataKeyAscii) {
            case DEFAULT_FIELD_CONFIG[filePdfResponse?.data?.dataInfoInit?.typeId]?.tinhThanh:
                form.setFieldsValue({quanHuyen: undefined, phuongXa: undefined})
                setDistricts([]);
                setCommunes([]);
                districtTrigger(value).unwrap()
                    .then((res: any) => setDistricts(res));
                break;
            case DEFAULT_FIELD_CONFIG[filePdfResponse?.data?.dataInfoInit?.typeId]?.quanHuyen:
                form.setFieldsValue({phuongXa: undefined})
                setCommunes([]);
                communeTrigger(value).unwrap()
                    .then(res => setCommunes(res));
                break;
        }
        // // KS.2007.01.2007-07-11.082.pdf
        // let changeFilePdf = item.dataKeyAscii === DEFAULT_FIELD_CONFIG[filePdfResponse?.data?.dataInfoInit?.typeId]?.so ||
        //     item.dataKeyAscii === DEFAULT_FIELD_CONFIG[filePdfResponse?.data?.dataInfoInit?.typeId]?.quyenSo ||
        //     item.dataKeyAscii === DEFAULT_FIELD_CONFIG[filePdfResponse?.data?.dataInfoInit?.typeId]?.ngayDangKy;
        // let tenFilePdf = "";
        // if (changeFilePdf&&!isCheckbox) {
        //     let type = filePdfResponse?.data?.dataInfoInit?.filePDFName?.split(".")?.[0];
        //     let so = item.dataKeyAscii === DEFAULT_FIELD_CONFIG[filePdfResponse?.data?.dataInfoInit?.typeId]?.so ? value : form?.getFieldsValue()?.so;
        //     let quyenSo = ((item.dataKeyAscii === DEFAULT_FIELD_CONFIG[filePdfResponse?.data?.dataInfoInit?.typeId]?.quyenSo ? value : form?.getFieldsValue()?.quyenSo) || "").split("/");
        //     let ngayDangKy = ((item.dataKeyAscii === DEFAULT_FIELD_CONFIG[filePdfResponse?.data?.dataInfoInit?.typeId]?.ngayDangKy ? value : form?.getFieldsValue()?.ngayDangKy) || "").split(".");
        //     // ngayDangKy = ngayDangKy.length === 3 ? ngayDangKy : [];
        //     let temp = '';
        //     if (ngayDangKy?.length === 3) {
        //         temp = `${ngayDangKy?.[2] || ""}-${ngayDangKy?.[1] || ""}-${ngayDangKy?.[0] || ""}`;
        //     } else if (ngayDangKy?.length === 2) {
        //         temp = `${ngayDangKy?.[1] || ""}-${ngayDangKy?.[0] || ""}-`;
        //     } else {
        //         temp = `${ngayDangKy?.[0] || ""}--`;
        //     }
        //     tenFilePdf = `${type}.${quyenSo?.[1] || '--'}.${quyenSo?.[0] || '--'}.${temp}.${so || ""}.pdf`;
        //     form.setFieldsValue({
        //         [DEFAULT_FIELD_CONFIG[filePdfResponse?.data?.dataInfoInit?.typeId]?.tenfilepdf]: tenFilePdf,
        //     })
        //     setPdfFileName(tenFilePdf);
        // }
        //
        // let loaiDangKy: any;
        // let ngayDangKy1 = ((item.dataKeyAscii === DEFAULT_FIELD_CONFIG[filePdfResponse?.data?.dataInfoInit?.typeId]?.ngayDangKy ? value : form?.getFieldsValue()?.ngayDangKy) || "");
        //
        // // Loại đăng ký KS
        // let changeLoaiDangKy = item.dataKeyAscii === DEFAULT_FIELD_CONFIG[filePdfResponse?.data?.dataInfoInit?.typeId]?.ngayDangKy ||
        //     item.dataKeyAscii === DEFAULT_FIELD_CONFIG[filePdfResponse?.data?.dataInfoInit?.typeId]?.nksNgaySinh;
        // let nksNgaySinh = ((item.dataKeyAscii === DEFAULT_FIELD_CONFIG[filePdfResponse?.data?.dataInfoInit?.typeId]?.nksNgaySinh ? value : form?.getFieldsValue()?.nksNgaySinh) || "");
        //
        // // Loại đăng ký KT
        // let changeLoaiDangKyKT = item.dataKeyAscii === DEFAULT_FIELD_CONFIG[filePdfResponse?.data?.dataInfoInit?.typeId]?.ngayDangKy ||
        //     item.dataKeyAscii === DEFAULT_FIELD_CONFIG[filePdfResponse?.data?.dataInfoInit?.typeId]?.nktNgayChet;
        // let nktNgayChet = (item.dataKeyAscii === DEFAULT_FIELD_CONFIG[filePdfResponse?.data?.dataInfoInit?.typeId]?.nktNgayChet ?
        //     value : form?.getFieldsValue()?.nktNgayChet) || "";
        //
        //
        // if (changeLoaiDangKy && filePdfResponse?.data?.dataInfoInit?.typeId === "KS" && ngayDangKy1 && nksNgaySinh&&!isCheckbox) {
        //     loaiDangKy = autoFillLoaiDangKy(ngayDangKy1, nksNgaySinh);
        // }
        //
        // if (changeLoaiDangKyKT && filePdfResponse?.data?.dataInfoInit?.typeId === "KT" && ngayDangKy1 && nktNgayChet&&!isCheckbox) {
        //     loaiDangKy = autoFillLoaiDangKyKT(ngayDangKy1, nktNgayChet);
        // }
        // KS.2007.01.2007-07-11.082.pdf
        let changeFilePdf = item.dataKeyAscii === DEFAULT_FIELD_CONFIG[filePdfResponse?.data?.dataInfoInit?.typeId].so ||
            item.dataKeyAscii === DEFAULT_FIELD_CONFIG[filePdfResponse?.data?.dataInfoInit?.typeId].quyenSo ||
            item.dataKeyAscii === DEFAULT_FIELD_CONFIG[filePdfResponse?.data?.dataInfoInit?.typeId].ngayDangKy;
        let tenFilePdf = "";
        if (changeFilePdf) {
            let type = filePdfResponse?.data?.dataInfoInit?.typeId;
            let so = form?.getFieldsValue()?.[DEFAULT_FIELD_CONFIG[filePdfResponse?.data?.dataInfoInit?.typeId].so];
            let quyenSo = form?.getFieldsValue()?.quyenSo?.split("/");
            let ngayDangKy = form?.getFieldsValue()?.ngayDangKy?.split(".") || '';
            // ngayDangKy = ngayDangKy.length === 3 ? ngayDangKy : [];
            let temp = '';
            if(ngayDangKy?.length===3){
                temp = `${ngayDangKy?.[2] || ""}-${ngayDangKy?.[1] || ""}-${ngayDangKy?.[0] || ""}`;
            }else  if(ngayDangKy?.length===2){
                temp = `${ngayDangKy?.[1] || ""}-${ngayDangKy?.[0] || ""}-`;
            }else{
                temp = `${ngayDangKy?.[0] || ""}--`;
            }
            tenFilePdf = `${type}.${quyenSo?.[1] || '--'}.${quyenSo?.[0] || '--'}.${temp}.${so || ""}.pdf`;
            form.setFieldsValue({
                [DEFAULT_FIELD_CONFIG[filePdfResponse?.data?.dataInfoInit?.typeId].tenfilepdf]: tenFilePdf,
            })
            setPdfFileName(tenFilePdf);
        }
        let items: any = lodash.cloneDeep(keyMaps);
        let items158: any = lodash.cloneDeep(keyMap158s);
        if (item.dataKeyAscii.indexOf('158') > 0) {
            let pos = items158.findIndex((x:any)=>item.dataKeyAscii === x.dataKeyAscii);
            if(pos>=0){
                if(isCheckbox){
                    items158[pos].isSelected=value;
                    items[pos].isSelected=false;
                    form.setFieldsValue({[`${item.dataKeyAscii.replace('158','')}_CB`]: false, [`${item.dataKeyAscii}_CB`]: value});
                }else{
                    items158[index].dataValue=value;
                    console.log('day roi', item);
                    if(!item.dataKeyAscii.replace('158','').toLowerCase().includes("bangchu")){
                        let posText = items158.findIndex((x:any)=>x.dataKeyAscii.replace('158','').toLowerCase().includes(item.dataKeyAscii.replace('158','').toLowerCase()) && x.dataKeyAscii.replace('158','').toLowerCase().includes("bangchu"));
                        console.log('pos:', posText);
                        if(posText>=0){
                            let txt = helpers.dateToWord(value || '');
                            form.setFieldsValue({ [items158[posText].dataKeyAscii]: txt });
                            items158[posText].dataValue=txt;
                        }
                    }

                }
            }
        }else{
            let pos = items.findIndex((x:any)=>item.dataKeyAscii === x.dataKeyAscii);
            if(pos>=0){
                if(isCheckbox){
                    items[pos].isSelected=value;
                    items158[pos].isSelected=false;
                    form.setFieldsValue({[`${item.dataKeyAscii}158_CB`]: false, [`${item.dataKeyAscii}_CB`]: value});
                }else{
                    items[pos].dataValue=value;
                    if(!item.dataKeyAscii.replace('158','').toLowerCase().includes("bangchu")) {

                        let posText = items.findIndex((x: any) => x.dataKeyAscii.toLowerCase().includes(item.dataKeyAscii.toLowerCase()) && x.dataKeyAscii.toLowerCase().includes("bangchu"));
                        if (posText >= 0) {
                            let txt = helpers.dateToWord(value || '');
                            form.setFieldsValue({[items[posText].dataKeyAscii]: txt});
                            items[posText].dataValue = txt;
                        }
                    }
                }
            }
        }
        setKeyMaps(items);
        setKeyMap158s(items158);
    }

    // Chuyển trường dữ liệu thành item
    // <ExclamationCircleOutlined title="Đã duyệt qua" style={{ marginLeft: 5, color: "#faad14" }} />
    const convertTypeDataToElement = (item: FieldConfig, index: number) => {
        if (item.data && item.data.length) {
            return <Form.Item
                name={item.dataKeyAscii}
                style={{marginBottom: 10}}
                // label={<div style={{ display: "flex" }}>
                //     {listActive.indexOf(item.dataKeyAscii) !== -1 ?
                //         <CheckCircleOutlined title="Đã duyệt qua" color="success" style={{ margin: 5, color: "#52c41a" }} /> : <></>}
                //     <div style={{ fontWeight: 700, wordBreak: "break-word", color: item.isEdited ? "#ff4d4f" : "" }}>{item.dataKey || ""}</div>
                // </div>}
                rules={createRules(item)}
            >
                <Select
                    disabled={!item.isEdit}
                    allowClear
                    placeholder={item.placeholder || "--Lựa chọn--"}
                    optionFilterProp='label'
                    options={(item.data || []).map((x: any) => ({
                        label: `${x.id}${x.name ? (" - " + x.name) : ""}`,
                        value: x.id + ''
                    }))}
                    showSearch
                    showArrow
                    onChange={(value) => changeData(value, index, item)}
                    value={item.dataValue}
                    onBlur={() => onBlurItem(item)}
                >
                </Select>
            </Form.Item>
        }
        if (item.isDefault && filePdfResponse?.data?.dataInfoInit?.typeId && (!item.data || !item.data.length)) {
            switch (item.dataKeyAscii.replace('158', '')) {
                case DEFAULT_FIELD_CONFIG[filePdfResponse?.data?.dataInfoInit?.typeId]?.tinhThanh:
                    return <Form.Item
                        name={item.dataKeyAscii || ""}
                        style={{marginBottom: 10}}
                        // label={<div style={{ display: "flex" }}>
                        //     {listActive.indexOf(item.dataKeyAscii) !== -1 ?
                        //         <CheckCircleOutlined title="Đã duyệt qua" color="success" style={{ margin: 5, color: "#52c41a" }} /> : <></>}
                        //     <div style={{ fontWeight: 700, wordBreak: "break-word", color: item.isEdited ? "#ff4d4f" : "" }}>{item.dataKey || ""}</div>
                        // </div>}
                        rules={[
                            {required: true, message: "Trường dữ liệu bắt buộc"},
                        ]}
                    >
                        <Select
                            // disabled={!item.isEdit}
                            disabled
                            allowClear
                            placeholder={item.placeholder || "--Lựa chọn--"}
                            optionFilterProp='label'
                            options={(responseProvince?.data || []).map((item) => ({
                                label: item.provinceName,
                                value: item.id
                            }))}
                            showSearch
                            showArrow
                            onChange={(value) => changeData(value, index, item)} value={item.dataValue}
                            onBlur={() => onBlurItem(item)}
                        >
                        </Select>
                    </Form.Item>
                case DEFAULT_FIELD_CONFIG[filePdfResponse?.data?.dataInfoInit?.typeId]?.quanHuyen:
                    return <Form.Item
                        name={item.dataKeyAscii || ""}
                        style={{marginBottom: 10}}
                        // label={<div style={{ display: "flex" }}>
                        //     {listActive.indexOf(item.dataKeyAscii) !== -1 ?
                        //         <CheckCircleOutlined title="Đã duyệt qua" color="success" style={{ margin: 5, color: "#52c41a" }} /> : <></>}
                        //     <div style={{ fontWeight: 700, wordBreak: "break-word", color: item.isEdited ? "#ff4d4f" : "" }}>{item.dataKey || ""}</div>
                        // </div>}
                        rules={[
                            {required: true, message: "Trường dữ liệu bắt buộc"},
                        ]}
                    >
                        <Select
                            // disabled={!item.isEdit}
                            disabled
                            allowClear
                            placeholder={item.placeholder || "--Lựa chọn--"}
                            optionFilterProp='label'
                            options={(districts || []).map((item) => ({
                                label: item.districtName,
                                value: item.id
                            }))}
                            showSearch
                            showArrow
                            onChange={(value) => changeData(value, index, item)} value={item.dataValue}
                            onBlur={() => onBlurItem(item)}
                        >
                        </Select>
                    </Form.Item>
                case DEFAULT_FIELD_CONFIG[filePdfResponse?.data?.dataInfoInit?.typeId]?.phuongXa:
                    return <Form.Item
                        name={item.dataKeyAscii || ""}
                        style={{marginBottom: 10}}
                        // label={<div style={{ display: "flex" }}>
                        //     {listActive.indexOf(item.dataKeyAscii) !== -1 ?
                        //         <CheckCircleOutlined title="Đã duyệt qua" color="success" style={{ margin: 5, color: "#52c41a" }} /> : <></>}
                        //     <div style={{ fontWeight: 700, wordBreak: "break-word", color: item.isEdited ? "#ff4d4f" : "" }}>{item.dataKey || ""}</div>
                        // </div>}
                        rules={[
                            {required: true, message: "Trường dữ liệu bắt buộc"},
                        ]}
                    >
                        <Select
                            // disabled={!item.isEdit}
                            disabled
                            allowClear
                            placeholder={item.placeholder || "--Lựa chọn--"}
                            optionFilterProp='label'
                            options={(communes || []).map((item) => ({
                                label: item.communeName,
                                value: item.id
                            }))}
                            showSearch
                            showArrow
                            onChange={(value) => changeData(value, index, item)} value={item.dataValue}
                            onBlur={() => onBlurItem(item)}
                        >
                        </Select>
                    </Form.Item>
            }
        }
        return <Form.Item
            name={item.dataKeyAscii}
            style={{marginBottom: 10}}
            // label={<div style={{ display: "flex" }}>
            //     {listActive.indexOf(item.dataKeyAscii) !== -1 ?
            //         <CheckCircleOutlined title="Đã duyệt qua" color="success" style={{ margin: 5, color: "#52c41a" }} /> : <></>}
            //     <div style={{ fontWeight: 700, wordBreak: "break-word", color: item.isEdited ? "#ff4d4f" : "" }}>{item.dataKey || ""}</div>
            // </div>}
            rules={createRules(item)}
        ><Input.TextArea
            // autoFocus={item.dataKeyAscii.replace('158', '') === DEFAULT_FIELD_CONFIG[filePdfResponse?.data?.dataInfoInit?.typeId]?.so}
            placeholder={item.placeholder || ""}
            disabled={!item.isEdit || item.dataKeyAscii.replace('158', '') === DEFAULT_FIELD_CONFIG[filePdfResponse?.data?.dataInfoInit?.typeId]?.quyenSo}
            onFocus={(e) => e.currentTarget.select()}
            // onChange={(e) => changeData(e.target.value, index, item)}
            defaultValue={item.dataValue}
            onBlur={(e) => updateData(e.target.value, index, item)}
        /></Form.Item>;
    }

    const onBlurItem = (item: any) => {
        // if (listActive.indexOf(item.dataKeyAscii) === -1) {
        //     setListActive([...listActive, item.dataKeyAscii]);
        // }
    }

    // Format lại dữ liệu sau khi nhập liệu
    const updateData = (value: any, index: number, item: FieldConfig) => {
        if (item.type === "date") {
            if(item.dataKeyAscii.indexOf('nksNgaySinh')==0&& (value.length<4||value.indexOf('tuổi')>0)){
                value = value;
            }
            else value = helpers.customDate(value);
            console.log('vao day', value);
            changeData(value, index, item);
        } else if (item.type === "time" && value) {
            value = helpers.customTime(value, item.format);
            changeData(value, index, item);
        } else if (item.format?.toLowerCase().includes("capitalcase")) {
            value = helpers.toTitleCase(value);
            changeData(value, index, item);
        } else {
            changeData((value || "").trim(), index, item);
        }
        form.validateFields([item.dataKeyAscii]);
        onBlurItem(item);
    }


    // Tạo rules validate cho trường dữ liệu
    const createRules = (item: any) => {
        let rules: Object[] = [];
        if (item.isDefault) {
            rules = [...rules, {required: true, message: "Trường dữ liệu bắt buộc"}];
        }
        if (item.format && item.format !== "capitalcase") {
            rules = [...rules, {pattern: new RegExp(item.format), message: "Dữ liệu không đúng định dạng"}];
        }
        return rules;
    }

    const resetData = () => {
        if (filePdfResponse?.data?.dataInfoInit?.dataKeyMap?.length) {
            setKeyMaps(filePdfResponse?.data?.dataInfoInit?.dataKeyMap);
        } else {
            setKeyMaps([]);
        }
        form.resetFields();
    }

    const onSubmitSuccess = () => {
       let body = lodash.cloneDeep(filePdfResponse.data);
       body.dataInfoInit.dataKeyMap.map((obj:any)=>{
           let item: any = keyMaps.find((x: any) => x.dataKeyAscii === obj.dataKeyAscii);
           let item158: any = keyMap158s.find((x: any) => x.dataKeyAscii.replace('158', '') === obj.dataKeyAscii);
            if(item.isSelected){
                obj.dataValue=item.dataValue;
            }else if(item158.isSelected){
                obj.dataValue=item158.dataValue;
            }
       });
        updateDataApi(body).unwrap()
            .then(res => {
                notification.success({ message: "Cập nhật kết quả thành công" });
                setListActive([]);
                setKeyMap158s([]);
                setKeyMaps([]);
                form.resetFields();
                triggerFilePdf(null);
                toggleConfirmModal();
            }).catch(err => {
                console.log(err);
            notification.error({ message: err.data?.message || "Có lỗi xảy ra!" })
        })
    }

    const checkActiveSave = () => {
       if(listActive.length===0)
           return true;
       return false;
    }

    const changeSize = (size: number) => {
        console.log('day roi', size);
        if (displayFlex) {
            setWidth(size);
            setClientY(clientY * (size / width));
        } else {
            setHeight(size);
            setClientY(clientY * (size / height));
        }
        setIsRendered(false);
    }

    const autoFillLoaiDangKy = (ngayDangKy: string, ngaySinh: string) => {
        let ngayDangKyDate = moment(ngayDangKy, "DD.MM.YYYY").toDate().getTime();
        let ngaySinhDate = moment(ngaySinh, "DD.MM.YYYY").toDate().getTime();
        let timeCompare = (ngayDangKyDate - ngaySinhDate) / (1000 * 60 * 60 * 24);
        if (timeCompare < 0) return undefined;
        if (timeCompare <= 60) return "1";
        if (timeCompare / 365 > 10) return "2";
        if (timeCompare > 60) return "4";
    }


    const autoFillLoaiDangKyKT = (ngayDangKy: string, ngaySinh: string) => {
        let ngayDangKyDate = moment(ngayDangKy, "DD.MM.YYYY").toDate().getTime();
        let ngaySinhDate = moment(ngaySinh, "DD.MM.YYYY").toDate().getTime();
        let timeCompare = (ngayDangKyDate - ngaySinhDate) / (1000 * 60 * 60 * 24);
        if (timeCompare < 0) return undefined;
        if (timeCompare <= 15) return "1";
        if (timeCompare / 365 > 10) return "2";
        if (timeCompare > 15) return "4";
    }

    const editDataInfoInit = (record: any) => {
        let dataInit = dataInfoInitByFilePdfResponse?.data?.find(item => item.id === record.key);
        if (dataInit) {
            setCurrentDataInit(dataInit);
            let typeFile = filePdfResponse?.data?.dataInfoInit?.filePDFName?.split(".")?.[0];
            if (typeFile) {
                let dataKeys = dataInit?.dataKeyMap?.reduce((a: any, v: any) => ({...a, [v.dataKeyAscii]: v}), {});
                let dataFieldValue = dataInit?.dataKeyMap?.reduce((a: any, v: any) => ({
                    ...a,
                    [v.dataKeyAscii]: v.dataValue
                }), {});

                // Cập nhật thông tin đầu vào cho data info init
                form.setFieldsValue(dataFieldValue);
                if (dataFieldValue[DEFAULT_FIELD_CONFIG[typeFile]?.tinhThanh]) {
                    districtTrigger(dataFieldValue[DEFAULT_FIELD_CONFIG[typeFile]?.tinhThanh]).unwrap()
                        .then(dists => setDistricts(dists));
                }
                if (dataFieldValue[DEFAULT_FIELD_CONFIG[typeFile]?.quanHuyen]) {
                    communeTrigger(dataFieldValue[DEFAULT_FIELD_CONFIG[typeFile]?.quanHuyen]).unwrap()
                        .then(coms => setCommunes(coms));
                }

                if (dataFieldValue?.[DEFAULT_FIELD_CONFIG[typeFile]?.tenfilepdf]) {
                    setPdfFileName(dataFieldValue[DEFAULT_FIELD_CONFIG?.[typeFile]?.tenfilepdf]);
                }

                // Cập nhật thông tin key maps
                // setKeyMaps(keyMaps.map((item: any, index: number) => {
                //     return {
                //         ...item,
                //         dataValue: dataKeys[item.dataKeyAscii]?.dataValue,
                //         isEdited: false,
                //         beforeEdited: dataKeys[item.dataKeyAscii]?.isEdited,
                //     }
                // }));
            }
        }
    }

    const indexCurrentItem = () => {
        let currentIndex = -1;
        tableData.map((item, index) => {
            if (item.key === currentDataInit?.id) currentIndex = index;
        })
        return currentIndex;
    }

    const backDataInfoInit = () => {
        setListActive([]);
        if (tableData?.[indexCurrentItem() - 1]) {
            editDataInfoInit(tableData?.[indexCurrentItem() - 1])
        } else {
            notification.error({
                message: "Không có THHT trước!",
            })
        }
    }

    const nextDataInfoInit = () => {
        setListActive([]);
        if (currentDataInit) {
            if (tableData?.[indexCurrentItem() + 1]) {
                editDataInfoInit(tableData?.[indexCurrentItem() + 1])
            } else {
                notification.error({
                    message: "Không có THHT sau!",
                })
            }
        } else {
            editDataInfoInit(tableData?.[0])
        }
    }

    const renderItem=(obj: any, item: any, itemOrigin: any, index: number)=>{
        if(itemOrigin&&item?.dataValue!==itemOrigin?.dataValue)
        {
            let current = lodash.get(item,'dataValue','');
            let old = lodash.get(itemOrigin,'dataValue','');
            return <Tooltip color={'#ffffff'} title={<span style={{color:'#000000'}}>{`Dữ liệu thay đổi: `}{old?old:<span style={{color:'red'}}>Không có dữ liệu</span>} {`->`} {current?current:<span  style={{color:'red'}}>Không có dữ liệu</span>}</span>}>
                {convertTypeDataToElement({...obj,dataKey: item.dataKey, dataKeyAscii: item.dataKeyAscii, dataValue: item.dataValue, isEdit: item.isEdit}, index)}
            </Tooltip>
        }
        else return convertTypeDataToElement({...obj,dataKey: item.dataKey, dataKeyAscii: item.dataKeyAscii, dataValue: item.dataValue, isEdit: item.isEdit}, index);
    }
    const displayHeaderHistory = (item: any, index: number) => {
        if (item.createBy) {
            return <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                <div>
                    <span>
                        {item.createBy} đã <Tag color="#2db7f5">Kiểm tra dữ liệu</Tag>
                    </span>
                    {/* <span>trong chiến dịch <Tag color="#2db7f5">{item?.dataCampaign?.campaignName}</Tag></span> */}
                    {/* <span>
						{!index && !page ? <Tag color="#87d068">NOW</Tag> : ""}
					</span> */}
                    <span onClick={e => e.stopPropagation()}>
                        <Checkbox
                            checked={historyHaveEdit.indexOf(item.id) !== -1}
                            onChange={(e) => changeHistoryHaveEdit(e, item)}
                        >Chỉ xem trường đã sửa</Checkbox>
                    </span>
                </div>
                <div><ClockCircleOutlined /> {moment(item.createdDate).format("HH:mm DD/MM/YYYY")}</div>
            </div>
        }
        return <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
            <div>{item.createBy} đã <Tag color="#2db7f5">Thêm mới dữ liệu</Tag></div>
            <div><ClockCircleOutlined /> {moment(item.createdDate).format("HH:mm DD/MM/YYYY")}</div>
        </div>
    }

    const changeHistoryHaveEdit = (e: any, item: any) => {
        e.stopPropagation();
        let historys = [...historyHaveEdit];
        let indexOfItem = historyHaveEdit.indexOf(item.id);
        if (indexOfItem !== -1) {
            historys.splice(indexOfItem, 1);
        } else {
            historys.push(item.id);
        }
        setHistoryHaveEdit(historys);
    }
    const onAcceptEditData = () => {
        setListActive([]);
        setKeyMap158s([]);
        setKeyMaps([]);
        form.resetFields();
        triggerFilePdf(currentDataInitActive?.id);
        toggleEditDataModal();
    }
    const convertTypeDataToElementHis = (item: FieldConfig, index: number) => {
        let dataKeys = keyMaps?.reduce((a: any, v: any) => ({ ...a, [v.dataKeyAscii]: v }), {});
        item = {
            ...dataKeys?.[item.dataKeyAscii],
            dataValue: item.dataValue
        }
        if (item.data && item.data.length) {
            return <Form.Item
                style={{ marginBottom: 10 }}
                label={<div style={{ display: "flex" }}>
                    <div
                        style={{
                            fontWeight: 700,
                            wordBreak: "break-word",
                            color: item.isEdited || item.beforeEdited ? "#ff4d4f" : "",
                        }}>{item.dataKey || ""}</div>
                </div>}
                rules={createRules(item)}
            >
                <Select
                    className="custom-select"
                    disabled
                    allowClear
                    placeholder={item.placeholder || "--Lựa chọn--"}
                    optionFilterProp='label'
                    options={(item.data || []).map((x: any) => ({
                        label: `${x.id}${x.name ? (" - " + x.name) : ""}`,
                        value: x.id
                    }))}
                    showSearch
                    showArrow
                    value={item.dataValue}
                >
                </Select>
            </Form.Item>
        }

        if (item.isDefault && fieldConfigResponse?.data?.type && (!item.data || !item.data.length)) {
            switch (item.dataKeyAscii) {
                case DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type].tinhThanh:
                    return <Form.Item
                        style={{ marginBottom: 10 }}
                        label={<div style={{ display: "flex" }}>
                            <div
                                style={{
                                    fontWeight: 700,
                                    wordBreak: "break-word",
                                    color: item.isEdited || item.beforeEdited ? "#ff4d4f" : "",
                                }}>{item.dataKey || ""}</div>
                        </div>}
                        rules={[
                            { required: true, message: "Trường dữ liệu bắt buộc" },
                        ]}
                    >
                        <Select
                            disabled
                            allowClear
                            placeholder={item.placeholder || "--Lựa chọn--"}
                            optionFilterProp='label'
                            options={(responseProvince?.data || []).map((item) => ({
                                label: item.provinceName,
                                value: item.id
                            }))}
                            showSearch
                            showArrow
                            value={item.dataValue}
                        >
                        </Select>
                    </Form.Item>
                case DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type].quanHuyen:
                    return <Form.Item
                        style={{ marginBottom: 10 }}
                        label={<div style={{ display: "flex" }}>
                            <div
                                style={{
                                    fontWeight: 700,
                                    wordBreak: "break-word",
                                    color: item.isEdited || item.beforeEdited ? "#ff4d4f" : "",
                                }}>{item.dataKey || ""}</div>
                        </div>}
                        rules={[
                            { required: true, message: "Trường dữ liệu bắt buộc" },
                        ]}
                    >
                        <Select
                            disabled
                            allowClear
                            placeholder={item.placeholder || "--Lựa chọn--"}
                            optionFilterProp='label'
                            options={(districts || []).map((item) => ({
                                label: item.districtName,
                                value: item.id
                            }))}
                            showSearch
                            showArrow
                            value={item.dataValue}
                        >
                        </Select>
                    </Form.Item>
                case DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type].phuongXa:
                    return <Form.Item
                        style={{ marginBottom: 10 }}
                        label={<div style={{ display: "flex" }}>
                            <div
                                style={{
                                    fontWeight: 700,
                                    wordBreak: "break-word",
                                    color: item.isEdited || item.beforeEdited ? "#ff4d4f" : "",
                                }}>{item.dataKey || ""}</div>                        </div>}
                        rules={[
                            { required: true, message: "Trường dữ liệu bắt buộc" },
                        ]}
                    >
                        <Select
                            disabled
                            allowClear
                            placeholder={item.placeholder || "--Lựa chọn--"}
                            optionFilterProp='label'
                            options={(communes || []).map((item) => ({
                                label: item.communeName,
                                value: item.id
                            }))}
                            showSearch
                            showArrow
                            value={item.dataValue}
                        >
                        </Select>
                    </Form.Item>
            }
        }
        return <Form.Item
            style={{ marginBottom: 10 }}
            label={<div style={{ display: "flex" }}>
                <div
                    style={{
                        fontWeight: 700,
                        wordBreak: "break-word",
                        color: item.isEdited || item.beforeEdited ? "#ff4d4f" : "",
                    }}>{item.dataKey || ""}</div>            </div>}
            rules={createRules(item)}
        ><Input.TextArea
            placeholder={item.placeholder || ''}
            disabled
            onFocus={(e) => e.currentTarget.select()}
            value={item.dataValue}
        /></Form.Item>;
    }
    const handleCopy = () => {
        const textToCopy = window.location.protocol + '//' + window.location.hostname + ':' + window.location.port + '/data/info_init_detail/' + filePdfResponse?.data?.dataInfoInitId;
        if (navigator.clipboard) {
            navigator.clipboard.writeText(textToCopy)
                .then(() => {
                    notification.success({message: 'Đã sao chép thành công'})
                    console.log('Đã sao chép thành công:', textToCopy);
                })
                .catch((error) => {
                    notification.error({message: 'Lỗi sao chép'})
                    console.error('Lỗi sao chép:', error);
                });
        } else {
            // Use the 'out of viewport hidden text area' trick
            const textArea = document.createElement("textarea");
            textArea.value = textToCopy;

            // Move textarea out of the viewport so it's not visible
            textArea.style.position = "absolute";
            textArea.style.left = "-999999px";

            document.body.prepend(textArea);
            textArea.select();

            try {
                document.execCommand('copy');
                notification.success({message: 'Đã sao chép thành công'})
            } catch (error) {
                console.error(error);
            } finally {
                textArea.remove();
            }
        }
    };
    const [displayA, setdisplayA] = useState(false);
    return (
        // <Spin tip="Đang tải..." spinning={fieldConfigResponse.isFetching}>
        <>
            <div className="content-box" style={{margin: "0px 20px", padding: "20px"}}>
                <div className="header-block" style={{ display: "flex", justifyContent: "space-between" }}>
                    <div>
                        <UserOutlined
                            style={{
                                cursor: 'pointer',
                                width: 20,
                                height: 20,
                                marginBottom: 0
                            }} />
                        <span style={{ margin: "0px 3px" }}>
                            {filePdfResponse?.data?.createBy}
                        </span>
                        - <ClockCircleOutlined style={{ cursor: 'pointer', width: 20, height: 20, marginBottom: 0 }} />
                        <span style={{ margin: "0px 3px" }}>{moment(filePdfResponse?.data?.createdDate).format("HH:mm DD/MM/YYYY")} </span>
                        -
                        <span>
                            <a href={'/data/info_init_detail/' + filePdfResponse?.data?.dataInfoInitId} target="_blank"> id = {filePdfResponse?.data?.dataInfoInitId}</a> <span onClick={handleCopy} style={{cursor: 'pointer'}}>(copy link)</span>
                        </span>
                        -
                        <span onClick={() => setdisplayA(!displayA )}><a href="#">Danh sách dữ liệu đã nhập</a></span>
                    </div>
                    <div>
                    <Top onClick={() => changeLayout(false, 1)}
                         style={{cursor: 'pointer', width: 20, height: 20, marginLeft: 20, marginBottom: 0}}/>
                    {/* <Right onClick={() => changeLayout(true, 3)} style={{ cursor: 'pointer', width: 20, height: 20, marginLeft: 20, marginBottom: 0 }} /> */}
                    {/* <Bottom onClick={() => changeLayout(false, 2)} style={{ cursor: 'pointer', width: 20, height: 20, marginLeft: 20, marginBottom: 0 }} /> */}
                    <Left onClick={() => changeLayout(true, 1)}
                          style={{cursor: 'pointer', width: 20, height: 20, marginLeft: 20, marginBottom: 0}}/>
                    </div>
                </div>
                <div>
                    {displayA ? <><DragAndDropTable
                                toggleEditDataModal={() => { }}
                                setCurrentDataInitActive={() => { }}
                                setTableData={setTableData}
                                tableData={tableData}
                                columns={columns}
                        /></> : <></>}
                </div>
                <div className="body-block-create-info-init" ref={wrapperDiv}>
                    <SplitPane
                        split={displayFlex ? "vertical" : "horizontal"} size={displayFlex ? width : height}
                        minSize={"50%"}
                        onChange={(size) => changeSize(size)}
                        maxSize={"100%"}
                    >
                        <div
                            style={displayFlex ? {
                                order: orderNumber,
                                padding: 10,
                                width: "100%",
                                // resize: "horizontal",
                                overflow: "auto"
                            } : {
                                // borderBottom: orderNumber === 1 ? "dashed 2px #E5E5E5" : "",
                                order: orderNumber,
                                padding: 10,
                                width: "100%",
                                overflow: "auto"
                            }}
                        >
                            <div className="header-block">
                                <div>
                                    <label className='search-title'>File
                                        PDF: {filePdfResponse?.data?.dataInfoInit?.filePDFName || ''}</label>
                                    <ZoomOut
                                        onClick={() => setScale((prev: number) => {
                                            prevScale.current = prev;
                                            return scale === 1 ? 1 : scale - 1
                                        })}
                                        style={{
                                            cursor: 'pointer',
                                            width: 20,
                                            height: 20,
                                            marginLeft: 20,
                                            marginBottom: 0
                                        }}/>
                                    <ZoomIn
                                        onClick={() => setScale((prev: number) => {
                                            prevScale.current = prev;
                                            return scale + 1
                                        })}
                                        style={{
                                            cursor: 'pointer',
                                            width: 20,
                                            height: 20,
                                            marginLeft: 20,
                                            marginBottom: 0
                                        }}/>
                                    <Rotate
                                        onClick={() => setRotate(rotate - 90)}
                                        style={{
                                            cursor: 'pointer',
                                            width: 20,
                                            height: 20,
                                            marginLeft: 20,
                                            marginBottom: 0
                                        }}/>
                                </div>
                                <div>
                                    <label
                                        style={{ wordBreak: "break-word" }}
                                        className='search-title'
                                    >({filePdfResponse?.data?.filePDF?.pathFile || ''})</label>
                                </div>
                            </div>
                            <div
                                style={{
                                    overflowY: "auto",
                                    height: displayFlex ? "calc(100vh - 160px)" : "calc(100% - 55px)",
                                }}
                                id="block-view-pdf"
                            >
                                <TransformWrapper
                                    maxScale={3}
                                    initialScale={1}
                                    disablePadding={true}
                                    ref={transformComponentRef}
                                    onZoomStop={(ref: ReactZoomPanPinchRef, event: any) => {
                                        if (ref.state.scale >= 3) {
                                            setScale((prev: number) => {
                                                prevScale.current = prev;
                                                return prev + 1
                                            });
                                        } else if (ref.state.scale <= 1) {
                                            setScale((prev: number) => {
                                                prevScale.current = prev;
                                                return prev - 1 || 1
                                            });
                                        }
                                    }}
                                >
                                    <TransformComponent>
                                        <Document
                                            file={file}
                                            onLoadSuccess={onDocumentLoadSuccess}>
                                            {Array.from(new Array(Math.round(numPages / 2)), (el, index) => (
                                                <div style={{display: "flex"}} key={`grouppage${index + 1}`}>
                                                    <Page
                                                        width={width}
                                                        // height={height}
                                                        rotate={rotate}
                                                        scale={scale}
                                                        canvasRef={canvas}
                                                        key={`page_${index * 2 + 1}`}
                                                        pageNumber={index * 2 + 1}
                                                        onRenderSuccess={onRenderSuccess}
                                                        onClick={onClickPage}
                                                    />
                                                    {
                                                        (index * 2 + 2) <= numPages ? <Page
                                                            width={width}
                                                            // height={height}
                                                            rotate={rotate}
                                                            scale={scale}
                                                            key={`page_${index * 2 + 2}`}
                                                            pageNumber={index * 2 + 2}
                                                            canvasRef={canvas1}
                                                            onRenderSuccess={onRenderSuccess}
                                                            onClick={onClickPage}
                                                        /> : <></>
                                                    }

                                                </div>
                                            ))}
                                        </Document>
                                    </TransformComponent>
                                </TransformWrapper>
                            </div>
                        </div>
                        <div
                            style={displayFlex ? {
                                order: orderNumber === 1 ? 3 : 1,
                                padding: 10,
                                width: "100%",
                                boxSizing: "border-box",
                                position: "absolute"
                                // resize: "horizontal",
                                // overflow: "auto"
                            } : {
                                // borderBottom: orderNumber !== 1 ? "dashed 2px #E5E5E5" : "",
                                order: orderNumber === 1 ? 3 : 1,
                                padding: 10,
                                width: "100%",
                                boxSizing: "border-box",
                                // position: "absolute"
                            }}
                        >
                            <Form
                                name="searchBox"
                                autoComplete="off"
                                form={form}
                                colon={false}
                                style={{marginTop: 0}}
                                layout="vertical"
                                labelWrap
                                labelAlign="left"
                            >
                                {/*<label className='search-title'>Dữ liệu THHT: {pdfFileName}</label>*/}
                                {/*<div style={{display: "flex", justifyContent: "space-between"}}>*/}
                                {/*    /!* <div style={{ display: "flex", justifyContent: "space-between" }}> *!/*/}
                                {/*    <div>*/}
                                {/*        {indexCurrentItem() > 0 ?*/}
                                {/*            <Button htmlType='button' onClick={() => backDataInfoInit()}>*/}
                                {/*                THHT trước*/}
                                {/*            </Button> : <></>}*/}
                                {/*        {indexCurrentItem() < tableData?.length - 1 && tableData?.length ?*/}
                                {/*            <Button htmlType='button' onClick={() => nextDataInfoInit()}>*/}
                                {/*                {currentDataInit ? "THHT sau" : "Bắt đầu kiểm tra"}*/}
                                {/*            </Button> : <></>}*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                {/*<div*/}
                                {/*    style={{ color: "#ff4d4f", marginBottom: 10 }}*/}
                                {/*>Lưu ý*: Nút lưu dữ liệu chỉ hoạt động khi bạn chỉnh sửa/nhập mới</div>*/}
                                <Affix offsetTop={20}>
                                    <Row gutter={8} className={'align-items-center'}>
                                        <Col span={6}>
                                            <Checkbox checked={showDiff}
                                                      onChange={e => setShowDiff(e.target.checked)}>Chỉ hiển thị dữ liệu lệch</Checkbox>
                                        </Col>
                                        <Col span={8}>
                                            <div style={{fontWeight: 700, color: '#000000', textAlign: 'center'}}>CSDL
                                                Gốc
                                            </div>
                                            <Divider></Divider>
                                        </Col>
                                        <Col span={8}>
                                            <div
                                                style={{fontWeight: 700, color: '#000000', textAlign: 'center'}}>PM158
                                            </div>
                                            <Divider></Divider>
                                        </Col>
                                    </Row>
                                </Affix>
                                <div style={{
                                    overflowY: "auto",
                                    overflowX: "hidden",
                                    height: "calc(100vh - 285px)",
                                    paddingRight: 10
                                }}>

                                    {fieldConfigResponse?.data?.fieldKeyMap?.length>0 && fieldConfigResponse?.data?.fieldKeyMap?.filter((x: any) => x.isDisplay).map((obj: any, index: number) => {
                                        let item: any = keyMaps.find((x: any) => x.dataKeyAscii === obj.dataKeyAscii);
                                        let item158: any = keyMap158s.find((x: any) => x.dataKeyAscii.replace('158', '') === obj.dataKeyAscii);
                                        if(!item||!item158) return;
                                        let itemOrigin = filePdfResponse?.data?.dataInfoInit?.dataKeyMap?.find((x:any)=>x.dataKeyAscii===obj.dataKeyAscii);
                                        let item158Origin = filePdfResponse?.data?.dataKeyMap158?.find((x:any)=>x.dataKeyAscii.replace('158', '')===obj.dataKeyAscii);
                                        item.data = obj.data;
                                        item158.data = obj.data;
                                        item158.isEdit=false;
                                        let isDisableOrigin = itemOrigin?.dataValue === item158Origin?.dataValue;
                                        let isDisable = item?.dataValue === item158?.dataValue;
                                        if(!item158Origin&&showDiff) return <></>;
                                        return <Row gutter={8} className={'align-items-center'}>
                                            <Col span={6}>
                                                <div style={{ display: "flex" }}>
                                                    <div style={{
                                                        fontWeight: 700,
                                                        wordBreak: "break-word",
                                                        color: '#000000'
                                                    }}>{`${index + 1}.${item.dataKey}`}</div>
                                                    {!isDisable&&!listActive.includes(item.dataKeyAscii)?
                                                        <CheckCircleOutlined title="Đã duyệt qua" color="success" style={{ margin: 5, color: "#52c41a" }} /> : <></>}
                                                     </div>
                                            </Col>
                                            <Col span={7}>
                                                {renderItem(obj, item, itemOrigin, index)}
                                            </Col>
                                            <Col span={2}>
                                                <Form.Item name={`${item.dataKeyAscii}_CB`} valuePropName="checked">
                                                    <Checkbox checked={item.isSelected}
                                                              onChange={e => changeData(e.target.checked, index, item, true)}
                                                              disabled={isDisable}></Checkbox>
                                                </Form.Item>
                                            </Col>
                                            <Col span={7}>
                                                {renderItem(obj, item158, item158Origin, index)}
                                            </Col>
                                            <Col span={2}>
                                                <Form.Item name={`${item158.dataKeyAscii}_CB`} valuePropName="checked">
                                                    <Checkbox checked={item158.isSelected}
                                                              onChange={e => changeData(e.target.checked, index, item158, true)}
                                                              disabled={isDisable}></Checkbox>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    })}
                                </div>
                            </Form>
                            {filePdfResponse?.data?.id ?
                                <div className='search-box-button' style={{marginTop: 15}}>
                                    <Button onClick={toggleConfirmNextModal} htmlType='button'>
                                        Bỏ qua
                                    </Button>
                                    <Button type={'primary'}
                                            danger
                                            disabled={!checkActiveSave()}
                                            onClick={toggleClearDataModal} htmlType='button'>
                                        Xóa dữ liệu đã chọn
                                    </Button>
                                    <Button
                                        disabled={!checkActiveSave()}
                                        type='primary'
                                        onClick={onFinish}
                                    >Cập nhật</Button>
                                </div>
                                : <></>
                            }
                        </div>
                    </SplitPane>
                </div>
                <div>
                    <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                        <label style={{ lineHeight: "40px", marginBottom: 2 }} className='search-title'>Lịch sử chỉnh sửa</label>
                        {/* <Pagination
                            style={{ marginTop: 4 }}
                            total={historyResponse?.data?.totalElements || 0}
                            defaultPageSize={pageSizeRef.current}
                            defaultCurrent={1}
                            locale={{ items_per_page: ' dòng' }}
                            pageSizeOptions={[10, 20, 50, 100]}
                            onChange={changePageHistory}
                            showSizeChanger
                            current={page + 1}
                        /> */}
                    </div>
                    <div
                        // style={{
                        //     overflow: "hidden auto",
                        //     height: "24vh"
                        // }}
                        className="detail-data-info"
                    >
                        <Collapse defaultActiveKey={['1']} style={{ width: "100%" }}>
                            {historyResponse?.data?.length ? historyResponse?.data?.map((item, index) => {
                                return <Panel header={displayHeaderHistory(item, index)} key={item.id}>
                                    <div>
                                        <Row gutter={16} style={{ marginTop: 8, marginBottom: 8 }}>
                                            {item?.dataInfoInit?.dataKeyMap?.filter((x: any) => (x.isEdited && historyHaveEdit.indexOf(item.id) !== -1) || historyHaveEdit.indexOf(item.id) === -1)
                                                .map((keyMap: any, index: number) => {
                                                    return <Col span={24} key={keyMap.dataKeyAscii}>
                                                        {convertTypeDataToElementHis(keyMap, index)}
                                                    </Col>
                                                    // return (<Col span={24} key={keyMap.dataKeyAscii}>
                                                    //     <Form.Item
                                                    //         style={{ marginBottom: 10 }}
                                                    //         label={<div>
                                                    //             {keyMap.dataKey || ""}
                                                    //             {keyMap.isEdited ?
                                                    //                 <ExclamationCircleOutlined title="Đã chỉnh sửa" style={{ marginLeft: 5, color: "#faad14" }} /> :
                                                    //                 <CheckCircleOutlined title="Chưa chỉnh sửa" color="success" style={{ marginLeft: 5, color: "#52c41a" }} />
                                                    //             }
                                                    //         </div>}
                                                    //     >
                                                    //         <Input.TextArea disabled value={keyMap.dataValue} />
                                                    //     </Form.Item>
                                                    // </Col>)
                                                })}
                                        </Row>
                                    </div>
                                </Panel>
                            }) : <></>}
                        </Collapse>
                    </div>
                </div>
            </div>
            {/* okText={year === "A4" ? undefined : "Lưu (Nhập THHT khác)"} */}
            {editDataVisible ?
                <ConfirmModal
                    cancelText="Huỷ bỏ"
                    okText="Xác nhận"
                    classBtnOk="ant-button-dangerous"
                    contents={["Bạn có muốn chuyển sang THHT khác không?"]}
                    handleCancel={() => { toggleEditDataModal(); setItemSelected(undefined); }}
                    handleOk={onAcceptEditData}
                    type={1}
                    visible={editDataVisible}
                    widthConfig={500}
                    okBtnDanger={true} />
                : <></>}
            {confirmVisible ?
                <ConfirmModal
                    cancelText="Huỷ bỏ"
                    // okText="Xác nhận"
                    okSuccess="Xác nhận"
                    classBtnOk="ant-button-dangerous"
                    contents={["Bạn muốn cập nhật dữ liệu không?"]}
                    handleCancel={toggleConfirmModal}
                    handleOk={onSubmitSuccess}
                    handleSuccess={onSubmitSuccess}
                    type={1}
                    visible={confirmVisible}
                    widthConfig={500}
                    okBtnDanger={true}/>
                : <></>
            }
            {confirmNextVisible ?
                <ConfirmModal
                    cancelText="Huỷ bỏ"
                    okText="Xác nhận"
                    classBtnOk="ant-button-dangerous"
                    contents={["Bạn chắc chắn muốn chuyển một bản ghi khác?"]}
                    handleCancel={toggleConfirmNextModal}
                    handleOk={onAcceptNextData}
                    type={1}
                    visible={confirmNextVisible}
                    widthConfig={500}
                    okBtnDanger={true}/>
                : <></>
            }
            {clearDataVisible ?
                <ConfirmModal
                    cancelText="Huỷ bỏ"
                    okText="Xác nhận"
                    classBtnOk="ant-button-dangerous"
                    contents={["Bạn có chắc chắn muốn xóa dữ liệu đã chọn?"]}
                    handleCancel={toggleClearDataModal}
                    handleOk={onAcceptClearData}
                    type={1}
                    visible={clearDataVisible}
                    widthConfig={500}
                    okBtnDanger={true}/>
                : <></>
            }
            {listDataInfoInitVisible ?
                <ListDataInfoInitModal
                    visible={listDataInfoInitVisible}
                    toggleModal={toggleListDataInfoInitModal}
                    columns={columns}
                    tableData={tableData}
                />
                : <></>
            }
            {viewPdfVisible ?
                <ViewFilePdf
                    visible={viewPdfVisible}
                    toggleModal={toggleViewPdfModal}
                    filePdf={filePdfResponse?.data}
                    indexCompare={indexCompare}
                />
                : <></>
            }
        </>
    )
}

export default CreateDataInfoLack;
