import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { CommonListQuery, User } from "../../../redux/api/apiTypes";
import { UserParams, UserResponse } from "./apiTypes";

export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL + "/api/v1/users",
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("accessToken");
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getUser: builder.query<
      UserResponse,
      { searchParam?: UserParams } & Partial<CommonListQuery>
    >({
      query: (body) => ({
        url: `/getPage?search=${
          body.searchParam
            ? encodeURIComponent(JSON.stringify(body.searchParam))
            : ""
        }&pageNumber=${body.page || 0}&pageSize=${body.size || 10}`,
        method: "GET",
      }),
      transformResponse: (response: { data: UserResponse }, meta, arg) => {
        return response.data;
      },
    }),

    // chi tiet theo id
    getById: builder.query<User, string>({
      query: (userId) => ({
        url: `/${userId}`,
        method: "GET",
      }),
      transformResponse: (response: { data: User }, meta, arg) => {
        return response.data;
      },
    }),

    // them moi user
    addUser: builder.mutation<User, Partial<User>>({
      query: (body) => ({
        url: `/add`,
        method: "POST",
        body,
      }),
      transformResponse: (response: { data: User }, meta, arg) => {
        return response.data;
      },
    }),

    // xoa
    deleteUser: builder.mutation<any, any>({
      //   query: (userId) => ({
      //     url: `/${userId}`,
      //     method: "DELETE",
      //   }),
      query: (body) => ({
        url: `/delete`,
        method: "POST",
        body: body,
      }),
      transformResponse: (response: { data: User }, meta, arg) => {
        return response.data;
      },
    }),

    // chinh sua
    editUser: builder.mutation<User, User>({
      query: (body) => ({
        url: `/edit`,
        method: "POST",
        body,
      }),
      transformResponse: (response: { data: User }, meta, arg) => {
        return response.data;
      },
    }),
    
    // đổi mẩu khẩu
    changeUserPassword: builder.mutation<User, any>({
      query: (body) => ({
        url: `/change-pass-by-admin/${body.userId}`,
        method: "POST",
        body,
      }),
      transformResponse: (response: { data: User }, meta, arg) => {
        return response.data;
      },
    }),

    
  }),
});

export const {
  useEditUserMutation,
  useLazyGetUserQuery,
  useLazyGetByIdQuery,
  useAddUserMutation,
  useDeleteUserMutation,
  useChangeUserPasswordMutation,
} = userApi;
export default userApi;
