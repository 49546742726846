import { Breadcrumb, Button, Checkbox, Col, Form, Input, InputNumber, Row, Select } from "antd";
import { ReactComponent as Home } from '../../assets/logos/home.svg';
import { useEffect, useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";
import { Commune, District } from "../../redux/api/apiTypes";
import { useLazyGetAllCampaignQuery, useLazyGetCommuneAllQuery, useLazyGetCommuneQuery, useLazyGetDistrictAllQuery, useLazyGetDistrictQuery, useLazyGetProvinceQuery, useLazyGetUserCheckDataQuery } from "../../redux/api/commonApi";
import { TYPE_DATA } from "../../assets/ts/constant";
import { useAppSelector } from "../../redux/Hooks";
import { subMonths, startOfMonth, endOfMonth, startOfYear, subYears, endOfYear } from "date-fns";
import { DatePicker } from "../../custom-components";
import { useLazyGetAllDistrictNoAuthQuery } from "../category/district/redux/districtApi";
import { useLazyGetAllCommuneNoAuthQuery } from "../category/commune/redux/communeApi";
import { useLazyGetAllProvinceNoAuthQuery } from "../category/province/redux/provinceApi";
import { PERMISSION_KEYS } from "../permissions/ts/Contants";
import DataSyncList from "./components/DataSyncList";
const { RangePicker } = DatePicker;

const RegisterSync = () => {
    const [form] = Form.useForm();
    let location = useLocation();
    let { state } = useLocation();
    const navigate = useNavigate();
    const [searchParam, setSearchParam] = useState({});
    const userState = useAppSelector(state => state.user.user);
    const [triggerUserCheck, responseUserCheck] = useLazyGetUserCheckDataQuery();
    const [triggerCampaign, responseCampaign] = useLazyGetAllCampaignQuery();
    const [triggerProvince, responseProvince] = useLazyGetAllProvinceNoAuthQuery();
    const [districtTrigger, responseDistrict] = useLazyGetDistrictQuery();
    const [districtAllTrigger, responseDistrictAll] = useLazyGetAllDistrictNoAuthQuery();
    const [communeAllTrigger, responseCommuneAll] = useLazyGetAllCommuneNoAuthQuery();
    const [communeTrigger, responseCommune] = useLazyGetCommuneQuery();
    const [districts, setDistricts] = useState<District[]>([]);
    const [communes, setCommunes] = useState<Commune[]>([]);
    const [dataCampaignId, setDataCampaignId] = useState();
    const [isCheck, setIsCheck] = useState<boolean>();
    const [districtObject, setDistrictObject] = useState();
    const [communeObject, setCommuneObject] = useState();
    console.log(userState);
    useEffect(() => {
        if (checkPermission("data/info_init")) {
            triggerCampaign({});
        }
        triggerUserCheck({});
        triggerProvince({});
        communeAllTrigger({}).unwrap().then(res => {
            setCommuneObject(res?.reduce((a: any, v: any) => ({ ...a, [v.communeCode]: v.id }), {}));
        });
        districtAllTrigger({}).unwrap().then(res => {
            setDistrictObject(res?.reduce((a: any, v: any) => ({ ...a, [v.districtCode]: v.id }), {}));
        });
    }, [triggerUserCheck, triggerCampaign, triggerProvince, communeAllTrigger, districtAllTrigger]);

    useEffect(() => {
        if (location?.search && communeObject && districtObject && responseProvince) {
            let listSearch = (location?.search || "").split("&") || [];
            let pdfFileName = (listSearch[0] || "").substr(1).replace(".pdf", "").replace(".PDF", "");
            if (!checkPermission("data/info_init") && (userState?.username || "").toLowerCase().includes("check")) {
                setSearchParam({
                    fileName: pdfFileName,
                    createBys: [userState.username],
                    provinceIdStr: responseProvince?.data?.[0]?.id,
                    districtIdStr: districtObject?.[listSearch[1]],
                    communeIdStr: communeObject?.[listSearch[2]],
                });
                form.setFieldsValue({
                    fileName: pdfFileName,
                    createBys: [userState.username],
                    provinceIdStr: responseProvince?.data?.[0]?.id,
                    districtIdStr: districtObject?.[listSearch[1]],
                    communeIdStr: communeObject?.[listSearch[2]],
                });
            } else {
                setSearchParam({
                    fileName: pdfFileName,
                    provinceIdStr: responseProvince?.data?.[0]?.id,
                    districtIdStr: districtObject?.[listSearch[1]],
                    communeIdStr: communeObject?.[listSearch[2]]
                });
                form.setFieldsValue({
                    fileName: pdfFileName,
                    provinceIdStr: responseProvince?.data?.[0]?.id,
                    districtIdStr: districtObject?.[listSearch[1]],
                    communeIdStr: communeObject?.[listSearch[2]]
                })
            }
            if (responseProvince?.data?.[0]?.id) {
                districtTrigger(responseProvince?.data?.[0]?.id).unwrap()
                    .then(res => setDistricts(res));
            }
            if (districtObject?.[listSearch[1]]) {
                communeTrigger(districtObject?.[listSearch[1]]).unwrap()
                    .then(res => setCommunes(res));
            }
        } else if (state && typeof state === "object") {
            let newSearchParam: any = { ...state };
            if (newSearchParam?.searchParam?.provinceIdStr) {
                setCommunes([]);
                setDistricts([]);
                districtTrigger(newSearchParam?.searchParam?.provinceIdStr).unwrap()
                    .then(res => setDistricts(res));
            }
            if (newSearchParam?.searchParam?.districtIdStr) {
                setCommunes([]);
                communeTrigger(newSearchParam?.searchParam?.districtIdStr).unwrap()
                    .then(res => setCommunes(res));
            }
            if (newSearchParam?.searchParam?.dataCampaignId) {
                setDataCampaignId(newSearchParam?.searchParam?.dataCampaignId);
                setIsCheck(newSearchParam?.searchParam?.isCheck || undefined);
            }
            if (!checkPermission("data/info_init") && (userState?.username || "").toLowerCase().includes("check")) {
                setSearchParam({ ...newSearchParam?.searchParam, createBys: newSearchParam?.searchParam?.createBys ? newSearchParam?.searchParam?.createBys : [userState.username] });
                form.setFieldsValue({ ...newSearchParam?.searchParam, createBys: newSearchParam?.searchParam?.createBys ? newSearchParam?.searchParam?.createBys : [userState.username] });
            } else {
                setSearchParam(newSearchParam?.searchParam);
                form.setFieldsValue(newSearchParam?.searchParam)
            }
        } else if (userState) {
            if (!checkPermissionUploadFilePdf && !checkPermission("data/info_init") && (userState?.username || "").toLowerCase().includes("check")) {
                setSearchParam(searchParam ? { ...searchParam, createBys: [userState.username] } : { createBys: [userState.username] });
                form.setFieldsValue(searchParam ? { ...searchParam, createBys: [userState.username] } : { createBys: [userState.username] });
            } else {
                let newSearchParam = { ...searchParam };
                if (!(userState?.username || "").toLowerCase().includes("check") && districtObject && communeObject) {
                    let communeObjectCrr = undefined;
                    districtTrigger(responseProvince?.data?.[0]?.id).unwrap()
                        .then(res => setDistricts(res));
                    if (userState?.username?.split("_")?.[0] && districtObject?.[userState?.username?.split("_")?.[0]]) {
                        communeTrigger(districtObject?.[userState?.username?.split("_")?.[0]])
                            .unwrap()
                            .then(res => {
                                setCommunes(res)
                                communeObjectCrr = res?.reduce((a: any, v: any) => ({ ...a, [v.communeCode]: v.id }), {});
                                newSearchParam = {
                                    ...newSearchParam,
                                    provinceIdStr: responseProvince?.data?.[0]?.id,
                                    districtIdStr: userState?.username?.split("_")?.[0] ? districtObject?.[userState?.username?.split("_")?.[0]] : undefined,
                                    communeIdStr: userState?.username?.split("_")?.[1] ? communeObjectCrr?.[userState?.username?.split("_")?.[1]] : undefined,
                                }
                                setSearchParam(newSearchParam ? newSearchParam : {});
                                form.setFieldsValue(newSearchParam ? newSearchParam : {});
                            });
                    }

                } else {
                    newSearchParam = { ...newSearchParam, provinceIdStr: responseProvince?.data?.[0]?.id }
                    setSearchParam(newSearchParam ? newSearchParam : {});
                    form.setFieldsValue(newSearchParam ? newSearchParam : {});
                }

            }
        }
    }, [state, userState, location, responseProvince, districtObject, communeObject]);


    const checkPermission = (path: string) => {
        if (userState.username === "admin" ||
            (userState?.authorities || []).indexOf("ROLE_SYSTEM_USER") !== -1) return true;
        return false;
    }

    const checkPermissionUploadFilePdf = (path: string) => {
        if (userState.username === "admin" ||
            (userState?.authorities || []).indexOf(PERMISSION_KEYS.KHO_DU_LIEU_NHAP_LIEU.SUA) !== -1) return true;
        return false;
    }

    const onFinish = (values: any) => {
        setSearchParam({ ...values, createBys: checkPermission("data/info_init") || !(userState?.username || "").toLowerCase().includes("check") ? values.createBys : [userState.username] });
        navigate(".", { replace: true, state: { searchParam: { ...values, createBys: checkPermission("data/info_init") || !(userState?.username || "").toLowerCase().includes("check") ? values.createBys : [userState.username] } } });
    }

    const onValuesChanged = (changedValues: any) => {
        if (Object.keys(changedValues)[0] === "provinceIdStr") {
            setCommunes([]);
            setDistricts([]);
            if (changedValues.provinceIdStr) {
                districtTrigger(changedValues.provinceIdStr).unwrap()
                    .then(res => setDistricts(res));
            }
            form.setFieldsValue({
                districtIdStr: undefined,
                communeIdStr: undefined,
            })

        } else if (Object.keys(changedValues)[0] === "districtIdStr") {
            setCommunes([]);
            if (changedValues.districtIdStr) {
                communeTrigger(changedValues.districtIdStr).unwrap()
                    .then(res => setCommunes(res));
            }
            form.setFieldsValue({
                communeIdStr: undefined
            })
        } else if (Object.keys(changedValues)[0] === "dataCampaignId") {
            setDataCampaignId(changedValues.dataCampaignId);
            form.setFieldsValue({
                updateDates: undefined,
                isCheck: undefined
            })
            setIsCheck(undefined);
        } else if (Object.keys(changedValues)[0] === "isCheck") {
            form.setFieldsValue({
                updateDates: undefined
            })
            setIsCheck(changedValues.isCheck === 1);
        }
    }

    const resetFormData = () => {
        form.resetFields();
        setDataCampaignId(undefined);
        setIsCheck(undefined);
    }

    return (
        <>

            <div className='title-label' style={{ marginBottom: 20 }}>Danh sách đồng bộ</div>

            <div className='content-box' style={{ marginBottom: 30 }}>
                <Form
                    name="searchBox"
                    onFinish={onFinish}
                    autoComplete="off"
                    layout="vertical"
                    form={form}
                    labelWrap
                    labelAlign="left"
                    colon={false}
                    onValuesChange={onValuesChanged}
                >
                    <label className='search-title'>Lọc chung</label>
                    <Row gutter={16} style={{ marginTop: 8, marginBottom: 8 }}>
                        <Col span={6}>
                            <Form.Item
                                style={{ marginBottom: 10 }}
                                label="ID"
                                name="dataInfoId"
                            >
                                <Input placeholder="Nhập ID" style={{ minWidth: '100%' }} />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                style={{ marginBottom: 10 }}
                                label="Tên file excel"
                                name="excelName"
                            >
                                <Input placeholder="Nhập tên file" style={{ minWidth: '100%' }} />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                style={{ marginBottom: 10 }}
                                label="Ngày đăng ký"
                                name="ngayDangKyTu"
                            >
                                <Input placeholder="Nhập ngày đăng ký" style={{ minWidth: '100%' }} />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                style={{ marginBottom: 10 }}
                                label="Số"
                                name="so"
                            >
                                <Input placeholder="Nhập số" style={{ minWidth: '100%' }} />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                style={{ marginBottom: 10 }}
                                label="Người chỉnh sửa"
                                name="modifiedBy"
                            >
                                <Input placeholder="Nhập người chỉnh sửa" style={{ minWidth: '100%' }} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <div className='search-box-button'>
                        <Button htmlType='button' onClick={() => resetFormData()}>
                            Xóa điều kiện
                        </Button>
                        <Button htmlType='submit' type='primary'>Tìm kiếm</Button>
                    </div>
                </Form>
            </div>
            <DataSyncList stateOld={state && typeof state === "object" ? state : {}} form={form} searchParam={searchParam} triggerCampaign={() => triggerCampaign({})} />
        </>
    )
}

export default RegisterSync;
