import { useEffect, useState } from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Typography, Row, DatePicker } from 'antd';
import moment from 'moment';
import { startOfYear, getYear, format, endOfYear } from 'date-fns'

const { Title } = Typography;

type ChartData = {
    name?: string,
    unsuccessful: number,
    successful: number,
    paused: number,
}

const data = [
    {
        name: 'Tháng 1',
        successful: 4000,
        unsuccessful: 2400,
    },
    {
        name: 'Tháng 2',
        successful: 3000,
        unsuccessful: 1398,
    },
    {
        name: 'Tháng 3',
        successful: 100,
        unsuccessful: 9800,
    },
    {
        name: 'Tháng 4',
        successful: 2780,
        unsuccessful: 3908,
    },
    {
        name: 'Tháng 5',
        successful: 1890,
        unsuccessful: 4800,
    },
    {
        name: 'Tháng 6',
        successful: 2390,
        unsuccessful: 3800,
    },
    {
        name: 'Tháng 7',
        successful: 3490,
        unsuccessful: 4300,
    },
    {
        name: 'Tháng 8',
        successful: 3490,
        unsuccessful: 4300,
    },
    {
        name: 'Tháng 9',
        successful: 3490,
        unsuccessful: 4300,
    },
    {
        name: 'Tháng 10',
        successful: 3490,
        unsuccessful: 4300,
    },
    {
        name: 'Tháng 11',
        successful: 3490,
        unsuccessful: 4300,
    },
    {
        name: 'Tháng 12',
        successful: 3490,
        unsuccessful: 4300,
    },

];

const AuctionResult = () => {
    const [selectedYear, setSelectedYear] = useState(getYear(new Date()))
    const onYearChange = (values: moment.Moment | null) => {
        if (values) setSelectedYear(getYear(values.toDate()))
    }

    return (
        <div className='home-card' style={{ width: '100%' }}>
            <Title level={2} className='text-center'>Thống kê dữ liệu đăng tải theo từng tháng</Title>
            <Row align='middle' justify='end' style={{ marginBottom: 20 }}>
                <span className='home-chart-label'>Năm:</span>
                <DatePicker
                    style={{ width: 80 }}
                    defaultValue={moment()}
                    picker='year'
                    onChange={(value) => onYearChange(value)}
                />
            </Row>
            <Row align='middle' justify='center'>
                <ResponsiveContainer width="100%" height={300}>
                    <BarChart
                        width={1000}
                        height={300}
                        data={data}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                        }}
                    >
                        <Legend verticalAlign='top' />
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Bar dataKey="successful" fill="#54a4ea" name="Số lượng dữ liệu tải lên" />
                        {/* <Bar dataKey="unsuccessful" fill="#f47f56" name="Số lượng file chưa check" /> */}
                        {/* <Bar dataKey="paused" fill="#a5a5a5" name="Số lượng file chưa check" /> */}
                    </BarChart>
                </ResponsiveContainer>
            </Row>
        </div>
    )
}

export default AuctionResult