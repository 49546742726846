import AppHeader from './header/Header';
import Sidebar from './sidebar/Sidebar';
import {Breadcrumb, Dropdown, Layout, Menu, Row, Typography} from 'antd';
import {Link, Outlet, useLocation, useNavigate} from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';
import { ReactComponent as Home } from '../assets/logos/home.svg';
import {DownOutlined} from "@ant-design/icons";
import {logout, setUser} from "../modules/login/redux/UserSlice";
import {useAppDispatch, useAppSelector} from "../redux/Hooks";
const { Text } = Typography;
const { Content } = Layout;

const AppLayout = () => {
    const ref = useRef<any>();
    const [title, setTitle] = useState<string>();
    const user = useAppSelector((state) => state.user)
    const [headerHeight, setHeaderHeight] = useState(0);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    useEffect(() => {
        selectedKeys();
        if(ref?.current) {
            setHeaderHeight(ref.current.clientHeight);
        }
    }, [location]);

    const logOut = () => {
        dispatch(logout());
        dispatch(setUser({}));
        navigate("/login", { replace: true, state: { from: location } })
    }
    const selectedKeys = () => {
        let activeKey = '';
        if (location?.pathname?.includes("data/list")) activeKey = "Kho dữ liệu";
        if (location?.pathname?.includes("data/import")) activeKey = "data";
        if (location?.pathname?.includes("data/detail")) activeKey = "data";
        if (location?.pathname?.includes("data/campaign")) activeKey = "Danh sách chiến dịch";
        if (location?.pathname?.includes("data/data-entry-campaign")) activeKey = "Chiến dịch nhập liệu";
        if (location?.pathname?.includes("data/check")) activeKey = "data/check";
        // if (location?.pathname?.includes("report/perform")) activeKey = "perform";
        // if (location?.pathname?.includes("report/field")) activeKey = "field";
        // if (location?.pathname?.includes("report/empl")) activeKey = "empl";

        if (location?.pathname?.includes("report-data-info-init/perform")) activeKey = "Báo cáo hiệu năng chỉnh sửa dữ liệu";
        if (location?.pathname?.includes("report-data-info-init/field")) activeKey = "Báo cáo theo trường dữ liệu";
        if (location?.pathname?.includes("report-data-info-init/empl")) activeKey = "Báo cáo thời gian làm việc";
        if (location?.pathname?.includes("report-data-info-init/import")) activeKey = "Báo cáo nhập liệu";
        if (location?.pathname?.includes("report-data-info-init/checked")) activeKey = "Báo cáo kiểm tra";

        if (location?.pathname?.includes("info_init/create")) activeKey = " Nhập mới dữ liệu";
        if (location?.pathname?.includes("info_init/createv2")) activeKey = "Nhập liệu có gợi ý";
        if (location?.pathname?.includes("info_init/createv3")) activeKey = "Nhập liệu dữ resize";
        if (location?.pathname?.includes("info_init/createLack")) activeKey = "Nhập liệu bổ sung";
        if (location?.pathname?.includes("data/info_init")) activeKey = "Kho dữ liệu nhập liệu";
        if (location?.pathname?.includes("data/field")) activeKey = "Cấu hình trường thông tin";
        if (location?.pathname?.includes("data/file")) activeKey = "Danh sách file scan";
        if (location?.pathname?.includes("data/check-data")) activeKey = "Kiểm tra dữ liệu";
        if (location?.pathname?.includes("data/info_init_history")) activeKey = "Kho dữ liệu kiểm tra";
        if (location?.pathname?.includes("data/user-list")) activeKey = "data/user-list";
        if (location?.pathname?.includes("category/province")) activeKey = "Danh mục Tỉnh thành";
        if (location?.pathname?.includes("category/district")) activeKey = "Danh mục Quận huyện";
        if (location?.pathname?.includes("category/commune")) activeKey = "Danh mục Phường xã";
        if (location?.pathname?.includes("data/box-data")) activeKey = "Kho dữ liệu PX";
        if (location?.pathname?.includes("data/sync-data")) activeKey = "Đồng bộ dữ liệu";

        if (location?.pathname?.includes("setting/user")) activeKey = "Quản lý người dùng";
        if (location?.pathname?.includes("setting/role")) activeKey = "Phân quyền";

        if (location?.pathname?.includes("data/list-sync-data")) activeKey = "Danh sách đồng bộ";
        setTitle(activeKey);
    }
    const menu = (
        <Menu>
            <Menu.Item key='detail'>
                <Link to='/account/detail'>
                    <span>Thông tin tài khoản</span>
                </Link>
            </Menu.Item>
            <Menu.Item key='change_pw'>
                <Link to='/account/change-password'>
                    <span>Đổi mật khẩu</span>
                </Link>
            </Menu.Item>
            <Menu.Item onClick={() => logOut()} key='logout'>
                <span>Đăng xuất</span>
            </Menu.Item>
        </Menu>
    )

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Layout>
                <Sidebar headerHeight={headerHeight} />
                <Layout>
                    {/*<div ref={ref}>*/}
                    {/*    <AppHeader />*/}
                    {/*</div>*/}
                    <Row  align='middle' justify='space-between' style={{paddingRight:20}}>
                        <Breadcrumb separator="" style={{ marginBottom: 10, marginLeft: 20, marginTop: 10 }}>
                            <Breadcrumb.Item href="/home">
                                <Home style={{ width: 14, height: 14, marginRight: 20 }} /> Trang chủ
                            </Breadcrumb.Item>
                            {title&&<Breadcrumb.Separator> {'>'} </Breadcrumb.Separator>}
                            {title&&<Breadcrumb.Item href="">
                                <span>{title}</span>
                            </Breadcrumb.Item>}
                        </Breadcrumb>
                        <Dropdown overlay={menu} trigger={['click']}>
                            <Text className='font-size-16 cursor-pointer'>
                                {user.user.fullName || user.user.username} <DownOutlined />
                            </Text>
                        </Dropdown>
                    </Row>

                    <Content className='layout-content' style={{ height: `calc(100vh - ${headerHeight}px)`, overflow: "auto", paddingBottom: '100px', paddingTop: 20 }}><Outlet /></Content>
                </Layout>
            </Layout>
        </Layout>
    )
}

export default AppLayout;
