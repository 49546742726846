import { Routes, Route, Navigate } from 'react-router-dom';
import Login from '../modules/login/Index';
import LayoutApp from '../layouts/Layout';
import Home from '../modules/home/Index';
import { useAppSelector } from '../redux/Hooks';
import PrivateRoute from './PrivateRoute';
import ChangePassword from '../modules/account/change-password/Index';
import DataList from '../modules/data/index';
import PerformReport from '../modules/dashboard/perform-report/Index';
import Campaign from '../modules/campaign/Index';
import DetailDataInfo from '../modules/data/components/DetailDataInfo';
import ImportData from '../modules/data/components/ImportData';
import CreateDataInfoV2 from '../modules/data_init/components/CreateDataInfoVer2';
import CreateDataInfoV3 from '../modules/data_init/components/CreateDataInfoResize';
import CreateDataInfo from '../modules/data_init/components/CreateDataInfo';
import CreateDataInfoLack from '../modules/data_init/components/CreateDataInfoLack';
import CheckDataInfo from '../modules/data/components/CheckDataInfo';
import CheckData from '../modules/check-data/components/CheckDataInfo';
import FieldReport from '../modules/dashboard/field-report/Index';
import EmplReport from '../modules/dashboard/employee-report/Index';
// import CheckedReport from '../modules/info-init-report/checked-report';
import FilePdf from '../modules/file-pdf/Index';
import DataInit from '../modules/data_init/index';
import FieldConfig from '../modules/field_config/Index';
import DetailDataInfoInit from '../modules/data_init/components/DetailDataInfoInit';
import DetailDataInfoInitLack from '../modules/data_init/components/DetailDataInfoInitLack';
import InfoInitImportReport from '../modules/info-init-report/import-report/Index';
import InfoInitPerformReport from '../modules/info-init-report/perform-report/Index';
import InfoInitCheckedReport from '../modules/info-init-report/checked-report/Index';
import DetailFilePdf from '../modules/file-pdf/components/DetailFilePdf';
import DataInitHistory from '../modules/data_init_history/index';
import Province from '../modules/category/province/Index';
import Commune from '../modules/category/commune/Index';
import District from '../modules/category/district/Index';
import DetailDataInfoInitHistory from '../modules/data_init_history/components/DetailDataInfoInitHistory';
import User from '../modules/user/Index';
import Permissions from '../modules/permissions/Index';
import BoxData from '../modules/box-Data/Index';
import DataEntryCampaign from '../modules/data_entry_campaign/Index';
import CreateUser from '../modules/user/components/CreateUser';
import DataSync from '../modules/data-sync/components';
import AddRole from '../modules/permissions/components/AddRole';
import { PERMISSION_KEYS } from '../modules/permissions/ts/Contants';
import { checkPermission } from '../modules/common/assets/CommonFunctions';
import { useEffect } from 'react';
import RegisterSync from '../modules/data-sync-list';

const IndexRoutes = () => {
    // const user = useAppSelector((state) => state.user);
    // const checkPermission = (path: string) => {
    //     if (user.user?.username !== "admin" || (user?.user?.authorities || []).indexOf("ROLE_SYSTEM_DATAINFO") !== -1) return true;
    //     return false;
    // }

    // const checkRoleCheck = () => {
    //     if (user.user?.username === "admin" || (user.user?.username || "").toLowerCase().includes("check")) return true;
    //     return false;
    // }

    return (
        <>
            <Routes>
                <Route path='/login' element={<Login />} />
                <Route path="/" element={<PrivateRoute><LayoutApp /></PrivateRoute>}>
                    <Route path='' element={<PrivateRoute><Home /></PrivateRoute>} />
                    {/* account */}
                    {/* <Route path='account/detail' element={<PrivateRoute><AccountDetail /></PrivateRoute>} /> */}
                    <Route path='account/change-password' element={<PrivateRoute><ChangePassword /></PrivateRoute>} />

                    {/* Chiến dịch */}
                    {/* <Route path='data/campaign' element={<PrivateRoute hasPermissions={checkPermission("data/campaign") && user.user.username === "admin"}><Campaign /></PrivateRoute>} /> */}
                    <Route path='data/campaign' element={<PrivateRoute hasPermissions={checkPermission(PERMISSION_KEYS.CHIEN_DICH.DANH_SACH)}><Campaign /></PrivateRoute>} />

                    {/* Chiến dịch */}
                    {/* <Route path='data/data-entry-campaign' element={<PrivateRoute hasPermissions={checkPermission("data/data-entry-campaign") && user.user.username === "admin"}><DataEntryCampaign /></PrivateRoute>} /> */}
                    <Route path='data/data-entry-campaign' element={<PrivateRoute hasPermissions={checkPermission(PERMISSION_KEYS.CHIEN_DICH_NHAP_LIEU.DANH_SACH)}><DataEntryCampaign /></PrivateRoute>} />

                    {/* Cấu hình trường thông tin */}
                    <Route path='data/field' element={<PrivateRoute hasPermissions={checkPermission(PERMISSION_KEYS.THAM_SO_HE_THONG.DANH_SACH)}><FieldConfig /></PrivateRoute>} />

                    {/* Danh sách file pdf */}
                    <Route path='data/file' element={<PrivateRoute hasPermissions={checkPermission(PERMISSION_KEYS.DANH_SACH_FILE_PDF.DANH_SACH)}><FilePdf /></PrivateRoute>} />
                    <Route path='data/file/detail/:id' element={<PrivateRoute hasPermissions={checkPermission(PERMISSION_KEYS.DANH_SACH_FILE_PDF.DANH_SACH)}><DetailFilePdf /></PrivateRoute>} />

                    {/* Data */}
                    <Route path='data/list' element={<PrivateRoute hasPermissions={checkPermission("data/list")}><DataList /></PrivateRoute>} />
                    <Route path='data/detail/:id' element={<PrivateRoute hasPermissions={checkPermission("data/detail")}><DetailDataInfo /></PrivateRoute>} />
                    <Route path='data/check' element={<PrivateRoute><CheckDataInfo /></PrivateRoute>} />
                    {/* kiem tra du lieu */}
                    <Route path='data/check-data' element={<PrivateRoute hasPermissions={checkPermission(PERMISSION_KEYS.KIEM_TRA_DU_LIEU.DANH_SACH)}><CheckData /></PrivateRoute>} />
                    <Route path='data/import' element={<PrivateRoute><ImportData /></PrivateRoute>} />
                    {/* Đồng bộ dữ liệu */}
                    <Route path='data/sync-data/:id' element={<PrivateRoute  hasPermissions={checkPermission(PERMISSION_KEYS.DONG_BO_DU_LIEU.ADD)}><DataSync /></PrivateRoute>} />
                    <Route path='data/sync-data' element={<PrivateRoute  hasPermissions={checkPermission(PERMISSION_KEYS.DONG_BO_DU_LIEU.ADD)}><DataSync /></PrivateRoute>} />

                    {/* Nhập liệu dữ liệu */}
                    <Route path='info_init/createLack' element={<PrivateRoute hasPermissions={checkPermission(PERMISSION_KEYS.NHAP_LIEU_DU_LIEU.DANH_SACH)}><CreateDataInfoLack /></PrivateRoute>} />
                    <Route path='info_init/create' element={<PrivateRoute hasPermissions={checkPermission(PERMISSION_KEYS.NHAP_LIEU_DU_LIEU.DANH_SACH)}><CreateDataInfo /></PrivateRoute>} />
                    <Route path='info_init/createv2' element={<PrivateRoute hasPermissions={checkPermission(PERMISSION_KEYS.NHAP_LIEU_DU_LIEU.DANH_SACH)}><CreateDataInfoV2 /></PrivateRoute>} />
                    <Route path='info_init/createv3' element={<PrivateRoute hasPermissions={checkPermission(PERMISSION_KEYS.NHAP_LIEU_DU_LIEU.DANH_SACH)}><CreateDataInfoV3 /></PrivateRoute>} />

                    {/* Kho du lieu nhap lie */}
                    <Route path='data/info_init' element={<PrivateRoute hasPermissions={checkPermission(PERMISSION_KEYS.KHO_DU_LIEU_NHAP_LIEU.DANH_SACH)}><DataInit /></PrivateRoute>} />
                    <Route path='data/info_init_detail/:id' element={<PrivateRoute hasPermissions={checkPermission(PERMISSION_KEYS.KHO_DU_LIEU_NHAP_LIEU.DANH_SACH)}><DetailDataInfoInit /></PrivateRoute>} />
                    <Route path='data/lack/info_init_detail/:id' element={<PrivateRoute hasPermissions={checkPermission(PERMISSION_KEYS.KHO_DU_LIEU_NHAP_LIEU.DANH_SACH)}><DetailDataInfoInitLack /></PrivateRoute>} />

                    {/* Lịch sử dữ liệu nhập liệu / kho du lieu kiem tra */}
                    <Route path='data/info_init_history' element={<PrivateRoute hasPermissions={checkPermission(PERMISSION_KEYS.KHO_DU_LIEU_KIEM_TRA.DANH_SACH)}><DataInitHistory /></PrivateRoute>} />
                    <Route path='data/info_init_history_detail/:id' element={<PrivateRoute hasPermissions={checkPermission(PERMISSION_KEYS.KHO_DU_LIEU_KIEM_TRA.DANH_SACH)}><DetailDataInfoInitHistory /></PrivateRoute>} />


                    {/* Báo cáo dữ liệu import */}
                    <Route path='report/perform' element={<PrivateRoute hasPermissions={checkPermission("report/perform")}><PerformReport /></PrivateRoute>} />
                    <Route path='report/field' element={<PrivateRoute hasPermissions={checkPermission("report/field")}><FieldReport /></PrivateRoute>} />
                    <Route path='/report/empl' element={<PrivateRoute hasPermissions={checkPermission("/report/empl")}><EmplReport /></PrivateRoute>} />

                    {/* Báo cáo dữ liệu nhập liệu */}
                    <Route path='report-data-info-init/import' element={<PrivateRoute hasPermissions={checkPermission(PERMISSION_KEYS.BAO_CAO.BAO_CAO_NHAP_LIEU)}><InfoInitImportReport /></PrivateRoute>} />
                    <Route path='report-data-info-init/perform' element={<PrivateRoute hasPermissions={checkPermission(PERMISSION_KEYS.BAO_CAO.BAO_CAO_HIEU_NANG_CHINH_SUA)}><InfoInitPerformReport /></PrivateRoute>} />
                    <Route path='report-data-info-init/checked' element={<PrivateRoute hasPermissions={checkPermission(PERMISSION_KEYS.BAO_CAO.BAO_CAO_KIEM_TRA)}><InfoInitCheckedReport /></PrivateRoute>} />
                    {/* <Route path='report-data-info-init/field' element={<PrivateRoute hasPermissions={checkPermission("report-data-info-init/create")}><PerformReport /></PrivateRoute>} />
                    <Route path='report-data-info-init/empl' element={<PrivateRoute hasPermissions={checkPermission("report-data-info-init/create")}><PerformReport /></PrivateRoute>} /> */}


                    {/* Quản lý danh mục */}
                    <Route path='category/province' element={<PrivateRoute hasPermissions={checkPermission(PERMISSION_KEYS.DANH_MUC.TINH_THANH.DANH_SACH)}><Province /></PrivateRoute>} />
                    <Route path='category/district' element={<PrivateRoute hasPermissions={checkPermission(PERMISSION_KEYS.DANH_MUC.QUAN_HUYEN.DANH_SACH)}><District /></PrivateRoute>} />
                    <Route path='category/commune' element={<PrivateRoute hasPermissions={checkPermission(PERMISSION_KEYS.DANH_MUC.PHUONG_XA.DANH_SACH)}><Commune /></PrivateRoute>} />


                    {/* Quản trị hệ thống */}
                    <Route path='setting/user' element={<PrivateRoute hasPermissions={checkPermission(PERMISSION_KEYS.QUAN_TRI_HE_THONG.NGUOI_DUNG.DANH_SACH)}><User /></PrivateRoute>} />
                    <Route path='setting/user/add' element={<PrivateRoute hasPermissions={checkPermission(PERMISSION_KEYS.QUAN_TRI_HE_THONG.NGUOI_DUNG.THEM_MOI)}><CreateUser /></PrivateRoute>} />
                    <Route path='setting/user/detail/:id' element={<PrivateRoute hasPermissions={checkPermission(PERMISSION_KEYS.QUAN_TRI_HE_THONG.NGUOI_DUNG.DANH_SACH)}><CreateUser /></PrivateRoute>} />

                    <Route path='/setting/role' element={<PrivateRoute hasPermissions={checkPermission(PERMISSION_KEYS.QUAN_TRI_HE_THONG.NHOM_QUYEN.DANH_SACH)}><Permissions /></PrivateRoute>} />
                    {/* <Route path='/setting/role/add' element={<PrivateRoute ><AddRole /></PrivateRoute>} /> */}

                    {/* Kho dữ liệu */}
                    <Route path='data/box-data' element={<PrivateRoute hasPermissions={checkPermission(PERMISSION_KEYS.KHO_DU_LIEU.DANH_SACH)}><BoxData /></PrivateRoute>} />
                    <Route path='data/box-data/detail/:id' element={<PrivateRoute hasPermissions={checkPermission(PERMISSION_KEYS.KHO_DU_LIEU.DANH_SACH)}><DetailFilePdf /></PrivateRoute>} />

                    {/*Danh sách đồng bộ dữ liệu*/}
                    <Route path='data/list-sync-data' element={<PrivateRoute hasPermissions={checkPermission(PERMISSION_KEYS.DONG_BO_DU_LIEU.DANH_SACH)}><RegisterSync /></PrivateRoute>} />

                    <Route path='*' element={<Navigate to="/" />} />
                </Route>
            </Routes>
        </>
    );
}

export default IndexRoutes;
