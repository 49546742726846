import { Typography, Col, Row, Select, DatePicker, Form } from 'antd';
import { useEffect, useState } from 'react';
import { Cell, Label, Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';
import { AuctionStatus } from '../../common/assets/Constants'

import moment from 'moment';
import { getYear, getMonth } from 'date-fns';

const { Option } = Select;
const { Title } = Typography;

type ChartData = {
    name: string,
    value: number,
    color: string,
    id: number,
}

const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
var data: ChartData[] = [{
    name: "CC",
    value: 100,
    color: "#54a4ea",
    id: 1
}, {
    name: "KH",
    value: 80,
    color: "#fce78a",
    id: 2
}, {
    name: "KS",
    value: 120,
    color: "#a5a5a5",
    id: 3
}, {
    name: "KT",
    value: 50,
    color: "#f47f56",
    id: 4
}]

const HomeChart = () => {
    const [totalValue, setTotalValue] = useState(0);
    const [form] = Form.useForm()
    const [selectedMonth, setSelectedMonth] = useState(getMonth(new Date()) + 1 + '')
    const [selectedYear, setSelectedYear] = useState(getYear(new Date()) + '')

    const onMonthChange = (values: string) => {
        if (values) {
            setSelectedMonth(values)
        }
    }

    const onYearChange = (values: moment.Moment | null) => {
        if (values) {
            let newYear = getYear(values.toDate()) + ''
            setSelectedYear(newYear)
        }
    }

    return (
        <div className='home-card'>
            <Title level={2} className='text-center'>Thống kê tình hình kiểm tra dữ liệu theo tháng</Title>
            <Form
                form={form}
            >
                <Row style={{ width: '100%' }}>
                    <Col span={12} style={{ display: 'flex', justifyContent: 'right', paddingRight: 10 }}>
                        <Form.Item name="year">
                            <span className='home-chart-label'>Năm:</span>
                            <DatePicker
                                style={{ width: 80 }}
                                defaultValue={moment()}
                                picker='year' onChange={(value) => onYearChange(value)}
                                allowClear={false}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12} style={{ display: 'flex', justifyContent: 'left', paddingLeft: 10 }}>

                        <Form.Item name="month">
                            <span className='home-chart-label'>Tháng:</span>
                            <Select
                                style={{ width: 80 }}
                                defaultValue={selectedMonth}
                                onChange={(e) => onMonthChange(e)}
                            >
                                {months.map(item => {
                                    return <Option key={item}>{item}</Option>
                                })}
                            </Select>
                        </Form.Item>
                    </Col>

                </Row>
            </Form>
            <Row align='middle' justify='center'>
                <ResponsiveContainer width='100%' height={300}>
                    <PieChart>
                        <Legend
                            verticalAlign='top'
                            align='center'
                            payload={[{ value: "CC", type: 'rect', color: '#54a4ea' },
                            { value: "KH", type: 'rect', color: '#fce78a' },
                            { value: "KS", type: 'rect', color: "#a5a5a5" },
                            { value: "KT", type: 'rect', color: "#f47f56" },
                            ]}
                        />
                        <Pie
                            dataKey='value'
                            data={data}
                            outerRadius={80}
                            innerRadius={60}>
                            {data.map((item, index) => {
                                return <Cell key={`cell-${index}`} fill={data[index].color} />
                            })}
                            <Label value='Tổng số' position='center' className='chart-label-upper' />
                            <Label value={totalValue} position='center' className='chart-label-lower' />
                        </Pie>
                        <Tooltip />
                    </PieChart>
                </ResponsiveContainer>
                {/* </div> */}
            </Row>
        </div >
    )
}

export default HomeChart;