import { Row, Table, Pagination, FormInstance, Col, Spin } from "antd";
import { ColumnsType } from "antd/es/table";
import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useLazyGetCountDataInfoInitQuery, useLazyGetHistoryQuery } from "../redux/dataInitHistoryApi";
import moment from "moment";
import { DEFAULT_FIELD_CONFIG, TYPE_DATA } from "../../../assets/ts/constant";
import { useLazyGetCommuneAllQuery, useLazyGetDistrictAllQuery, useLazyGetProvinceQuery } from "../../../redux/api/commonApi";
import { FieldConfig } from "../redux/apiTypes";
import { useAppSelector } from "../../../redux/Hooks";
import { useLazyGetAllProvinceNoAuthQuery } from "../../category/province/redux/provinceApi";
import { useLazyGetAllCommuneNoAuthQuery } from "../../category/commune/redux/communeApi";
import { useLazyGetAllDistrictNoAuthQuery } from "../../category/district/redux/districtApi";
interface DataListTable {
    key: string,
    index: number,
    createBy?: string,
    type?: string,
    createdDate?: string,
    fileName?: string,
    fileName158?: string,
    address?: string,
}

type Props = {
    form: FormInstance<any>,
    searchParam: any,
    stateOld: any,
}

const RegisterInfoList = ({ form, searchParam, stateOld }: Props) => {
    const pageSizeRef = useRef(10);
    const navigate = useNavigate();
    const [page, setPage] = useState(1);
    const [provinceObj, setProvinceObj] = useState();
    const [districtObj, setDistrictObj] = useState();
    const [communeObj, setCommuneObj] = useState();
    const userState = useAppSelector(state => state.user.user);
    const [tableData, setTableData] = useState<DataListTable[]>()
    const [triggerProvince, responseProvince] = useLazyGetAllProvinceNoAuthQuery();
    const [communeTrigger, responseCommune] = useLazyGetAllCommuneNoAuthQuery();
    const [districtTrigger, responseDistrict] = useLazyGetAllDistrictNoAuthQuery();
    const [triggerDataInfoInitHistory, responseDataInfoInitHistory] = useLazyGetHistoryQuery();
    const [triggerCountDataInfoInit, responseCountDataInfoInit] = useLazyGetCountDataInfoInitQuery();

    useEffect(() => {
        triggerProvince({});
        districtTrigger({});
        communeTrigger({});
    }, [triggerProvince, districtTrigger, communeTrigger]);

    useEffect(() => {
        getData(false);
    }, [searchParam]);

    useEffect(() => {
        if (responseProvince) {
            setProvinceObj(responseProvince?.data?.reduce((a: any, v: any) => ({ ...a, [v.id]: v.provinceName }), {}))
        }
        if (responseDistrict) {
            setDistrictObj(responseDistrict?.data?.reduce((a: any, v: any) => ({ ...a, [v.id]: v.districtName }), {}))
        }
        if (responseCommune) {
            setCommuneObj(responseCommune?.data?.reduce((a: any, v: any) => ({ ...a, [v.id]: v.communeName }), {}))
        }
    }, [responseProvince, responseDistrict, responseCommune]);

    useEffect(() => {
        if (responseDataInfoInitHistory?.data?.content?.length) {
            let newTableData: DataListTable[] = [];
            responseDataInfoInitHistory.data.content.forEach((item: any, index: number) => {
                newTableData.push({
                    key: item.id,
                    index: index + 1,
                    fileName: item?.filePdf?.fileName || "",
                    createdDate: item.createdDate ? moment(item.createdDate).format("HH:mm DD/MM/YYYY") : "",
                    createBy: item.createBy || "",
                    type: item?.dataInfoInit?.filePdf?.typeId || "",
                    fileName158: item?.dataInfoInit?.dataKeyMap && item?.dataInfoInit?.typeId ? customFileFileName158(item?.dataInfoInit, item?.dataInfoInit?.typeId) : '',
                    address: [item.province?.provinceName || "", item.district?.districtName || "", item.commune?.districtName || ""].filter(item => item).join(", "),
                })
            })
            setTableData(newTableData);
        } else {
            setTableData([]);
        }
    }, [responseDataInfoInitHistory]);

    const columns: ColumnsType<DataListTable> = [
        {
            title: "STT",
            dataIndex: "index",
            key: "index",
            align: "center",
            render: (value, record, index) => (page - 1) * pageSizeRef.current + index + 1,
            width: 70
        },
        {
            title: "File pdf",
            dataIndex: "fileName",
            key: "fileName",
            ellipsis: true,
            width: 200,
        },
        {
            title: "File pdf 158",
            dataIndex: "fileName158",
            key: "fileName158",
            ellipsis: true,
            width: 200,
        },
        {
            title: "Địa chỉ",
            dataIndex: "address",
            key: "address",
        },
        {
            title: "Người tạo",
            dataIndex: "createBy",
            key: "createBy",
        },
        {
            title: "Ngày kiểm tra",
            dataIndex: "createdDate",
            key: "createdDate",
        },
    ];

    const getData = (getCurrentPage: boolean) => {
        triggerDataInfoInitHistory({
            searchParam: searchParam ? {
                ...searchParam,
                createDates: undefined,
                types: searchParam?.types?.length ? searchParam.types : undefined,
                isCheck: searchParam.isCheck ? (searchParam.isCheck === 1 ? true : false) : undefined,
                fromCreateDate: searchParam?.createDates && searchParam.createDates[0] ? moment(searchParam.createDates[0]).format("YYYY-MM-DD") : undefined,
                toCreateDate: searchParam?.createDates && searchParam.createDates[1] ? moment(searchParam.createDates[1]).format("YYYY-MM-DD") : undefined,
            } : "",
            page: stateOld?.page ? stateOld.page - 1 : (getCurrentPage ? page - 1 : 0),
            size: stateOld?.size ? stateOld.size : pageSizeRef.current
        });
        triggerCountDataInfoInit({
            searchParam: searchParam ? {
                ...searchParam,
                createDates: undefined,
                types: searchParam?.types?.length ? searchParam.types : undefined,
                isCheck: searchParam.isCheck ? (searchParam.isCheck === 1 ? true : false) : undefined,
                fromCreateDate: searchParam?.createDates && searchParam.createDates[0] ? moment(searchParam.createDates[0]).format("YYYY-MM-DD") : undefined,
                toCreateDate: searchParam?.createDates && searchParam.createDates[1] ? moment(searchParam.createDates[1]).format("YYYY-MM-DD") : undefined,
            } : "",
            page: stateOld?.page ? stateOld.page - 1 : (getCurrentPage ? page - 1 : 0),
            size: stateOld?.size ? stateOld.size : pageSizeRef.current
        })

        if (stateOld?.page || stateOld?.size) {
            if (stateOld?.page) setPage(stateOld?.page);
            if (stateOld?.size) pageSizeRef.current = stateOld?.size;
        } else if (!getCurrentPage) {
            setPage(1);
        }
    }

    const customFileFileName158 = (dataInfoInit: any, type: string) => {
        return dataInfoInit?.dataKeyMap?.reduce((a: any, v: any) => ({ ...a, [v.dataKeyAscii]: v.dataValue }), {})?.[DEFAULT_FIELD_CONFIG[type]?.tenfilepdf];
    }

    const customContent = (dataInfoInit: any, type: string) => {
        let address = dataInfoInit?.dataKeyMap?.filter((item: any) => {
            if ((item.dataKeyAscii === DEFAULT_FIELD_CONFIG[type]?.tinhThanh ||
                item.dataKeyAscii === DEFAULT_FIELD_CONFIG[type]?.quanHuyen ||
                item.dataKeyAscii === DEFAULT_FIELD_CONFIG[type]?.phuongXa) && item.dataValue) return true;
            return false;
        }) || [];
        return address.map((item: FieldConfig) => {
            if (item.dataKeyAscii === DEFAULT_FIELD_CONFIG[type]?.tinhThanh && item.dataValue) return provinceObj?.[item.dataValue];
            if (item.dataKeyAscii === DEFAULT_FIELD_CONFIG[type]?.quanHuyen && item.dataValue) return districtObj?.[item.dataValue];
            if (item.dataKeyAscii === DEFAULT_FIELD_CONFIG[type]?.phuongXa && item.dataValue) return communeObj?.[item.dataValue];
        }).join(", ");
    }

    const openDetailModal = (e: any, item: DataListTable) => {
        navigate(`/data/info_init_history_detail/${item.key}`, { replace: true, state: { searchParam, page, size: pageSizeRef.current } });
    }

    const changePage = (currentPage: number, pageSize: number) => {
        const pageSizeChange = pageSizeRef.current !== pageSize;
        let newPage = currentPage;
        if (pageSizeChange) {
            newPage = 1;
        }
        setPage(newPage);
        pageSizeRef.current = pageSize;
        triggerDataInfoInitHistory({
            searchParam: searchParam ? {
                ...searchParam,
                createDates: undefined,
                types: searchParam?.types?.length ? searchParam.types : undefined,
                isCheck: searchParam.isCheck ? (searchParam.isCheck === 1 ? true : false) : undefined,
                fromCreateDate: searchParam?.createDates && searchParam.createDates[0] ? moment(searchParam.createDates[0]).format("YYYY-MM-DD") : undefined,
                toCreateDate: searchParam?.createDates && searchParam.createDates[1] ? moment(searchParam.createDates[1]).format("YYYY-MM-DD") : undefined,
            } : "",
            page: newPage - 1,
            size: pageSizeRef.current
        })
        // triggerCountDataInfoInit({
        //     searchParam: searchParam ? {
        //         ...searchParam,
        //         isCheck: searchParam.isCheck ? (searchParam.isCheck === 1 ? true : false) : undefined
        //     } : "",
        //     page: newPage - 1,
        //     size: pageSizeRef.current
        // })
        navigate(".", { replace: true, state: { searchParam, page: newPage, size: pageSize } });
    }

    return (
        <div className='content-box'>
            <Row justify="space-between" align="middle" style={{ marginBottom: 15 }}>
                <Col span={6}>
                </Col>
                <div className='search-box-button'>
                    {/* <Button htmlType="button"
                        type="primary"
                    >Xuất excel</Button> */}
                </div>
            </Row>
            <Table<DataListTable>
                onRow={(record, rowIndex) => {
                    return {
                        onClick: event => openDetailModal(event, record), // click row
                        onDoubleClick: event => openDetailModal(event, record), // double click row
                    };
                }}
                loading={responseDataInfoInitHistory?.isFetching}
                dataSource={tableData}
                columns={columns}
                tableLayout="auto"
                pagination={false}
                scroll={{ x: "max-content" }}
            />

            <Spin spinning={responseCountDataInfoInit?.isFetching} tip="Đang tải...">
                <Pagination
                    total={responseCountDataInfoInit?.data || 0}
                    defaultPageSize={pageSizeRef.current}
                    defaultCurrent={1}
                    locale={{ items_per_page: ' dòng' }}
                    pageSizeOptions={[10, 20, 50, 100]}
                    onChange={changePage}
                    showSizeChanger
                    current={page}
                />
            </Spin>
        </div>
    )
}

export default RegisterInfoList;