import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { format } from 'date-fns';
import moment from 'moment';
import { CommonListQuery, } from '../../../../redux/api/apiTypes';
import { ReportParams, ImportItem } from './apiTypes';

export const importReportApi = createApi({
    reducerPath: 'importReportApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_URL + "/api/v1/reports_init",
        prepareHeaders: (headers, { getState }) => {
            const token = localStorage.getItem("accessToken");
            if (token) {
                headers.set('authorization', `Bearer ${token}`)
            }
            return headers;
        }
    }),
    endpoints: (builder) => ({
        getImportReport: builder.query<ImportItem[], { searchParam?: ReportParams } & Partial<CommonListQuery>>({
            query: (body) => ({
                url: `/input_incorrect/search`,
                method: 'POST',
                params: {
                    pageNumber: body.page || 0,
                    pageSize: body.size || 10,
                    sortType: 0
                },
                body: {
                    accounts: body?.searchParam?.accounts,
                    types: body?.searchParam?.types,
                    from_date: body?.searchParam?.dates && body.searchParam.dates[0] ? moment(body.searchParam.dates[0]).format("YYYY-MM-DD") : undefined,
                    to_date: body?.searchParam?.dates && body.searchParam.dates[1] ? moment(body.searchParam.dates[1]).format("YYYY-MM-DD") : undefined
                }
            }),
            transformResponse: (response: { data: ImportItem[] }, meta, arg) => {
                return response.data;
            },
        }),
        getImportReportCount: builder.query<number, { searchParam?: ReportParams } & Partial<CommonListQuery>>({
            query: (body) => ({
                url: `/input_incorrect/search_count`,
                method: 'POST',
                body: {
                    accounts: body?.searchParam?.accounts,
                    types: body?.searchParam?.types,
                    from_date: body?.searchParam?.dates && body.searchParam.dates[0] ? moment(body.searchParam.dates[0]).format("YYYY-MM-DD") : undefined,
                    to_date: body?.searchParam?.dates && body.searchParam.dates[1] ? moment(body.searchParam.dates[1]).format("YYYY-MM-DD") : undefined
                }
            }),
            transformResponse: (response: { data: number }, meta, arg) => {
                return response.data;
            },
        }),
        exportData: builder.mutation<any, { searchParam?: ReportParams } & Partial<CommonListQuery>>({
            query: (body) => ({
                url: `/input_incorrect/export`,
                method: 'POST',
                params: {
                    sort_type: 0
                },
                body: {
                    accounts: body?.searchParam?.accounts,
                    types: body?.searchParam?.types,
                    from_date: body?.searchParam?.dates && body.searchParam.dates[0] ? moment(body.searchParam.dates[0]).format("YYYY-MM-DD") : undefined,
                    to_date: body?.searchParam?.dates && body.searchParam.dates[1] ? moment(body.searchParam.dates[1]).format("YYYY-MM-DD") : undefined
                },
                responseHandler: async (response) => {
                    let filename = `${format(new Date(), "dd-MM-yyyy")}_Báo cáo nhập liệu.xlsx`;
                    let blob = await response.blob();
                    const url = window.URL.createObjectURL(new Blob([blob]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", filename);
                    link.click();
                },
                cache: "no-cache",
            }),
        }),
    })
})

export const {
    useLazyGetImportReportQuery,
    useLazyGetImportReportCountQuery,
    useExportDataMutation,
} = importReportApi;

export default importReportApi;