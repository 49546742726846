import { Col, Row, Table, Pagination, FormInstance, Button, notification } from "antd";
import { ColumnsType } from "antd/es/table";
import { useEffect, useState, useRef } from "react";
import { useExportDataMutation, useLazyGetEmplReportQuery, useLazyGetEmplReportTotalQuery } from "../redux/emplReportApi";

interface DataListTable {
    key: number,
    index: number,
    account: string,
    amount_filed_checked: number
    avg_time_check_each_field: number,
    date: string,
    end_time: string,
    performance: number,
    performance80: number,
    start_time: string,
    total_hour_work: number,
    total_minute_work: number,
}

type Props = {
    form: FormInstance<any>,
    searchParam: any,
}

const ReportList = ({ form, searchParam }: Props) => {
    const [triggerReport, responseReport] = useLazyGetEmplReportQuery();
    const [triggerReportTotal, responseReportTotal] = useLazyGetEmplReportTotalQuery();
    const [triggerExport] = useExportDataMutation();
    const [tableData, setTableData] = useState<DataListTable[]>()
    const [page, setPage] = useState(1);
    const pageSizeRef = useRef(10);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getData();
    }, [searchParam]);

    useEffect(() => {
        if (responseReport?.data?.length) {
            let newTableData: DataListTable[] = [];
            responseReport.data.forEach((item, index) => {
                newTableData.push({
                    key: index + 1,
                    index: index + 1,
                    account: item.account,
                    amount_filed_checked: item.amount_filed_checked,
                    avg_time_check_each_field: item.avg_time_check_each_field,
                    date: item.date,
                    end_time: item.end_time,
                    performance: item.performance,
                    performance80: item.performance80,
                    start_time: item.start_time,
                    total_hour_work: item.total_hour_work,
                    total_minute_work: item.total_minute_work,
                })
            })
            setTableData(newTableData);
        } else {
            setTableData([]);
        }
    }, [responseReport]);

    const columns: ColumnsType<DataListTable> = [
        {
            title: "STT",
            dataIndex: "index",
            key: "index",
            align: "center",
            render: (value, record, index) => (page - 1) * pageSizeRef.current + index + 1,
            width: 70
        },
        {
            title: "Thời gian",
            dataIndex: "date",
            key: "date",
        },
        {
            title: "Tên tài khoản",
            dataIndex: "account",
            key: "account",
        },
        {
            title: "Thời gian bắt đầu",
            dataIndex: "start_time",
            key: "start_time",
        },
        {
            title: "Thời gian kết thúc",
            dataIndex: "end_time",
            key: "end_time",
        },
        {
            title: "Số THHT đã check",
            dataIndex: "amount_filed_checked",
            key: "amount_filed_checked",
        },
        {
            title: "Tổng thời gian làm việc trong ngày (phút)",
            dataIndex: "total_minute_work",
            key: "total_minute_work",
        },
        {
            title: "Tổng thời gian làm việc trong ngày (giờ)",
            dataIndex: "total_hour_work",
            key: "total_hour_work",
        },
        {
            title: "Thời gian TB để kiểm tra một THHT (phút)",
            dataIndex: "avg_time_check_each_field",
            key: "avg_time_check_each_field",
        },
        {
            title: "Năng xuất max / ngày (8h)",
            dataIndex: "performance",
            key: "performance",
        },
        {
            title: "80% năng xuất max",
            dataIndex: "performance80",
            key: "performance80",
        },
    ];

    const getData = () => {
        triggerReport({
            searchParam,
            page: 0,
            size: pageSizeRef.current
        });
        triggerReportTotal({ searchParam });
        setPage(1);
    }

    const changePage = (currentPage: number, pageSize: number) => {
        const pageSizeChange = pageSizeRef.current !== pageSize;
        let newPage = currentPage;
        if (pageSizeChange) {
            newPage = 1;
        }
        setPage(newPage);
        pageSizeRef.current = pageSize;
        triggerReport({
            searchParam: searchParam ? {
                ...searchParam,
            } : "",
            page: newPage - 1,
            size: pageSizeRef.current
        })
        triggerReportTotal({ searchParam });
    }

    const onExportExcelClick = () => {
        setLoading(true);
        triggerExport({ searchParam })
            .unwrap()
            .then((res: any) => {
                setLoading(false);
            })
            .catch((err: any) => {
                setLoading(false);
                notification.error({ message: err.data?.message || "Có lỗi xảy ra!" })
            });
    }

    return (
        <div className='content-box'>
            <Row justify="space-between" align="middle" style={{ marginBottom: 15 }}>
                <Col span={6}></Col>
                <div className='search-box-button'>
                    <Button htmlType="button"
                        type="primary"
                        onClick={onExportExcelClick}
                        loading={loading}
                    >Xuất excel</Button>
                </div>
            </Row>
            <Table<DataListTable>
                loading={responseReport?.isFetching}
                dataSource={tableData}
                columns={columns}
                tableLayout="auto"
                pagination={false}
                scroll={{ x: "max-content" }}
            />

            <Pagination
                total={responseReportTotal?.data || 0}
                defaultPageSize={pageSizeRef.current}
                defaultCurrent={1}
                locale={{ items_per_page: ' dòng' }}
                pageSizeOptions={[10, 20, 50, 100]}
                onChange={changePage}
                showSizeChanger
                current={page}
            />
        </div>
    )
}

export default ReportList;