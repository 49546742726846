import { Button, Checkbox, Form, Input, InputNumber, Modal, notification, Select } from "antd"
import React, { useEffect, useState } from "react"
import { helpers } from "../../../assets/js/helper"
import { useCreateCampaignMutation, useLazyGetUserCheckDataQuery } from "../../../redux/api/commonApi"
import { checkPermission } from "../../common/assets/CommonFunctions"
import { PERMISSION_KEYS } from "../../permissions/ts/Contants"
import { useEditCampaignMutation, useLazyGetCampaignByIdQuery } from "../redux/campaignApi"

type Props = {
    visible: boolean,
    toggleModal: () => void,
    createCampaignSuccess: () => void,
    currentCampaign: any
}

const CreateCampaign = ({ visible, toggleModal, currentCampaign, createCampaignSuccess }: Props) => {
    const [form] = Form.useForm();
    const [triggerUserCheck, responseUserCheck] = useLazyGetUserCheckDataQuery();
    const [triggerCreateCampaign, { isLoading }] = useCreateCampaignMutation();
    const [triggerEditCampaign] = useEditCampaignMutation();
    const [triggerCampaign, responseCampaign] = useLazyGetCampaignByIdQuery();
    const [typeDivide, setTypeDivide] = useState(1);
    const [editing, setEditing] = useState<boolean>(false);

    const canEdit = checkPermission(PERMISSION_KEYS.CHIEN_DICH_NHAP_LIEU.SUA);

    useEffect(() => {
        triggerUserCheck({});
    }, [triggerUserCheck])

    useEffect(() => {
        if (currentCampaign) {
            triggerCampaign(currentCampaign.key).unwrap().then(res => {
                form.setFieldsValue({
                    campaignName: res.campaignName,
                    number: (res.number && res.number > -1) ? res.number : undefined,
                    percent: (res.percent && res.percent > -1) ? res.percent : undefined,
                    // userIds: res.userIds.map(item => item.id) || [],
                    userIds: res.userIdsStr,
                    description: res.description,
                    isDefault: res.isDefault,
                })
                setTypeDivide(res.number && res.number > -1 ? 1 : 2);
            }).catch(err => {
                notification.error({ message: "Có lỗi xảy ra!" });
            });
        }
    }, [triggerCampaign, currentCampaign])

    const onSave = () => {
        form.submit();
    }

    const onFinish = (value: any) => {
        if (!currentCampaign) {
            triggerCreateCampaign({ ...value, searchForm: JSON.stringify({}), isDefault: false }).unwrap().then(res => {
                notification.success({ message: "Tạo chiến dịch thành công!" });
                createCampaignSuccess();
            }).catch(err => {
                notification.error({ message: "Có lỗi xảy ra!" });
            })
        } else {
            triggerEditCampaign({ ...value, id: currentCampaign.key, isDefault: !!responseCampaign?.data?.isDefault }).unwrap().then(res => {
                notification.success({ message: "Chỉnh sửa chiến dịch thành công!" });
                createCampaignSuccess();
            }).catch(err => {
                notification.error({ message: "Có lỗi xảy ra!" });
            })
        }
    }

    const onChangeTypeDivide = (value: number) => {
        setTypeDivide(value);
        form.setFieldsValue({
            number: undefined,
            percent: undefined,
        })
    }
    return (
        <Modal
            visible={visible}
            title={currentCampaign ? "Chỉnh sửa chiến dịch" : "Thêm mới chiến dịch"}
            onCancel={() => toggleModal()}
            width={800}
            maskClosable={false}
            footer={[
                <React.Fragment key="footer">
                    <Button htmlType='button' onClick={toggleModal}>
                        Đóng
                    </Button>
                    {!currentCampaign ? <Button loading={isLoading} htmlType='button' type='primary' onClick={onSave}>
                        Thêm chiến dịch
                    </Button> : <></>}
                    {(currentCampaign && !editing && canEdit) ? <Button htmlType='button' type='primary' onClick={() => setEditing(!editing)}>
                        Chỉnh sửa
                    </Button> : <></>}
                    {(currentCampaign && editing) ? <Button loading={isLoading} htmlType='button' type='primary' onClick={onSave}>
                        Lưu
                    </Button> : <></>}
                </React.Fragment>
            ]}>
            <Form
                name="searchBox"
                autoComplete="off"
                form={form}
                labelWrap
                labelCol={{ span: 5 }}
                wrapperCol={{ span: 19 }}
                labelAlign="left"
                requiredMark={false}
                colon={false}
                onFinish={onFinish}
            >
                <Form.Item
                    label="Mặc định"
                    name="isDefault"
                    valuePropName="checked"
                >
                    <Checkbox disabled />
                </Form.Item>
                <Form.Item
                    label="Tên chiến dịch"
                    name="campaignName"
                    rules={[
                        { required: true, whitespace: true, message: "Trường dữ liệu bắt buộc." }
                    ]}>
                    <Input placeholder="Nhập tên chiến dịch" style={{ minWidth: '100%' }} disabled={currentCampaign && !editing} />
                </Form.Item>
                <Form.Item
                    label="Loại phân công"
                    rules={[
                        { required: true, message: "Trường dữ liệu bắt buộc." }
                    ]}
                >
                    <Select
                        placeholder="Chọn loại phân công"
                        value={typeDivide}
                        onChange={onChangeTypeDivide}
                        disabled={currentCampaign && !editing}
                    >
                        <Select.Option value={1}>Phân công tuyệt đối</Select.Option>
                        <Select.Option value={2}>Phân công tương đối</Select.Option>
                    </Select>
                </Form.Item>
                {typeDivide === 1 ?
                    <Form.Item
                        label="Số lượng bản ghi"
                        name="number"
                        rules={[
                            { required: true, message: "Trường dữ liệu bắt buộc." }
                        ]}
                    >
                        <InputNumber disabled={currentCampaign && !editing} min={0} style={{ minWidth: '100%' }} precision={0} />
                    </Form.Item> : <></>}
                {typeDivide === 2 ? <Form.Item
                    label="Phần trăm dữ liệu"
                    name="percent"
                    rules={[
                        { required: true, message: "Trường dữ liệu bắt buộc." }
                    ]}
                >
                    <InputNumber disabled={currentCampaign && !editing} min={0} max={100} style={{ minWidth: '100%' }} precision={0} />
                </Form.Item> : <></>}
                <Form.Item
                    label="Người thực hiện"
                    name="userIds"
                    rules={[
                        { required: true, message: "Trường dữ liệu bắt buộc." }
                    ]}
                >
                    <Select
                        allowClear
                        placeholder="Chọn người thực hiện"
                        options={(responseUserCheck?.data || []).map(item => ({
                            label: `${item.fullName || ""} (${item.username || ""})`,
                            value: item.id
                        })).sort((item1, item2) => helpers.compareListByName(item1, item2, "label"))}
                        showSearch
                        optionFilterProp="label"
                        mode="multiple"
                        disabled={currentCampaign && !editing}
                    >
                    </Select>
                </Form.Item>
                <Form.Item label="Mô tả" name="description">
                    <Input.TextArea placeholder="Nhập mô tả" rows={3} maxLength={200} disabled={currentCampaign && !editing} />
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default CreateCampaign