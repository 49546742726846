import { Button, Col, Collapse, Form, Input, notification, Row, Select, Skeleton, Spin, Upload } from "antd";
import {
    useLazyGetFieldConfigQuery, useLazyGetRandomDataQuery,
    useUpdateDataByCampaignMutation, useUpdateDataInfoInitByCampaignMutation, useUploadFilePdfCheckMutation
} from "../redux/checkDataApi";
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { FieldConfig } from "../redux/apiTypes";
import { ReactComponent as Top } from '../../../assets/logos/layout-top-line.svg';
import { ReactComponent as Left } from '../../../assets/logos/layout-left-line.svg';
import { ReactComponent as Right } from '../../../assets/logos/layout-right-line.svg';
import { ReactComponent as ZoomOut } from '../../../assets/logos/zoom-out-line.svg';
import { ReactComponent as ZoomIn } from '../../../assets/logos/zoom-in-line.svg';
import { ReactComponent as Rotate } from '../../../assets/logos/rotate.svg';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack5';
import { PDFDocumentProxy } from 'react-pdf/node_modules/pdfjs-dist/types/src/display/api';
import ConfirmModal from "../../common/components/ConfirmModal";
import useResizeObserver from '@react-hook/resize-observer';
import { DEFAULT_FIELD_CONFIG, TYPE_FILE_PDF } from "../../../assets/ts/constant";
import { useLazyGetCommuneAllQuery, useLazyGetCommuneQuery, useLazyGetDistrictAllQuery, useLazyGetDistrictQuery, useLazyGetProvinceQuery } from "../../../redux/api/commonApi";
import { Commune, District } from "../../../redux/api/apiTypes";
import { useLazyGetSortIdQuery } from "../../data/redux/dataCheckApi";
import { helpers } from "../../../assets/js/helper";
import { CheckCircleOutlined, ClockCircleOutlined, ExclamationCircleOutlined, FullscreenOutlined, UploadOutlined, UserOutlined } from "@ant-design/icons";
import moment from "moment";
import { useLazyGetDataInfoInitByFilePdfQuery, useLazyGetFilePdfByIdQuery } from "../../data_init/redux/dataInitApi";
import { useLazyGetDataInfoInitByIdQuery } from "../../data_init/redux/dataInitApi";
import ViewFilePdf from "./ViewFilePdf";
import SplitPane from "react-split-pane";
import { ReactZoomPanPinchRef, TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import DragAndDropTable from "../../data_init/components/DragAndDropTable";
import { ColumnsType } from "antd/es/table";
import { PDFPageProxy } from "react-pdf";
import { checkPermission } from "../../common/assets/CommonFunctions";
import { PERMISSION_KEYS } from "../../permissions/ts/Contants";
const { Panel } = Collapse;

const useWidth = (target: any) => {
    const [width, setWidth] = useState(300);

    useLayoutEffect(() => {
        setWidth(target.current.getBoundingClientRect().width)
    }, [target]);

    useResizeObserver(target, (entry: any) => setWidth(entry.contentRect.width));
    return width;
};

const CreateDataInfo = () => {
    const [form] = Form.useForm();
    const [scale, setScale] = useState(1);
    const [rotate, setRotate] = useState(0);
    const [numPages, setNumPages] = useState(0);
    const [orderNumber, setOrderNumber] = useState(1);
    const [pdfFileName, setPdfFileName] = useState('');
    const [displayFlex, setDisplayFlex] = useState(true);
    const [showUploadBtn, setShowUploadBtn] = useState(false);
    const [width, setWidth] = useState<any>();
    const [initWidth, setInitWidth] = useState<any>();
    const [height, setHeight] = useState<any>();
    const [communes, setCommunes] = useState<Commune[]>([]);
    const [keyMaps, setKeyMaps] = useState<FieldConfig[]>([]);
    const [districts, setDistricts] = useState<District[]>([]);
    const [confirmVisible, setConfirmVisible] = useState(false);
    const [confirmNextextVisible, setConfirmNextextVisible] = useState(false);
    const [indexCompare, setIndexCompare] = useState<number>();
    const [viewPdfVisible, setViewPdfVisible] = useState(false);

    const [triggerData, dataResponse] = useLazyGetRandomDataQuery<any>();
    const [triggerProvince, responseProvince] = useLazyGetProvinceQuery();
    const [districtTrigger, responseDistrict] = useLazyGetDistrictQuery();
    const [communeTrigger, responseCommune] = useLazyGetCommuneQuery();
    const [triggerCurrentSort, currentSortResponse] = useLazyGetSortIdQuery();
    const [triggerGetFieldConfig, fieldConfigResponse] = useLazyGetFieldConfigQuery();
    const [triggerGetDataInfoInit, dataInfoInitResponse] = useLazyGetDataInfoInitByIdQuery();
    const [updateData] = useUpdateDataByCampaignMutation();
    const [updateDataInit, { isLoading }] = useUpdateDataInfoInitByCampaignMutation();
    const [listActive, setListActive] = useState<String[]>([]);
    const [triggerFilePdfId, detailResponse] = useLazyGetFilePdfByIdQuery();
    const [communeAllTrigger, responseCommuneAll] = useLazyGetCommuneAllQuery();
    const [districtAllTrigger, responseDistrictAll] = useLazyGetDistrictAllQuery();
    const [tableData, setTableData] = useState<any[]>([]);
    const [columns, setColumns] = useState<ColumnsType<any>>([]);
    const [triggerGetDataInfoInitByFilePdf, dataInfoInitByFilePdfResponse] = useLazyGetDataInfoInitByFilePdfQuery();
    const wrapperDiv = useRef<HTMLInputElement>(null);
    const [docKey, setDocKey] = useState(0);

    const canvas = useRef<any>();
    const canvas1 = useRef<any>();
    const transformComponentRef = useRef<ReactZoomPanPinchRef>(null!);
    const [clientY, setClientY] = useState<number>(0);
    const [isRendered, setIsRendered] = useState<boolean>(false);
    const [isDrag, setIsDrag] = useState<boolean>(false);
    const prevScale = useRef<number>(1);
    const [uploadFile, responseDataInfo] = useUploadFilePdfCheckMutation();
    const [loadingFile, setLoadingFile] = useState(false);

    useEffect(() => {
        if (clientY === 0 || !canvas.current || !isRendered) {
            return;
        }
        const context = canvas.current.getContext('2d');
        const { width } = canvas.current;
        const rect = canvas.current.getBoundingClientRect();
        // if (clientY === 0 || !canvas1.current || !isRendered) {
        //     return;
        // }

        context.save();
        context.beginPath();
        context.moveTo(0, clientY);
        context.lineTo(width, clientY);
        context.strokeStyle = 'red';
        context.stroke();
        context.restore();


        if (canvas1?.current && canvas1.current.getContext('2d')) {
            const context1 = canvas1.current.getContext('2d');
            const width1 = canvas1.current.width;
            const rect1 = canvas1.current.getBoundingClientRect();
            context1.save();
            context1.beginPath();
            context1.moveTo(0, clientY);
            context1.lineTo(width1, clientY);
            context1.strokeStyle = 'red';
            context1.stroke();
            context1.restore();
        }
    }, [clientY, isRendered]);
    useEffect(() => {
        if (prevScale.current === scale) {
            return;
        } else {
            transformComponentRef.current.resetTransform(0);
            transformComponentRef.current.centerView(1, 0);
            setClientY((prevClientY) => prevClientY * (scale / prevScale.current));
            setDocKey((s) => ++s);
            setIsRendered(false);
        }
    }, [scale])
    const onRenderSuccess = (page: PDFPageProxy) => {
        setIsRendered(true);
    }
    const onClickPage = (event: React.MouseEvent, page: PDFPageProxy) => {
        if (!isDrag) {
            const rect = canvas.current.getBoundingClientRect();
            const scaleY = canvas.current.height / rect.height;
            setClientY((event.clientY - rect.top) * scaleY);
        }
    }

    const file = useMemo(() => {
        let uri = '';
        // qtk/phongtuphap/CC/2019/02/CC.2019.02.2019-12-31.261.pdf
        // phuongvinhtrung_qtk_tpdn.HN.2018.02.xls
        if (dataResponse?.data?.filePDFId || dataInfoInitResponse?.data?.filePDFId) {
            uri = `/api/v1/file/download-pdf/${dataInfoInitResponse?.data?.filePDFId || dataResponse?.data?.filePDFId}`;
        } else if (dataResponse?.data?.excelName && pdfFileName) {
            let excelNames = dataResponse?.data?.excelName?.split("_");
            let excelNames1 = dataResponse?.data?.excelName?.split(".");
            let pathName = `${excelNames?.[1]}/${excelNames?.[0]}/${dataResponse?.data?.typeId}/${excelNames1?.[2]}/${excelNames1?.[3]}/${pdfFileName}`
            uri = `/api/v1/file/download-pdf?dataInfoId=${dataResponse?.data?.id}&pathFile=${pathName}`;
        }
        if (uri) {
            return {
                url: process.env.REACT_APP_API_URL + uri,
                httpHeaders: {
                    Authorization: 'Bearer ' + localStorage.getItem("accessToken"),
                    "Content-Type": "application/json;charset=UTF-8",
                },
            }
        }
    }, [dataResponse?.data?.excelName, dataResponse?.data?.id, dataResponse?.data?.typeId, pdfFileName, dataInfoInitResponse?.data?.filePDFId]);
    // }, [dataResponse?.data?.id, dataResponse?.data?.filePDFId]);

    useEffect(() => {
        triggerData({});
        districtAllTrigger({}).unwrap()
            .then(dists => setDistricts(dists));
        communeAllTrigger({}).unwrap()
            .then(coms => setCommunes(coms));
    }, [triggerData, communeAllTrigger, districtAllTrigger]);

    useEffect(() => {
        const slider = document.getElementById("block-view-pdf");
        let isDown = false;
        let startX: any;
        let startY: any;
        let scrollLeft: any;
        let scrollTop: any;
        if (slider) {
            slider.addEventListener('mousedown', (e) => {
                isDown = true;
                slider.classList.add('active');
                startX = e.pageX - slider.offsetLeft;
                startY = e.pageY - slider.offsetTop;
                scrollLeft = slider.scrollLeft;
                scrollTop = slider.scrollTop;
                setIsDrag(false);
            });
            slider.addEventListener('mouseleave', () => {
                isDown = false;
                slider.classList.remove('active');
            });
            slider.addEventListener('mouseup', () => {
                isDown = false;
                slider.classList.remove('active');
            });
            slider.addEventListener('mousemove', (e) => {
                if (!isDown) return;
                e.preventDefault();
                const x = e.pageX - slider.offsetLeft;
                const walk = (x - startX) * 3; //scroll-fast
                slider.scrollLeft = scrollLeft - walk;
                setIsDrag(true);
            });
            slider.addEventListener('mousemove', (e) => {
                if (!isDown) return;
                e.preventDefault();
                const x = e.pageY - slider.offsetTop;
                const walk = (x - startY) * 3; //scroll-fast
                slider.scrollTop = scrollTop - walk;
                setIsDrag(true);
            });
        }
    }, []);

    useEffect(() => {
        if (displayFlex) {
            setWidth((wrapperDiv?.current?.getBoundingClientRect()?.width || 0) / 3 || undefined);
            setInitWidth((wrapperDiv?.current?.getBoundingClientRect()?.width || 0) / 3 || undefined);
        }
    }, [wrapperDiv?.current?.getBoundingClientRect()?.width, displayFlex]);

    useEffect(() => {
        if (fieldConfigResponse?.data?.fieldKeyMap) {
            setColumns([
                ...(fieldConfigResponse?.data?.fieldKeyMap || "")
                    .filter((x: any) =>
                        // x.isDefault &&
                        x.isEdit &&
                        x.dataKeyAscii !== "tinhThanh" &&
                        x.dataKeyAscii !== "quanHuyen" &&
                        x.dataKeyAscii !== "phuongXa"
                    )
                    .map((item: any, index: number) => {
                        return {
                            title: item.dataKey,
                            dataIndex: item.dataKeyAscii,
                            key: item.dataKeyAscii,
                            align: "center",
                            width: item.dataKeyAscii == "so" ||
                                item.dataKeyAscii === "quyenSo" ||
                                item.dataKeyAscii === "trangSo" ||
                                item.type === "date" ? 125 : 250,
                            fixed: item.dataKeyAscii == "so" ||
                                item.dataKeyAscii === "quyenSo" ? 'left' : '',
                            // render: (record: any) => {
                            //     console.log(record);
                            //     return ;
                            // },
                        }
                    })
            ]);
        }
    }, [fieldConfigResponse]);

    useEffect(() => {
        if (dataInfoInitByFilePdfResponse?.data?.length && columns?.length) {
            setTableData(dataInfoInitByFilePdfResponse?.data?.map((item: any) => {
                return {
                    key: item.id,
                    // ...item.dataKeyMap?.reduce((a: any, v: any) => ({ ...a, [v.dataKeyAscii]: v.dataKeyAscii === "quyenSo" ? <Link to={`/data/lack/info_init_detail/${item.id}`}>{v.dataValue || ""}</Link> : v.dataValue }), {})
                    ...item.dataKeyMap?.reduce((a: any, v: any) => ({ ...a, [v.dataKeyAscii]: v.dataValue }), {})
                }
            }));
        } else {
            setTableData([]);
        }
    }, [dataInfoInitByFilePdfResponse, columns]);

    useEffect(() => {
        // If have filePdfId is DataInfoInit\
        if (dataResponse?.isFetching || !districts?.length || !communes?.length) return;
        if (dataResponse?.data?.filePDFId) {
            triggerProvince({});
            let typeFile = dataResponse?.data?.filePDFName?.split(".")?.[0];
            if (typeFile) {
                triggerFilePdfId(dataResponse?.data?.filePDFId).unwrap().then(res => {
                    triggerGetDataInfoInitByFilePdf(dataResponse?.data?.filePDFId);
                    triggerGetFieldConfig({ types: typeFile, years: convertYearToTypePdf(typeFile) }).unwrap().then(res => {
                        let dataKeys = dataResponse?.data?.dataKeyMap?.reduce((a: any, v: any) => ({ ...a, [v.dataKeyAscii]: v }), {});
                        let dataFieldValue = dataResponse?.data?.dataKeyMap?.reduce((a: any, v: any) => ({ ...a, [v.dataKeyAscii]: v.dataValue }), {});

                        // Cập nhật thông tin đầu vào cho data info init
                        form.setFieldsValue(dataFieldValue);
                        if (dataFieldValue[DEFAULT_FIELD_CONFIG[typeFile]?.tinhThanh]) {
                            districtTrigger(dataFieldValue[DEFAULT_FIELD_CONFIG[typeFile]?.tinhThanh]).unwrap()
                                .then(dists => setDistricts(dists));
                        }
                        if (dataFieldValue[DEFAULT_FIELD_CONFIG[typeFile]?.quanHuyen]) {
                            communeTrigger(dataFieldValue[DEFAULT_FIELD_CONFIG[typeFile]?.quanHuyen]).unwrap()
                                .then(coms => setCommunes(coms));
                        }

                        if (dataFieldValue?.[DEFAULT_FIELD_CONFIG[typeFile]?.tenfilepdf]) {
                            setPdfFileName(dataFieldValue[DEFAULT_FIELD_CONFIG?.[typeFile]?.tenfilepdf]);
                        }

                        // console.log(dataKeys)
                        // Cập nhật thông tin key maps
                        setKeyMaps((res.fieldKeyMap || dataResponse?.data?.dataKeyMap).map((item: any, index: number) => {
                            return {
                                ...item,
                                // dataKey: dataKeys[item.dataKeyAscii]?.dataKey,
                                // dataKeyAscii: dataKeys[item.dataKeyAscii]?.dataKeyAscii,
                                dataValue: dataKeys[item.dataKeyAscii]?.dataValue,
                                isEdited: false,
                                error: dataKeys[item.dataKeyAscii]?.isEdited,
                            }
                        }));
                    });
                })
            } else {
                setKeyMaps([]);
            }
            // else not filePdfId is DataInfo
        } else if (dataResponse?.data?.typeId) {
            triggerProvince({});
            let typeFile = dataResponse?.data?.typeId;
            if (typeFile) {
                if (dataResponse?.data?.filePDFId) {
                    triggerGetDataInfoInitByFilePdf(dataResponse?.data?.filePDFId);
                }
                triggerGetFieldConfig({ types: typeFile, years: convertYearToTypePdf(typeFile) }).unwrap().then(res => {
                    let dataKeys = dataResponse?.data?.dataKeyMap?.reduce((a: any, v: any) => ({ ...a, [v.dataKeyAscii]: v }), {});
                    let dataFieldValue = dataResponse?.data?.dataKeyMap?.reduce((a: any, v: any) => ({ ...a, [v.dataKeyAscii]: v.dataValue }), {});
                    // Cập nhật thông tin đầu vào cho data info init
                    form.setFieldsValue(dataFieldValue);
                    if (dataFieldValue[DEFAULT_FIELD_CONFIG[typeFile]?.tinhThanh]) {
                        districtTrigger(dataFieldValue[DEFAULT_FIELD_CONFIG[typeFile]?.tinhThanh]).unwrap()
                            .then(dists => setDistricts(dists));
                    }
                    if (dataFieldValue[DEFAULT_FIELD_CONFIG[typeFile]?.quanHuyen]) {
                        communeTrigger(dataFieldValue[DEFAULT_FIELD_CONFIG[typeFile]?.quanHuyen]).unwrap()
                            .then(coms => setCommunes(coms));
                    }

                    if (dataFieldValue?.[DEFAULT_FIELD_CONFIG[typeFile]?.tenfilepdf]) {
                        setPdfFileName(dataFieldValue[DEFAULT_FIELD_CONFIG?.[typeFile]?.tenfilepdf]);
                    }

                    // Cập nhật thông tin key maps
                    setKeyMaps((res.fieldKeyMap || dataResponse?.data?.dataKeyMap).map((item: any, index: number) => {
                        return {
                            ...item,
                            // dataKey: dataKeys[item.dataKeyAscii]?.dataKey,
                            // dataKeyAscii: dataKeys[item.dataKeyAscii]?.dataKeyAscii,
                            dataValue: dataKeys[item.dataKeyAscii]?.dataValue,
                            isEdited: false,
                            error: dataKeys[item.dataKeyAscii]?.isEdited,
                        }
                    }));
                });
            } else {
                setKeyMaps([]);
            }
        } else if (dataResponse?.data) {
            let dataKeys = dataResponse?.data?.keyMaps?.reduce((a: any, v: any) => ({ ...a, [v.dataKeyAscii]: v }), {});
            triggerCurrentSort(dataResponse?.data?.dataFile?.id).unwrap()
                .then((res: any) => {
                    setKeyMaps((res?.keyMaps || dataResponse?.data?.keyMaps).map((item: any, index: number) => {
                        return {
                            ...item,
                            dataKey: dataKeys[item.dataKeyAscii]?.dataKey,
                            dataKeyAscii: dataKeys[item.dataKeyAscii]?.dataKeyAscii,
                            dataValue: dataKeys[item.dataKeyAscii]?.dataValue,
                            isEdited: false,
                            error: dataKeys[item.dataKeyAscii]?.isEdited,
                        }
                    }));
                }).catch((err: any) => {
                    setKeyMaps(dataResponse?.data?.keyMaps.map((item: any, index: number) => {
                        return {
                            ...item,
                            dataKey: dataKeys[item.dataKeyAscii]?.dataKey,
                            dataKeyAscii: dataKeys[item.dataKeyAscii]?.dataKeyAscii,
                            dataValue: dataKeys[item.dataKeyAscii]?.dataValue,
                            isEdited: false,
                            error: dataKeys[item.dataKeyAscii]?.isEdited,
                        }
                    }));
                });
        } else {
            setKeyMaps([]);
            setPdfFileName('')
        }
    }, [triggerGetFieldConfig, triggerProvince, dataResponse?.data, districts, communes]);

    const convertYearToTypePdf = (typeFile: string) => {
        // KS.1994.01.--.001.PDF
        let yearStr = dataResponse?.data?.fileName?.split(".")?.[1];
        let yearNum = yearStr ? parseInt(yearStr) : 0;
        // TODO: LƯU Ý CHECK LẠI RULE NÀY KHI TRIỂN KHAI CHO QUẢNG NGÃI (ĐẶC BIỆT GIAI ĐOẠN <= 1977)
        // Chuyển từ năm sang file loại file: A3, A4
        if (typeFile === "KH" && yearNum === 2007 && detailResponse?.data?.communeCode === "xatinhantay") {
            return TYPE_FILE_PDF.A3;
        }
        if (yearNum > 2006) {
            if (typeFile === "KH" &&
                detailResponse?.data?.communeCode === "sotuphap" &&
                detailResponse?.data?.districtCode === "thanhphoquangngai") {
                if (yearNum < 2011) {
                    return TYPE_FILE_PDF.A3;
                }
            }
            return TYPE_FILE_PDF.A4;
        }
        if (yearNum > 1977) {
            return TYPE_FILE_PDF.A3;
        }
        switch (typeFile) {
            case "KS":
                return TYPE_FILE_PDF.A4
            default:
                return TYPE_FILE_PDF.A4;
        }
    }

    const onFinish = (values: any) => {
        form.validateFields().then(res => {
            // triggerCheckExists({ dataInfoId: dataResponse?.data?.id, filePDFName: pdfFileName }).unwrap().then(res => {
            //     if (res) {
            toggleConfirmModal();
            //     } else {
            //         notification.error({ message: "Không tồn tại file pdf với dữ liệu bạn cập nhật!" });
            //     }
            // }).catch(err => {
            //     notification.error({ message: "Không tồn tại file pdf với dữ liệu bạn cập nhật!" });
            // })
        })
    }

    const onSubmitCheckData = () => {
        let keyMapsObj = keyMaps.reduce((a: any, v: any) => ({ ...a, [v.dataKeyAscii]: v.dataValue }), {});
        // If have filePdfId is DataInfoInit
        if ((dataResponse?.data?.filePDFId || dataResponse?.data?.typeId) &&
            fieldConfigResponse?.data?.type &&
            keyMapsObj?.[DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.tinhThanh] &&
            keyMapsObj?.[DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.quanHuyen] &&
            keyMapsObj?.[DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.phuongXa]) {
            updateDataInit({
                dataKeyMap: keyMaps,
                id: dataResponse?.data?.id,
                isCheck: true,
                communeId: keyMapsObj?.[DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.phuongXa],
                districtId: keyMapsObj?.[DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.quanHuyen],
                provinceId: keyMapsObj?.[DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.tinhThanh],
                campaignId: dataResponse?.data?.dataCampaignId
            }).unwrap()
                .then(res => {
                    notification.success({ message: "Cập nhật kết quả thành công" });
                    triggerData({});
                    toggleConfirmModal();
                    setListActive([]);
                }).catch(err => {
                    notification.error({ message: err.data?.message || "Có lỗi xảy ra!" })
                })
        } else {
            updateData({
                keyMaps,
                id: dataResponse?.data?.id,
                isCheck: true,
            }).unwrap()
                .then(res => {
                    notification.success({ message: "Cập nhật kết quả thành công" });
                    triggerData({});
                    toggleConfirmModal();
                }).catch(err => {
                    notification.error({ message: err.data?.message || "Có lỗi xảy ra!" })
                })
        }
    }

    const changeLayout = (newDisplayFlex: boolean, order: number) => {
        setDisplayFlex(newDisplayFlex);
        setOrderNumber(order);
        if (!newDisplayFlex) {
            setWidth(initWidth * 2);
        }
    }
    const onDocumentLoadSuccess = ({ numPages }: any) => {
        setNumPages(numPages);
        console.log(numPages);
        triggerGetDataInfoInit(dataResponse?.data?.id);
        // setShowUploadBtn(false);
    }

    // Format lại dữ liệu sau khi nhập liệu
    const updateDataInitBlur = (value: any, index: number, item: FieldConfig) => {
        if (item.type === "date") {
            value = helpers.customDate(value);
            changeDataInit(value, index, item);
        } else if (item.type === "time" && value) {
            value = helpers.customTime(value, item.format);
            changeDataInit(value, index, item);
        } else if (item.format?.toLowerCase().includes("capitalcase")) {
            value = helpers.toTitleCase(value);
            changeDataInit(value, index, item);
        } else {
            changeDataInit((value || "").trim(), index, item);
        }
        form.validateFields([item.dataKeyAscii]);
        onBlurItem(item);
    }

    const onBlurItem = (item: any) => {
        if (listActive.indexOf(item.dataKeyAscii) === -1) {
            setListActive([...listActive, item.dataKeyAscii]);
        }
    }

    // Change dataValue Of Data Info Init
    const changeDataInit = (value: any, index: number, item: FieldConfig) => {
        let dataKeys = dataResponse?.data?.dataKeyMap?.reduce((a: any, v: any) => ({ ...a, [v.dataKeyAscii]: v }), {});
        // Nếu không cho sửa thì không được sửa
        if (!item.isEdit) return;
        if (item?.dataKeyAscii) {
            form.setFieldsValue({ [item.dataKeyAscii]: value });
        }
        switch (item.dataKeyAscii) {
            case DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type].tinhThanh:
                form.setFieldsValue({ quanHuyen: undefined, phuongXa: undefined })
                setDistricts([]);
                setCommunes([]);
                districtTrigger(value).unwrap()
                    .then(res => setDistricts(res));
                break;
            case DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type].quanHuyen:
                form.setFieldsValue({ phuongXa: undefined })
                setCommunes([]);
                communeTrigger(value).unwrap()
                    .then(res => setCommunes(res));
                break;
        }
        // KS.2007.01.2007-07-11.082.pdf
        let changeFilePdf = item.dataKeyAscii === DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type].so ||
            item.dataKeyAscii === DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type].quyenSo ||
            item.dataKeyAscii === DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type].ngayDangKy;
        let tenFilePdf = "";
        if (changeFilePdf) {
            let type = dataResponse?.data?.typeId;
            let so = form?.getFieldsValue()?.[DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type].so];
            let quyenSo = form?.getFieldsValue()?.quyenSo?.split("/");
            let ngayDangKy = form?.getFieldsValue()?.ngayDangKy?.split(".") || '';
             // ngayDangKy = ngayDangKy.length === 3 ? ngayDangKy : [];
            let temp = '';
            if(ngayDangKy?.length===3){
                temp = `${ngayDangKy?.[2] || ""}-${ngayDangKy?.[1] || ""}-${ngayDangKy?.[0] || ""}`;
            }else  if(ngayDangKy?.length===2){
                temp = `${ngayDangKy?.[1] || ""}-${ngayDangKy?.[0] || ""}-`;
            }else{
                temp = `${ngayDangKy?.[0] || ""}--`;
            }
            tenFilePdf = `${type}.${quyenSo?.[1] || '--'}.${quyenSo?.[0] || '--'}.${temp}.${so || ""}.pdf`;
            form.setFieldsValue({
                [DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type].tenfilepdf]: tenFilePdf,
            })
            setPdfFileName(tenFilePdf);
        }
        setKeyMaps(keyMaps.map((x, i) => {
            if (x.dataKeyAscii === item.dataKeyAscii) return {
                ...x,
                dataValue: value || "",
                isEdited: (value !== dataKeys[item?.dataKeyAscii || ""]?.dataValue) && !!(dataKeys[item?.dataKeyAscii || ""]?.dataValue || value),
            }
            if (x.dataKeyAscii === DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.tenfilepdf && changeFilePdf) return {
                ...x,
                dataValue: tenFilePdf,
                isEdited: value !== dataKeys[item?.dataKeyAscii || ""]?.dataValue,
            }
            if ((x.dataKeyAscii || "").toLowerCase().includes((item.dataKeyAscii || "").toLowerCase()) &&
                (x.dataKeyAscii || "").toLowerCase().includes("bangchu")) {
                let txt = helpers.dateToWord(value || '');
                form.setFieldsValue({ [x.dataKeyAscii]: txt });
                return {
                    ...x,
                    dataValue: txt
                }
            }
            return x;
        }));
    }

    // Change dataValue of Data info
    const changeDataInfo = (e: any, key: string) => {
        let dataKeys = dataResponse?.data?.keyMaps?.reduce((a: any, v: any) => ({ ...a, [v.dataKeyAscii]: v }), {});
        let currentKeyMaps = [...keyMaps];
        currentKeyMaps = currentKeyMaps.map(item => {
            if (item.dataKeyAscii === key) {
                return {
                    ...item,
                    dataValue: e.target.value || "",
                    isEdited: e.target.value !== dataKeys[key].dataValue,
                }
            }
            return item;
        })
        setKeyMaps(currentKeyMaps);
    }

    const toggleConfirmModal = () => {
        setConfirmVisible(!confirmVisible);
    }

    // Convert object field to element
    const convertTypeDataToElement = (item: FieldConfig, index: number) => {
        // console.log(item);
        if (item.data && item.data.length) {
            return <Form.Item
                name={item.dataKeyAscii}
                style={{ marginBottom: 10 }}
                label={<div style={{ display: "flex" }}>
                    {listActive.indexOf(item.dataKeyAscii) !== -1 ?
                        <CheckCircleOutlined title="Đã duyệt qua" color="success" style={{ margin: 5, color: "#52c41a" }} /> : <></>}
                    <div
                        style={{
                            fontWeight: 700,
                            wordBreak: "break-word",
                            color: item.isEdited ? "#ff4d4f" : "",
                            textDecoration: item.error ? "underline red 3px" : "",
                        }}>{item.dataKey || ""}
                    </div>
                </div>}
                rules={createRules(item)}
            >
                <Select
                    disabled={!item.isEdit}
                    allowClear
                    placeholder={item.placeholder || "--Lựa chọn--"}
                    optionFilterProp='label'
                    options={(item.data || []).map((x: any) => ({
                        label: `${x.id}${x.name ? (" - " + x.name) : ""}`,
                        value: x.id
                    }))}
                    showSearch
                    showArrow
                    onChange={(value) => changeDataInit(value, index, item)}
                    onBlur={() => onBlurItem(item)}
                    value={item.dataValue}
                >
                </Select>
            </Form.Item>
        }

        if (item.isDefault && fieldConfigResponse?.data?.type && (!item.data || !item.data.length)) {
            switch (item.dataKeyAscii) {
                case DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type].tinhThanh:
                    return <Form.Item
                        name={item.dataKeyAscii || ""}
                        style={{ marginBottom: 10 }}
                        label={<div style={{ display: "flex" }}>
                            {listActive.indexOf(item.dataKeyAscii) !== -1 ?
                                <CheckCircleOutlined title="Đã duyệt qua" color="success" style={{ margin: 5, color: "#52c41a" }} /> : <></>}
                            <div style={{
                                fontWeight: 700,
                                wordBreak: "break-word",
                                color: item.isEdited ? "#ff4d4f" : "",
                                textDecoration: item.error ? "underline red 3px" : ""
                            }}>{item.dataKey || ""}</div>
                        </div>}
                        rules={[
                            { required: true, message: "Trường dữ liệu bắt buộc" },
                        ]}
                    >
                        <Select
                            disabled={!item.isEdit}
                            allowClear
                            placeholder={item.placeholder || "--Lựa chọn--"}
                            optionFilterProp='label'
                            options={(responseProvince?.data || []).map((item) => ({
                                label: item.provinceName,
                                value: item.id
                            }))}
                            showSearch
                            showArrow
                            onChange={(value) => changeDataInit(value, index, item)}
                            onBlur={() => onBlurItem(item)}
                            value={item.dataValue}
                        >
                        </Select>
                    </Form.Item>
                case DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type].quanHuyen:
                    return <Form.Item
                        name={item.dataKeyAscii || ""}
                        style={{ marginBottom: 10 }}
                        label={<div style={{ display: "flex" }}>
                            {listActive.indexOf(item.dataKeyAscii) !== -1 ?
                                <CheckCircleOutlined title="Đã duyệt qua" color="success" style={{ margin: 5, color: "#52c41a" }} /> : <></>}
                            <div style={{
                                fontWeight: 700,
                                wordBreak: "break-word",
                                color: item.isEdited ? "#ff4d4f" : "",
                                textDecoration: item.error ? "underline red 3px" : ""
                            }}>{item.dataKey || ""}</div>
                        </div>}
                        rules={[
                            { required: true, message: "Trường dữ liệu bắt buộc" },
                        ]}
                    >
                        <Select
                            disabled={!item.isEdit}
                            allowClear
                            placeholder={item.placeholder || "--Lựa chọn--"}
                            optionFilterProp='label'
                            options={(districts || []).map((item) => ({
                                label: item.districtName,
                                value: item.id
                            }))}
                            showSearch
                            showArrow
                            onChange={(value) => changeDataInit(value, index, item)}
                            onBlur={() => onBlurItem(item)}
                            value={item.dataValue}
                        >
                        </Select>
                    </Form.Item>
                case DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type].phuongXa:
                    return <Form.Item
                        name={item.dataKeyAscii || ""}
                        style={{ marginBottom: 10 }}
                        label={<div style={{ display: "flex" }}>
                            {listActive.indexOf(item.dataKeyAscii) !== -1 ?
                                <CheckCircleOutlined title="Đã duyệt qua" color="success" style={{ margin: 5, color: "#52c41a" }} /> : <></>}
                            <div style={{
                                fontWeight: 700,
                                wordBreak: "break-word",
                                color: item.isEdited ? "#ff4d4f" : "",
                                textDecoration: item.error ? "underline red 3px" : ""
                            }}>{item.dataKey || ""}</div>
                        </div>}
                        rules={[
                            { required: true, message: "Trường dữ liệu bắt buộc" },
                        ]}
                    >
                        <Select
                            disabled={!item.isEdit}
                            allowClear
                            placeholder={item.placeholder || "--Lựa chọn--"}
                            optionFilterProp='label'
                            options={(communes || []).map((item) => ({
                                label: item.communeName,
                                value: item.id
                            }))}
                            showSearch
                            showArrow
                            onChange={(value) => changeDataInit(value, index, item)}
                            onBlur={() => onBlurItem(item)}
                            value={item.dataValue}
                        >
                        </Select>
                    </Form.Item>
            }
        }
        return <Form.Item
            name={item.dataKeyAscii}
            style={{ marginBottom: 10 }}
            label={<div style={{ display: "flex" }}>
                {listActive.indexOf(item.dataKeyAscii) !== -1 ?
                    <CheckCircleOutlined title="Đã duyệt qua" color="success" style={{ margin: 5, color: "#52c41a" }} /> : <></>}
                <div style={{
                    fontWeight: 700,
                    wordBreak: "break-word",
                    color: item.isEdited ? "#ff4d4f" : "",
                    textDecoration: item.error ? "underline red 3px" : ""
                }}>{item.dataKey || ""}</div>
            </div>}
            rules={createRules(item)}
        ><Input.TextArea
                placeholder={item.placeholder || ''}
                disabled={!item.isEdit||item.dataKeyAscii==='quyenSo'}
                onFocus={(e) => e.currentTarget.select()}
                onChange={(e) => changeDataInit(e.target.value, index, item)}
                // value={item.dataValue}
                onBlur={(e) => updateDataInitBlur(e.target.value, index, item)}
            /></Form.Item>;
    }



    const createRules = (item: any) => {
        let rules: Object[] = [];
        if (item.isDefault) {
            rules = [...rules, { required: true, message: "Trường dữ liệu bắt buộc" }];
        }
        if (item.format && item.format !== "capitalcase") {
            rules = [...rules, { pattern: new RegExp(item.format), message: "Dữ liệu không đúng định dạng" }];
        }
        return rules;
    }

    const checkChangeData = () => {
        return keyMaps.find(x => x.isEdited);
    }

    // Chuyển dữ liệu khác
    const nextDataInfo = () => {
        if (confirmNextextVisible) {
            triggerData({});
            if (confirmNextextVisible) {
                setConfirmNextextVisible(!confirmNextextVisible);
            }
            setListActive([]);
        } else {
            setConfirmNextextVisible(!confirmNextextVisible);
        }
    }

    const checkActiveSave = () => {
        let check = true;
        keyMaps.filter(x => x.isEdit && x.isDisplay).map(item => {
            if (item.dataKeyAscii !== DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.tinhThanh &&
                item.dataKeyAscii !== DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.quanHuyen &&
                item.dataKeyAscii !== DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.phuongXa &&
                item.dataKeyAscii !== DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.quyenSo &&

                listActive.indexOf(item.dataKeyAscii) === -1) check = false;
        })
        return check;
    }

    const toggleViewPdfModal = () => {
        setViewPdfVisible(!viewPdfVisible);
    }

    const changeSize = (size: number) => {
        if (displayFlex) {
            setWidth(size);
            setClientY(clientY * (size / width));
        } else {
            setHeight(size);
            setClientY(clientY * (size / height));
        }
        setIsRendered(false);
    }

    const beforeUpload = (files: File[]) => {
        let accepted = true;
        let fileList = [...files];
        fileList = fileList.slice(-1);
        Array.from(fileList).forEach(file => {
            if (file.type !== "application/pdf") {
                accepted = false;
                notification.error({ message: `Định dạng file không hợp kệ` });
            }
        })
        return accepted || Upload.LIST_IGNORE;
    }

    const handleCopy = () => {
        const textToCopy = window.location.protocol + '//' + window.location.hostname + ':' + window.location.port + '/data/info_init_detail/' + dataResponse?.data?.id;
        if (navigator.clipboard) {
            navigator.clipboard.writeText(textToCopy)
                .then(() => {
                    notification.success({message: 'Đã sao chép thành công'})
                    console.log('Đã sao chép thành công:', textToCopy);
                })
                .catch((error) => {
                    notification.error({message: 'Lỗi sao chép'})
                    console.error('Lỗi sao chép:', error);
                });
        } else {
            // Use the 'out of viewport hidden text area' trick
            const textArea = document.createElement("textarea");
            textArea.value = textToCopy;

            // Move textarea out of the viewport so it's not visible
            textArea.style.position = "absolute";
            textArea.style.left = "-999999px";

            document.body.prepend(textArea);
            textArea.select();

            try {
                document.execCommand('copy');
                notification.success({message: 'Đã sao chép thành công'})
            } catch (error) {
                console.error(error);
            } finally {
                textArea.remove();
            }
        }
    };

    const customUpload = (options: any) => {
        setLoadingFile(true);
        options.onProgress({ percent: 0 })
        let formData = new FormData();
        formData.append('file', options.file);
        if ((options?.file?.name || "").toLowerCase() != (pdfFileName || "").toLowerCase()) {
            notification.error({ message: "Tên file pdf không trùng khớp với dữ liệu đã nhập!" });
            setLoadingFile(true);
        } else {
            uploadFile({
                dataInfoId: dataResponse?.data?.id,
                file: formData,
                // filePDFName: dataResponse?.data?.filePDFName
                filePDFName: pdfFileName
            }).unwrap()
                .then((res: any) => {
                    options.onProgress({ percent: 100 });
                    options.onSuccess(res, options.file);
                    setLoadingFile(false);
                    notification.success({ message: "Tải file thành công!" });
                    triggerGetDataInfoInit(dataResponse?.data?.id);
                }).catch((err: any) => {
                    options.onError();
                    setLoadingFile(false);
                    if (err?.data?.message) {
                        notification.error({
                            message: err?.data?.message
                        })
                    }
                })
        }

    }

    useEffect(() => {
        console.log("file", file)
    }, [file])

    const [displayA, setdisplayA] = useState(false);

    return (
        // <Spin tip="Đang tải..." spinning={fieldConfigResponse.isFetching}>
        <>
            <div className="content-box" style={{margin: "0px 20px", padding: "20px"}}>
                <div className="header-block" style={{ display: "flex", justifyContent: "space-between" }}>
                    <div>
                        <UserOutlined
                            style={{
                                cursor: 'pointer',
                                width: 20,
                                height: 20,
                                marginBottom: 0
                            }} />
                        <span style={{ margin: "0px 3px" }}>
                            {dataResponse?.data?.createBy}
                        </span>
                        - <ClockCircleOutlined style={{ cursor: 'pointer', width: 20, height: 20, marginBottom: 0 }} />
                        <span style={{ margin: "0px 3px" }}>{moment(dataResponse?.data?.createdDate).format("HH:mm DD/MM/YYYY")} </span>
                        -
                        <span>
                            <a href={'/data/info_init_detail/' + dataResponse?.data?.id} target="_blank"> id = {dataResponse?.data?.id}</a> <span onClick={handleCopy} style={{cursor: 'pointer'}}>(copy link)</span>
                        </span>
                        -
                        <span onClick={() => setdisplayA(!displayA )}><a href="#">Danh sách dữ liệu đã nhập</a></span>
                    </div>
                    <div>
                        <Top onClick={() => changeLayout(false, 1)} style={{ cursor: 'pointer', width: 20, height: 20, marginLeft: 20, marginBottom: 0 }} />
                        {/* <Right onClick={() => changeLayout(true, 3)} style={{ cursor: 'pointer', width: 20, height: 20, marginLeft: 20, marginBottom: 0 }} /> */}
                        {/* <Bottom onClick={() => changeLayout(false, 2)} style={{ cursor: 'pointer', width: 20, height: 20, marginLeft: 20, marginBottom: 0 }} /> */}
                        <Left onClick={() => changeLayout(true, 1)} style={{ cursor: 'pointer', width: 20, height: 20, marginLeft: 20, marginBottom: 0 }} />
                    </div>
                </div>
                {/* { (showUploadBtn || file === undefined) ? */}

                {/* : <></>} */}
                <div>
                    {displayA ? <><DragAndDropTable
                                toggleEditDataModal={() => { }}
                                setCurrentDataInitActive={() => { }}
                                setTableData={setTableData}
                                tableData={tableData}
                                columns={columns}
                        /></> : <></>}
                </div>
                <div className="body-block-create-info-init" ref={wrapperDiv}>
                    <SplitPane
                        split={displayFlex ? "vertical" : "horizontal"} size={displayFlex ? width : height}
                        minSize={"50%"}
                        onChange={(size) => changeSize(size)}
                        maxSize={"100%"}
                    >
                        <div
                            style={displayFlex ? {
                                order: orderNumber,
                                padding: 10,
                                width: "100%",
                                // resize: "horizontal",
                                overflow: "auto"
                            } : {
                                // borderBottom: orderNumber === 1 ? "dashed 2px #E5E5E5" : "",
                                order: orderNumber,
                                padding: 10,
                                width: "100%",
                                overflow: "auto"
                            }}
                        >
                            <div className="header-block">
                                <div>
                                    <label className='search-title'>File PDF: {dataResponse?.data?.filePDFName || ''}</label>
                                    <ZoomOut
                                        onClick={() => setScale((prev: number) => { prevScale.current = prev; return scale === 1 ? 1 : scale - 1 })}
                                        style={{ cursor: 'pointer', width: 20, height: 20, marginLeft: 20, marginBottom: 0 }} />
                                    <ZoomIn
                                        onClick={() => setScale((prev: number) => { prevScale.current = prev; return scale + 1 })}
                                        style={{ cursor: 'pointer', width: 20, height: 20, marginLeft: 20, marginBottom: 0 }} />
                                    <Rotate
                                        onClick={() => setRotate(rotate - 90)}
                                        style={{ cursor: 'pointer', width: 20, height: 20, marginLeft: 20, marginBottom: 0 }} />
                                </div>
                                <div>
                                    <label
                                        style={{ wordBreak: "break-word" }}
                                        className='search-title'
                                    >({dataResponse?.data?.pathFile || ''})</label>
                                </div>
                                {/*<div>*/}
                                {/*    <label onClick={() => { toggleViewPdfModal(); setIndexCompare(-1) }} style={{ color: "#1890ff", cursor: "pointer" }} className='search-title'>Xem file trước</label> | <label onClick={() => { toggleViewPdfModal(); setIndexCompare(1) }} style={{ color: "#1890ff", cursor: "pointer" }} className='search-title'>Xem file sau</label>*/}
                                {/*</div>*/}
                            </div>
                            <div
                                style={{
                                    overflowY: "auto",
                                    height: displayFlex ? "calc(100vh - 150px)" : "calc(100% - 80px)",
                                }}
                                id="block-view-pdf"
                            >
                                <TransformWrapper
                                    maxScale={3}
                                    initialScale={1}
                                    disablePadding={true}
                                    ref={transformComponentRef}
                                    onZoomStop={(ref: ReactZoomPanPinchRef, event: any) => {
                                        if (ref.state.scale >= 3) {
                                            setScale((prev: number) => { prevScale.current = prev; return prev + 1 });
                                        } else if (ref.state.scale <= 1) {
                                            setScale((prev: number) => { prevScale.current = prev; return prev - 1 || 1 });
                                        }
                                    }}
                                >
                                    <TransformComponent>
                                        <Document
                                            file={file}
                                            onLoadSuccess={onDocumentLoadSuccess}
                                            onLoadError={() => { console.log('123123'); setShowUploadBtn(true) }}
                                        >
                                            {Array.from(new Array(Math.round(numPages / 2)), (el, index) => (
                                                <div style={{ display: "flex" }} key={`grouppage${index + 1}`}>
                                                    <Page
                                                        width={width}
                                                        // height={height}
                                                        rotate={rotate}
                                                        scale={scale}
                                                        key={`page_${docKey}_${index* 2 + 1}`}
                                                        pageNumber={index * 2 + 1}
                                                        canvasRef={canvas}
                                                        onRenderSuccess={onRenderSuccess}
                                                        onClick={onClickPage}
                                                    />
                                                    {
                                                        (index * 2 + 2) <= numPages ? <Page
                                                            width={width}
                                                            // height={height}
                                                            rotate={rotate}
                                                            scale={scale}
                                                            key={`page_${docKey}_${index* 2 + 2}`}
                                                            pageNumber={index * 2 + 2}
                                                            canvasRef={canvas1}
                                                            onRenderSuccess={onRenderSuccess}
                                                            onClick={onClickPage}
                                                        /> : <></>
                                                    }

                                                </div>
                                            ))}
                                        </Document>
                                    </TransformComponent>
                                </TransformWrapper>
                            </div>
                        </div>
                        <Skeleton loading={dataResponse.isFetching}>
                            <div
                                style={displayFlex ? {
                                    order: orderNumber === 1 ? 3 : 1,
                                    padding: 10,
                                    width: "100%",
                                    boxSizing: "border-box",
                                    position: "absolute"
                                    // resize: "horizontal",
                                    // overflow: "auto"
                                } : {
                                    // borderBottom: orderNumber !== 1 ? "dashed 2px #E5E5E5" : "",
                                    order: orderNumber === 1 ? 3 : 1,
                                    padding: 10,
                                    width: "100%",
                                    boxSizing: "border-box",
                                    // position: "absolute"
                                }}
                            >
                                <Form
                                    name="searchBox"
                                    autoComplete="off"
                                    form={form}
                                    colon={false}
                                    style={{ marginTop: 0 }}
                                    layout="vertical"
                                    labelWrap
                                    labelAlign="left"
                                >
                                    <label className='search-title'>THHT: {pdfFileName}</label>
                                    {checkPermission(PERMISSION_KEYS.KHO_DU_LIEU_NHAP_LIEU.SUA) ? <Upload
                                        showUploadList={false}
                                        customRequest={(options) => customUpload(options)}
                                        accept=".pdf"
                                        beforeUpload={(file, fileList) => beforeUpload(fileList)}
                                        style={{ marginBottom: 0 }}
                                        multiple={true}
                                        listType="text"
                                    >
                                        <Button
                                            title="Thay thế file pdf khác"
                                            type="primary"
                                            loading={loadingFile}
                                            style={{ marginBottom: 15, marginLeft: 10 }}
                                        ><UploadOutlined /> </Button>
                                    </Upload> : <></>}
                                    <div style={{ overflowY: "auto", overflowX: "hidden", height: "calc(100vh - 215px)", paddingRight: 10 }}>
                                        <Row gutter={16} style={{ marginTop: 8, marginBottom: 8 }}>
                                            {keyMaps?.length && keyMaps.filter(x => x.isDisplay).map((item: any, index) => {
                                                return (<Col span={8} key={item.dataKeyAscii}>
                                                    {dataResponse?.data?.filePDFId || dataResponse?.data?.typeId ? convertTypeDataToElement(item, index) : <Form.Item
                                                        style={{ marginBottom: 10 }}
                                                        label={<div style={{ fontWeight: 700, wordBreak: "break-word" }}>
                                                            {item.dataKey || ""}
                                                            {item.isEdited ?
                                                                <ExclamationCircleOutlined title="Đã chỉnh sửa" style={{ marginLeft: 5, color: "#faad14" }} /> :
                                                                <></>
                                                                // <CheckCircleOutlined title="Chưa chỉnh sửa" color="success" style={{ marginLeft: 5, color: "#52c41a" }} />
                                                            }
                                                        </div>}
                                                    >
                                                        <Input.TextArea onFocus={(e) => e.currentTarget.select()} onChange={(e) => changeDataInfo(e, item.dataKeyAscii)} value={item.dataValue} placeholder="Nhập dữ liệu" />
                                                    </Form.Item>}
                                                </Col>)
                                            })}
                                        </Row>
                                    </div>
                                </Form>
                                {keyMaps?.length ? <div className='search-box-button' style={{ marginTop: 15 }}>
                                    <Button onClick={nextDataInfo}>Kiểm tra dữ liệu khác</Button>
                                    <Button disabled={!checkActiveSave() || isLoading} type='primary' onClick={onFinish}>{checkChangeData() ? "Cập nhật dữ liệu" : "Xác nhận"}</Button>
                                </div> : <></>}
                            </div>
                        </Skeleton>
                    </SplitPane>
                </div>
            </div >
            {confirmVisible ?
                <ConfirmModal
                    cancelText="Huỷ bỏ"
                    okText="Xác nhận"
                    classBtnOk="ant-button-dangerous"
                    contents={[checkChangeData() ? "Bạn muốn cập nhật dữ liệu không?" : "Bạn muốn xác nhận dữ liệu không?"]}
                    handleCancel={toggleConfirmModal}
                    handleOk={onSubmitCheckData}
                    type={1}
                    visible={confirmVisible}
                    widthConfig={500}
                    okBtnDanger={true}
                    isLoading={isLoading}
                />
                : <></>}
            {confirmNextextVisible ?
                <ConfirmModal
                    cancelText="Huỷ bỏ"
                    okText="Đồng ý"
                    classBtnOk="ant-button-dangerous"
                    contents={[checkChangeData() ?
                        "Bạn đồng ý huỷ kết quả chỉnh sửa dữ liệu này để kiểm tra dữ liệu khác?" :
                        "Bạn chắc chắn muốn chuyển file pdf?"]}
                    handleCancel={() => setConfirmNextextVisible(!confirmNextextVisible)}
                    handleOk={nextDataInfo}
                    type={1}
                    visible={confirmNextextVisible}
                    widthConfig={424}
                    okBtnDanger={true} />
                : <></>}
            {viewPdfVisible ?
                <ViewFilePdf
                    visible={viewPdfVisible}
                    toggleModal={toggleViewPdfModal}
                    filePdf={dataResponse?.data}
                    indexCompare={indexCompare}
                />
                : <></>}
        </>
    )
}

export default CreateDataInfo;
