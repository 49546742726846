import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { UploadFileType, UploadFileForBidOrderType, FileByObjectIdAndType, UploadFileResponse, UploadFilePlaceType } from './apiTypes';

const fileApi = createApi({
    reducerPath: 'fileApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_URL + '/api/v1/file',
        prepareHeaders: (headers, { getState }) => {
            const token = localStorage.getItem("accessToken");
            if (token) {
                headers.set('authorization', `Bearer ${token}`)
            }
            return headers;
        }
    }),
    endpoints: (builder) => ({
        uploadFile: builder.mutation<UploadFileResponse, UploadFileType>({
            query: (body) => ({
                url: `uploadFile?objectType=${body.objectType}&note=${body.note || ""}&storageType=${body.storageType || ""}`,
                method: 'POST',
                body: body.file
            }),
            transformResponse: (response: UploadFileResponse , meta, arg) => {
                return response;
            },
        }),
        // Map file with object: stutue, contract,...
        uploadFileForObject: builder.mutation<string, UploadFileForBidOrderType>({
            query: (body) => ({
                url: `uploadFile`,
                method: 'PUT',
                body: body
            }),
        }),
        // Get file by object
        getFileByObjectIdAndType: builder.query<UploadFileResponse[], FileByObjectIdAndType>({
            query: (query) => `getFileByObjectIdAndObjectType?objectId=${query.objectId}&objectType=${query.objectType}${query.storageType ? `&storageType=${query.storageType}` : ""}`,
            // query: (query) => `getFileByObjectIdAndObjectType?objectId=${query.objectId}&objectType=${query.objectType}`,
            transformResponse: (response: { data: UploadFileResponse[] }, meta, arg) => {
                return response.data;
            },
        }),
        // Delete file
        deleteFile: builder.mutation<File, string>({
            query: (id) => ({
                url: `deleteFile/${id}`,
                method: 'DELETE',
            }),
            transformResponse: (response: { body: File }, meta, arg) => {
                return response.body;
            },
        }),
        // Download file
        downloadFileBase64: builder.query<any, number>({
            query: (id) => `downloadFileBase64/${id}`,
            transformResponse: (response: { body: any }, meta, arg) => {
                return response.body;
            },
        }),
        updateFileInfo: builder.mutation<UploadFileResponse, Partial<UploadFileResponse>>({
            query: (body) => {
                let { id, ...rest } = body;
                return ({
                    url: `updateFileInfo/${body.id}`,
                    method: 'PUT',
                    body: rest
                })
            },
            transformResponse: (response: { body: UploadFileResponse }, meta, arg) => {
                return response.body;
            },
        }),
        updateFiles: builder.mutation<UploadFileResponse, Partial<UploadFileResponse>[]>({
            query: (body) => {
                return ({
                    url: `updateFileInfo`,
                    method: 'PUT',
                    body: body
                })
            },
            transformResponse: (response: { body: UploadFileResponse }, meta, arg) => {
                return response.body;
            },
        }),
        addAttachments: builder.mutation<UploadFileResponse, Partial<UploadFileResponse>[]>({
            query: (body) => {
                return ({
                    url: 'add-vbattachment',
                    method: 'POST',
                    body,
                })
            },
            transformResponse: (response: { body: UploadFileResponse }, meta, arg) => {
                return response.body;
            },
        })
    })
})

export default fileApi;
export const {
    useUploadFileMutation,
    useUploadFileForObjectMutation,
    useLazyGetFileByObjectIdAndTypeQuery,
    useDeleteFileMutation,
    useLazyDownloadFileBase64Query,
    useUpdateFileInfoMutation,
    useUpdateFilesMutation,
    useAddAttachmentsMutation,
} = fileApi;