import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { UploadFileType } from './apiTypes';

export const checkDataApi = createApi({
    reducerPath: 'checkDataApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_URL + "/api/v1",
        prepareHeaders: (headers, { getState }) => {
            const token = localStorage.getItem("accessToken");
            if (token) {
                headers.set('authorization', `Bearer ${token}`)
            }
            return headers;
        }
    }),
    endpoints: (builder) => ({
        // Lấy dữ liệu random
        getRandomData: builder.query<any, any>({
            query: (id) => `/tool/data-info-init/random`,
            transformResponse: (response: { data: any }, meta, arg) => {
                return response.data;
            },
        }),
        // Cập nhật dữ liệu theo chiến dịch
        updateDataByCampaign: builder.mutation<any, any>({
            query: (body) => ({
                url: `/tool/data-info/confirm-check`,
                method: 'PUT',
                body
            }),
            transformResponse: (response: { data: any }, meta, arg) => {
                return response.data;
            },
        }),
        updateDataInfoInitByCampaign: builder.mutation<any, any>({
            query: (body) => ({
                url: `/tool/data-info-init/check-confirm`,
                method: 'PUT',
                body: {
                    dataKeyMap: body.dataKeyMap,
                    id: body.id,
                    isCheck: body.isCheck,
                },
                params: {
                    communeId: body.communeId,
                    districtId: body.districtId,
                    provinceId: body.provinceId,
                    campaignId: body.campaignId,
                }
            }),
            transformResponse: (response: { data: any }, meta, arg) => {
                return response.data;
            },
        }),
        // Lấy thứ tự sắp xếp
        getFieldConfig: builder.query<any, any>({
            query: (body) => ({
                url: `/fields`,
                method: 'GET',
                params: {
                    types: body.types,
                    years: body.years
                }
            }),
            transformResponse: (response: { data: any }, meta, arg) => {
                return response.data;
            },
        }),
        // Upload file 
        uploadFilePdfCheck: builder.mutation<any, UploadFileType>({
            query: (body) => ({
                url: `/file/upload-pdf-1`,
                method: 'POST',
                body: body.file,
                params: {
                    dataInfoId: body.dataInfoId,
                    filePDFName: body.filePDFName,
                }
            }),
            transformResponse: (response: { data: any }, meta, arg) => {
                return response.data;
            },
        }),
    })
})

export const {
    useLazyGetRandomDataQuery,
    useUpdateDataByCampaignMutation,
    useUpdateDataInfoInitByCampaignMutation,
    useLazyGetFieldConfigQuery,
    useUploadFilePdfCheckMutation
} = checkDataApi;
export default checkDataApi;