import { Breadcrumb, Button, Checkbox, Col, Form, Input, InputNumber, Row, Select } from "antd";
import { ReactComponent as Home } from '../../assets/logos/home.svg';
import { useEffect, useState } from "react";
import DataInfoInitHistory from "./components/DataInfoInitHistory";
import { useLocation, useNavigate } from "react-router-dom";
import { Commune, District } from "../../redux/api/apiTypes";
import { useLazyGetAllCampaignQuery, useLazyGetCommuneQuery, useLazyGetDistrictQuery, useLazyGetProvinceQuery, useLazyGetUserCheckDataQuery } from "../../redux/api/commonApi";
import { TYPE_DATA, TYPE_ACTION } from "../../assets/ts/constant";
import { useAppSelector } from "../../redux/Hooks";
import { subMonths, startOfMonth, endOfMonth, startOfYear, subYears, endOfYear } from "date-fns";
import { DatePicker } from "../../custom-components";
import { useLazyGetAllProvinceNoAuthQuery } from "../category/province/redux/provinceApi";
const { RangePicker } = DatePicker;

const RegisterInfo = () => {
    const [form] = Form.useForm();
    let location = useLocation();
    let { state } = useLocation();
    const navigate = useNavigate();
    const [searchParam, setSearchParam] = useState({});
    const userState = useAppSelector(state => state.user.user);
    const [triggerUserCheck, responseUserCheck] = useLazyGetUserCheckDataQuery();
    const [triggerCampaign, responseCampaign] = useLazyGetAllCampaignQuery();
    const [triggerProvince, responseProvince] = useLazyGetAllProvinceNoAuthQuery();
    const [districtTrigger, responseDistrict] = useLazyGetDistrictQuery();
    const [communeTrigger, responseCommune] = useLazyGetCommuneQuery();
    const [districts, setDistricts] = useState<District[]>([]);
    const [communes, setCommunes] = useState<Commune[]>([]);
    const [dataCampaignId, setDataCampaignId] = useState();
    const [initRender, setInitRender] = useState(false);

    useEffect(() => {
        if (checkPermission("data/info_init")) {
            triggerCampaign({});
        }
        triggerUserCheck({});
        triggerProvince({});
        if (location?.search) {
            let pdfFileName = (location?.search || "").substr(1).replace(".pdf", "").replace(".PDF", "");
            if (!checkPermission("data/info_init")) {
                setSearchParam({ fileName: pdfFileName, createBys: [userState.username] });
                form.setFieldsValue({ fileName: pdfFileName, createBys: [userState.username] });
            } else {
                setSearchParam({ fileName: pdfFileName });
                form.setFieldsValue({ fileName: pdfFileName })
            }
        } else if (state && typeof state === "object") {
            let newSearchParam: any = { ...state };
            if (newSearchParam?.searchParam?.provinceId) {
                setCommunes([]);
                setDistricts([]);
                districtTrigger(newSearchParam?.searchParam?.provinceId).unwrap()
                    .then(res => setDistricts(res));

            }
            if (newSearchParam?.searchParam?.districtId) {
                setCommunes([]);
                communeTrigger(newSearchParam?.searchParam?.districtId).unwrap()
                    .then(res => setCommunes(res));
            }
            if (newSearchParam?.searchParam?.dataCampaignId) {
                setDataCampaignId(newSearchParam.searchParam.dataCampaignId);
            }
            if (!checkPermission("data/info_init")) {
                setSearchParam({
                    ...newSearchParam?.searchParam,
                    createBys: newSearchParam?.searchParam?.createBys?.length ? newSearchParam?.searchParam?.createBys : [userState.username]
                });
                form.setFieldsValue({
                    ...newSearchParam?.searchParam,
                    createBys: newSearchParam?.searchParam?.createBys?.length ? newSearchParam?.searchParam?.createBys : [userState.username]
                });
            } else {
                setSearchParam(newSearchParam?.searchParam);
                form.setFieldsValue(newSearchParam?.searchParam)
            }
        } else {
            setSearchParam(searchParam ? { ...searchParam, createBys: [userState.username] } : { createBys: [userState.username] });
            form.setFieldsValue(searchParam ? { ...searchParam, createBys: [userState.username] } : { createBys: [userState.username] });
        }
        setInitRender(true);
    }, [triggerUserCheck, triggerCampaign, triggerProvince, form, state, userState]);

    const checkPermission = (path: string) => {
        if (userState.username === "admin" || (userState?.authorities || []).indexOf("ROLE_SYSTEM_USER") !== -1) return true;
        return false;
    }

    const onFinish = (values: any) => {
        setSearchParam({ ...values, createBys: checkPermission("data/info_init") ? values.createBys : [userState.username] });
        navigate(".", { replace: true, state: { searchParam: { ...values, createBys: checkPermission("data/info_init") ? values.createBys : [userState.username] } } });
    }

    const onValuesChanged = (changedValues: any) => {
        if (Object.keys(changedValues)[0] === "provinceId") {
            setCommunes([]);
            setDistricts([]);
            if (changedValues.provinceId) {
                districtTrigger(changedValues.provinceId).unwrap()
                    .then(res => setDistricts(res));
            }
            form.setFieldsValue({
                districtId: undefined,
                communeId: undefined,
            })

        } else if (Object.keys(changedValues)[0] === "districtId") {
            setCommunes([]);
            if (changedValues.districtId) {
                communeTrigger(changedValues.districtId).unwrap()
                    .then(res => setCommunes(res));
            }
            form.setFieldsValue({
                communeId: undefined
            })
        } else if (Object.keys(changedValues)[0] === "dataCampaignId") {
            setDataCampaignId(changedValues.dataCampaignId);
        }
    }

    const resetFormData = () => {
        form.resetFields();
    }

    return (
        <>
            {/*<Breadcrumb separator="" style={{ marginBottom: 20, marginLeft: 20, marginTop: 20 }}>*/}
            {/*    <Breadcrumb.Item href="/home">*/}
            {/*        <Home style={{ width: 14, height: 14, marginRight: 20 }} /> Trang chủ*/}
            {/*    </Breadcrumb.Item>*/}
            {/*    <Breadcrumb.Separator> {'>'} </Breadcrumb.Separator>*/}
            {/*    <Breadcrumb.Item href="">*/}
            {/*        <span>Dữ liệu kiểm tra</span>*/}
            {/*    </Breadcrumb.Item>*/}
            {/*</Breadcrumb>*/}

            <div className='title-label' style={{ marginBottom: 20 }}>Danh sách dữ liệu kiểm tra</div>

            <div className='content-box' style={{ marginBottom: 30 }}>
                <Form
                    name="searchBox"
                    onFinish={onFinish}
                    autoComplete="off"
                    layout="vertical"
                    form={form}
                    labelWrap
                    labelAlign="left"
                    colon={false}
                    onValuesChange={onValuesChanged}
                >
                    <label className='search-title'>Lọc chung</label>
                    <Row gutter={16} style={{ marginTop: 8, marginBottom: 8 }}>
                        <Col span={6}>
                            <Form.Item
                                style={{ marginBottom: 10 }}
                                label="Loại hành động"
                                name="types"
                            >
                                <Select
                                    allowClear
                                    placeholder="Chọn loại hành động"
                                    optionFilterProp='label'
                                    options={Object.keys(TYPE_ACTION).map(item => ({
                                        label: `${TYPE_ACTION[item]} (${item})`,
                                        value: item
                                    }))}
                                    showSearch
                                    showArrow
                                    mode="multiple"
                                    maxTagCount='responsive'
                                >
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                style={{ marginBottom: 10 }}
                                label="Thời gian kiểm tra"
                                name="createDates"
                            >
                                <RangePicker
                                    format="DD/MM/Y"
                                    placeholder={["Bắt đầu", "Kết thúc"]}
                                    ranges={{
                                        'Hôm nay': [new Date(), new Date()],
                                        'Tháng này': [startOfMonth(new Date()), new Date()],
                                        'Tháng trước': [startOfMonth(subMonths(new Date(), 1)), endOfMonth(subMonths(new Date(), 1))],
                                        '3 tháng gần nhất': [startOfMonth(subMonths(new Date(), 2)), new Date()],
                                        '6 tháng gần nhất': [startOfMonth(subMonths(new Date(), 5)), new Date()],
                                        'Năm nay': [startOfYear(subMonths(new Date(), 5)), new Date()],
                                        'Năm trước': [startOfYear(subYears(new Date(), 1)), endOfYear(subYears(new Date(), 1))],
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                style={{ marginBottom: 10 }}
                                label="Tỉnh/Thành phố"
                                name="provinceId"
                            >
                                <Select
                                    allowClear
                                    placeholder="Chọn tỉnh thành"
                                    optionFilterProp='label'
                                    options={(responseProvince?.data || []).map(item => ({
                                        label: item.provinceName,
                                        value: item.id
                                    }))}
                                    showSearch
                                    showArrow
                                >
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                style={{ marginBottom: 10 }}
                                label="Quận/Huyện"
                                name="districtId"
                            >
                                <Select
                                    allowClear
                                    placeholder="Chọn quận huyện"
                                    optionFilterProp='label'
                                    options={(districts || []).map(item => ({
                                        label: item.districtName,
                                        value: item.id
                                    }))}
                                    showSearch
                                    showArrow
                                >
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                style={{ marginBottom: 10 }}
                                label="Phường/Xã"
                                name="communeId"
                            >
                                <Select
                                    allowClear
                                    placeholder="Chọn phường xã"
                                    options={(communes || []).map(item => ({
                                        label: item.communeName,
                                        value: item.id
                                    }))}
                                    showSearch
                                    showArrow
                                >
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                style={{ marginBottom: 10 }}
                                label="Loại dữ liệu"
                                name="typeId"
                            >
                                <Select
                                    allowClear
                                    placeholder="Chọn loại dữ liệu"
                                    optionFilterProp='label'
                                    options={Object.keys(TYPE_DATA).map(item => ({
                                        label: `${TYPE_DATA[item]} (${item})`,
                                        value: item
                                    }))}
                                    showSearch
                                    showArrow
                                >
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                style={{ marginBottom: 10 }}
                                label="Tên file"
                                name="fileName"
                            >
                                <Input placeholder="Nhập tên file" style={{ minWidth: '100%' }} />
                            </Form.Item>
                        </Col>
                        <Col span={6} style={{ display: "flex" }}>
                            <Form.Item
                                style={{ marginBottom: 10 }}
                                label="Năm"
                                name="fromYear"
                            >
                                <Input placeholder="Từ" style={{ minWidth: '50%' }} />
                            </Form.Item>
                            <Form.Item
                                style={{ margin: "30px 10px 10px", }}
                                name="toYear"
                            >
                                <Input placeholder="Đến" style={{ minWidth: '50%' }} />
                            </Form.Item>
                        </Col>
                        {checkPermission("data/info_init") ? <Col span={6}>
                            <Form.Item
                                style={{ marginBottom: 10 }}
                                label="Người thực hiện"
                                name="createBys"
                            >
                                <Select
                                    allowClear
                                    placeholder="Chọn người thực hiện"
                                    options={(responseUserCheck?.data || []).map(item => ({
                                        label: `${item.fullName} (${item.username})`,
                                        value: item.username
                                    }))}
                                    showSearch
                                    mode="multiple"
                                    maxTagCount='responsive'
                                >
                                </Select>
                            </Form.Item>
                        </Col> : <></>}
                        {checkPermission("data/info_init") ? <Col span={6}>
                            <Form.Item
                                style={{ marginBottom: 10 }}
                                label="Chiến dịch"
                                name="dataCampaignId"
                            >
                                <Select
                                    allowClear
                                    placeholder="Chọn chiến dịch"
                                    optionFilterProp='label'
                                    options={(responseCampaign?.data || []).map(item => ({
                                        label: item.campaignName,
                                        value: item.id
                                    }))}
                                    showSearch
                                    showArrow
                                >
                                </Select>
                            </Form.Item>
                        </Col> : <></>}
                    </Row>
                    <div className='search-box-button'>
                        <Button htmlType='button' onClick={() => resetFormData()}>
                            Xóa điều kiện
                        </Button>
                        <Button htmlType='submit' type='primary'>Tìm kiếm</Button>
                    </div>
                </Form>
            </div>
            {initRender ?
                <DataInfoInitHistory
                    stateOld={state && typeof state === "object" ? state : {}}
                    form={form}
                    searchParam={searchParam}
                /> : <></>}
        </>
    )
}

export default RegisterInfo;
