import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { CommonListQuery, UploadFilePlaceResponse, UploadFilePlaceType } from '../../../redux/api/apiTypes';
import { DataCheck, DataFile, DataInfoParams, DataInfoResponse, HistoryParams, HistoryResponse } from './apiTypes';

export const dataCheckApi = createApi({
    reducerPath: 'dataCheckApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_URL + "/api/v1",
        prepareHeaders: (headers, { getState }) => {
            const token = localStorage.getItem("accessToken");
            if (token) {
                headers.set('authorization', `Bearer ${token}`)
            }
            return headers;
        }
    }),
    endpoints: (builder) => ({
        // Lấy danh sách data info
        getDataCheck: builder.query<DataInfoResponse, { searchParam?: DataInfoParams } & Partial<CommonListQuery>>({
            query: (body) => ({
                url: `/tool/data-info/getPage?search=${body.searchParam ? encodeURIComponent(JSON.stringify(body.searchParam)) : ""}&page=${body.page || 0}&size=${body.size || 10}`,
                method: 'GET',
            }),
            transformResponse: (response: { data: DataInfoResponse }, meta, arg) => {
                return response.data;
            },
        }),
        // Lấy số lượng data info
        getCountDataCheck: builder.query<number, { searchParam?: DataInfoParams } & Partial<CommonListQuery>>({
            query: (body) => ({
                url: `/tool/data-info/getPage-count?search=${body.searchParam ? encodeURIComponent(JSON.stringify(body.searchParam)) : ""}&page=${body.page || 0}&size=${body.size || 10}`,
                method: 'GET',
            }),
            transformResponse: (response: { data: number }, meta, arg) => {
                return response.data;
            },
        }),
        // Lấy danh sách lịch sử chỉnh sửa
        getHistory: builder.query<HistoryResponse, { searchParam?: HistoryParams } & Partial<CommonListQuery>>({
            query: (body) => ({
                url: `/tool/data-info-history/getPage?search=${body.searchParam ? encodeURIComponent(JSON.stringify(body.searchParam)) : ""}&page=${body.page || 0}&size=${body.size || 10}&sort=createdDate,desc`,
                method: 'GET',
            }),
            transformResponse: (response: { data: HistoryResponse }, meta, arg) => {
                return response.data;
            },
        }),
        // Lấy danh sách lịch sử chỉnh sửa
        getAllHistory: builder.query<HistoryResponse, string>({
            query: (dataInfoId) => ({
                url: `/tool/data-info-history/${dataInfoId}`,
                method: 'GET',
            }),
            transformResponse: (response: { data: HistoryResponse }, meta, arg) => {
                return response.data;
            },
        }),
        // Lây file excel đã import
        getFileExcel: builder.query<DataFile[], any>({
            query: (body) => ({
                url: `/tool/data-file/all`,
                method: 'GET',
            }),
            transformResponse: (response: { data: DataFile[] }, meta, arg) => {
                return response.data;
            },
        }),
        // Lây file excel đã import
        getFileExcelByPage: builder.query<{ content: DataFile[] }, any>({
            query: (body) => ({
                url: `/tool/data-file/getPage?search=${body.searchParam ? encodeURIComponent(JSON.stringify(body.searchParam)) : ""}&page=${body.page || 0}&size=${body.size || 10}`,
                method: 'GET',
            }),
            transformResponse: (response: { data: { content: DataFile[] } }, meta, arg) => {
                return response.data;
            },
        }),
        // Lấy chi tiết data info theo id
        getDataInfoById: builder.query<DataCheck, string>({
            query: (id) => `/tool/data-info/${id}`,
            transformResponse: (response: { data: DataCheck }, meta, arg) => {
                return response.data;
            },
        }),
        // Lấy dữ liệu tiếp theo từ bản ghi hiện tại
        getNextDataInfoById: builder.query<DataCheck, string>({
            query: (id) => `/tool/data-info/next/${id}`,
            transformResponse: (response: { data: DataCheck }, meta, arg) => {
                return response.data;
            },
        }),
        // Lấy dữ liệu random data info (check data info)
        getRandomData: builder.query<DataCheck, any>({
            query: (id) => `/tool/data-info/random`,
            transformResponse: (response: { data: DataCheck }, meta, arg) => {
                return response.data;
            },
        }),
        // Cập nhật dữ liệu trạng thái kiểm tra data info
        updateData: builder.mutation<any, any>({
            query: (body) => ({
                url: `/tool/data-info/is-check`,
                method: 'PUT',
                body
            }),
            transformResponse: (response: { data: any }, meta, arg) => {
                return response.data;
            },
        }),
        // Cập nhật dữ liệu theo chiến dịch
        updateDataByCampaign: builder.mutation<any, any>({
            query: (body) => ({
                url: `/tool/data-info/confirm-check`,
                method: 'PUT',
                body
            }),
            transformResponse: (response: { data: any }, meta, arg) => {
                return response.data;
            },
        }),
        // Kiểm tra tồn tại file pdf
        checkExitFilePdf: builder.mutation<any, any>({
            query: (id) => ({
                url: `/tool/data-info/check-pdf-exit/${id}`,
                method: 'GET',
            }),
            transformResponse: (response: { data: any }, meta, arg) => {
                return response.data;
            },
        }),
        // Upload file 
        uploadFilePlace: builder.mutation<UploadFilePlaceResponse, UploadFilePlaceType>({
            query: (body) => ({
                url: `/tool/data-info/upload-file/${body.typeName}/${body.typeId}/${body.provinceId}/${body.districtId}/${body.communeId}`,
                method: 'POST',
                body: body.file
            }),
            transformResponse: (response: { data: UploadFilePlaceResponse }, meta, arg) => {
                return response.data;
            },
        }),
        // Import excel 
        importDataInfo: builder.mutation<any, UploadFilePlaceType>({
            query: (body) => ({
                url: `/tool/data-info/import-excel`,
                method: 'POST',
                body: body
            }),
            transformResponse: (response: { data: any }, meta, arg) => {
                return response.data;
            },
        }),
        // Lưu thứ tự sắp xếp
        saveSort: builder.mutation<any, any>({
            query: (body) => ({
                url: `/tool/data-keymap-sort/add`,
                method: 'POST',
                body: body
            }),
            transformResponse: (response: { data: any }, meta, arg) => {
                return response.data;
            },
        }),
        // Lấy thứ tự sắp xếp
        getSortId: builder.query<any, string>({
            query: (fileId) => `/tool/data-keymap-sort/get-by-file-id/${fileId}`,
            transformResponse: (response: { data: any }, meta, arg) => {
                return response.data;
            },
        }),
    })
})

export const {
    useLazyGetAllHistoryQuery,
    useLazyGetHistoryQuery,
    useLazyGetDataCheckQuery,
    useLazyGetCountDataCheckQuery,
    useUploadFilePlaceMutation,
    useLazyGetDataInfoByIdQuery,
    useLazyGetNextDataInfoByIdQuery,
    useImportDataInfoMutation,
    useUpdateDataMutation,
    useLazyGetFileExcelQuery,
    useLazyGetFileExcelByPageQuery,
    useLazyGetRandomDataQuery,
    useUpdateDataByCampaignMutation,
    useSaveSortMutation,
    useLazyGetSortIdQuery,
    useCheckExitFilePdfMutation,
} = dataCheckApi;
export default dataCheckApi;