import React from 'react';
// import logo from './logo.svg';
import './App.css';
// import Module from './modules/system/participants/components/UserDetails'
import Route from './react-routes/Routes';
import "./assets/css/styles.scss";
import { pdfjs } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

function App() {
  return (
    <Route />
  );
  // return <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.3.122/build/pdf.worker.min.js"> <Route /> </Worker>

}

export default App;
