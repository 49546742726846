import { Breadcrumb, Button, Col, Form, Input, Row, Select } from "antd";
import { ReactComponent as Home } from '../../assets/logos/home.svg';
import { useEffect, useState } from "react";
import UserList from "./components/UserList";
import { useLazyGetUserCheckDataQuery } from "../../redux/api/commonApi";
import { DatePicker } from "../../custom-components";
import { endOfMonth, endOfYear, startOfMonth, startOfYear, subMonths, subYears } from "date-fns";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../redux/Hooks";
import { PERMISSION_KEYS } from "../permissions/ts/Contants";

const RegisterInfo = () => {
    const { Option } = Select;
    const { RangePicker } = DatePicker;
    const [form] = Form.useForm();
    const [searchParam, setSearchParam] = useState({});
    const [triggerUserCheck, responseUserCheck] = useLazyGetUserCheckDataQuery();

    useEffect(() => {
        triggerUserCheck({});
    }, [triggerUserCheck])

    const onFinish = (values: any) => {
        setSearchParam(values);
    }

    const resetFormData = () => {
        form.resetFields();
    }

    return (
        <>
            {/*<Breadcrumb separator="" style={{ marginBottom: 20, marginLeft: 20, marginTop: 20 }}>*/}
            {/*    <Breadcrumb.Item href="/home">*/}
            {/*        <Home style={{ width: 14, height: 14, marginRight: 20 }} /> Trang chủ*/}
            {/*    </Breadcrumb.Item>*/}
            {/*    <Breadcrumb.Separator> {'>'} </Breadcrumb.Separator>*/}
            {/*    <Breadcrumb.Item href="">*/}
            {/*        <span>Danh sách tài khoản</span>*/}
            {/*    </Breadcrumb.Item>*/}
            {/*</Breadcrumb>*/}

            <div className='title-label' style={{ marginBottom: 20 }}>Danh sách người dùng</div>

            <div className='content-box' style={{ marginBottom: 30 }}>
                <Form
                    name="searchBox"
                    onFinish={onFinish}
                    autoComplete="off"
                    layout="vertical"
                    form={form}
                    labelWrap
                    labelAlign="left"
                    colon={false}
                >
                    <label className='search-title'>Lọc chung</label>
                    <Row gutter={16} style={{ marginTop: 8, marginBottom: 8 }}>
                        <Col span={12} xs={24} md={12} lg={8} xl={6}>
                            <Form.Item
                                style={{ marginBottom: 10 }}
                                label="Tên đăng nhập"
                                name="username"
                            >
                                <Input placeholder="Nhập tên đăng nhập" allowClear style={{ minWidth: '100%' }} />
                            </Form.Item>
                        </Col>
                        <Col span={12} xs={24} md={12} lg={8} xl={6}>
                            <Form.Item
                                style={{ marginBottom: 10 }}
                                label="Họ tên"
                                name="fullName"
                            >
                                <Input placeholder="Nhập họ tên" allowClear style={{ minWidth: '100%' }} />
                            </Form.Item>
                        </Col>
                        <Col span={12} xs={24} md={12} lg={8} xl={6}>
                            <Form.Item
                                style={{ marginBottom: 10 }}
                                label="Số điện thoại"
                                name="phoneNumber"
                            >
                                <Input placeholder="Nhập số điện thoại" allowClear style={{ minWidth: '100%' }} />
                            </Form.Item>
                        </Col>
                        <Col span={12} xs={24} md={12} lg={8} xl={6}>
                            <Form.Item
                                style={{ marginBottom: 10 }}
                                label="Email"
                                name="email"
                            >
                                <Input placeholder="Nhập email" allowClear style={{ minWidth: '100%' }} />
                            </Form.Item>
                        </Col>
                        <Col span={12} xs={24} md={12} lg={8} xl={6}>
                            <Form.Item
                                style={{ marginBottom: 10 }}
                                label="Địa chỉ"
                                name="address"
                            >
                                <Input placeholder="Nhập địa chỉ" allowClear style={{ minWidth: '100%' }} />
                            </Form.Item>
                        </Col>
                        <Col span={12} xs={24} md={12} lg={8} xl={6}>
                            <Form.Item
                                label="Ngày vào làm"
                                name="startWorkDate"
                            >
                                <RangePicker
                                    format="DD/MM/Y"
                                    placeholder={["Bắt đầu", "Kết thúc"]}
                                    ranges={{
                                        'Hôm nay': [new Date(), new Date()],
                                        'Tháng này': [startOfMonth(new Date()), new Date()],
                                        'Tháng trước': [startOfMonth(subMonths(new Date(), 1)), endOfMonth(subMonths(new Date(), 1))],
                                        '3 tháng gần nhất': [startOfMonth(subMonths(new Date(), 2)), new Date()],
                                        '6 tháng gần nhất': [startOfMonth(subMonths(new Date(), 5)), new Date()],
                                        'Năm nay': [startOfYear(subMonths(new Date(), 5)), new Date()],
                                        'Năm trước': [startOfYear(subYears(new Date(), 1)), endOfYear(subYears(new Date(), 1))],
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12} xs={24} md={12} lg={8} xl={6}>
                            <Form.Item
                                label="Hình thức làm việc"
                                name="workType"
                            >
                                <Select
                                    placeholder="Chọn hình thức làm việc"
                                    allowClear
                                >
                                    <Option value={0}>
                                        Online
                                    </Option>
                                    <Option value={1}>
                                        Offline
                                    </Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12} xs={24} md={12} lg={8} xl={6}>
                            <Form.Item
                                label="Số lần cảnh báo"
                                name="warningCount"
                            >
                                <Input placeholder="Nhập Số lần cảnh báo" type="number" allowClear style={{ minWidth: '100%' }} />
                            </Form.Item>
                        </Col>
                        <Col span={12} xs={24} md={12} lg={8} xl={6}>
                            <Form.Item
                                label="Số lần khen thưởng"
                                name="rewardCount"
                            >
                                <Input placeholder="Nhập Số lần khen thưởng" type="number" allowClear style={{ minWidth: '100%' }} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <div className='search-box-button'>
                        <Button htmlType='button' onClick={() => resetFormData()}>
                            Xóa điều kiện
                        </Button>
                        <Button htmlType='submit' type='primary'>Tìm kiếm</Button>
                    </div>
                </Form>
            </div>
            <UserList form={form} searchParam={searchParam} />
        </>
    )
}

export default RegisterInfo;
