import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const publicApi = createApi({
  reducerPath: "publicApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL + "/public",
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("accessToken");
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    verifyGoogleCaptcha: builder.query<{ success: boolean }, string>({
      query: (token) => `google/capcha?response=${token}`,
      transformResponse: (response: { body: { success: boolean } }, meta, arg) => {
        return response.body;
      }
    })
  }),
})

export default publicApi;
export const {
  useLazyVerifyGoogleCaptchaQuery
} = publicApi

