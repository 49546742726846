import { Button, Modal } from "antd";
import { ReactComponent as SuccessIcon } from "../../../assets/logos/success.svg";
import { ReactComponent as WarningIcon } from "../../../assets/logos/warning.svg";
import { ReactComponent as ErrorIcon } from "../../../assets/logos/error_modal.svg";
import { ReactComponent as InfoIcon } from "../../../assets/logos/info_modal.svg";

interface ConfirmModalProps {
    visible: boolean;
    onOk?: () => void;
    onCancel?: () => void;
    showOk?: boolean;
    showCancel?: boolean;
    okText?: string;
    cancelText?: string;
    type?: StatusTypes;
    content?: React.ReactNode;
    title?: string;
    danger?: boolean;
    onClose?: () => void;
    isUpdate?: boolean;
    loadingBtnOk?: boolean;
}


type StatusTypes = "error" | "success" | "warning" | "info";

const ConfirmModalIcons: Record<StatusTypes, React.ReactNode> = {
    success: <SuccessIcon />,
    warning: <WarningIcon />,
    error: <ErrorIcon />,
    info: <InfoIcon />,
};

const ConfirmButtonColor: Record<StatusTypes, string> = {
    success: '#49cc90',
    warning: '#EB5659',
    error: '#EB5659',
    info: '#2563EB',
};

const ConfirmModalCustom = ({
    visible,
    onOk,
    onCancel,
    cancelText,
    okText,
    showCancel = true,
    showOk = true,
    content,
    type,
    title,
    danger = false,
    onClose,
    loadingBtnOk = false,
    isUpdate = false,
}: ConfirmModalProps) => {
    return (
        <Modal
            className="confirm-modal error-1"
            onCancel={isUpdate ? onClose : onCancel}
            visible={visible}
            footer={[
                ...(showCancel
                    ? [
                        <Button key="back" onClick={onCancel} htmlType='button'
                            className='white-red-border-button'>
                            {cancelText || "Đóng"}
                        </Button>
                    ]
                    : []),
                ...(showOk
                    ? [
                        <Button
                            key="accept"
                            onClick={onOk}
                            type="primary"
                            danger={danger}
                            style={{
                                backgroundColor: type ? ConfirmButtonColor?.[type] : '#FFFFFF', marginLeft: 16,
                                borderColor: type ? ConfirmButtonColor?.[type] : '#FFFFFF',
                                borderRadius: 4
                            }}
                            loading={loadingBtnOk}
                        >
                            {okText || "Đồng ý"}
                        </Button>,
                    ]
                    : []),
            ]}
        >
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                }}
            >
                {type && ConfirmModalIcons?.[type] ? ConfirmModalIcons[type] : <></>}

                {title && (
                    <span style={{ fontSize: 20, fontWeight: 500, marginTop: 16, color: '#1e3a8a' }}>
                        {title}
                    </span>
                )}

                {content && <div style={{ marginTop: 16 }}>{content}</div>}
            </div>
        </Modal>
    );
};

export default ConfirmModalCustom;
